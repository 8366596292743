export const API_ENDPOINTS = {
    LOGIN: 'authentication/login',
    DIRECT_LOGIN: 'DirectLogin',
    CHANGE_PASSWORD: 'ChangePassword ',
    DEPARTMENT_ADD: 'Department',
    DEPARTMENT_Get_ALL: 'Department',
    DEPARTMENT_by_id: 'Department/:id',
    DEPARTMENT_DELETE: 'Department',
    PROPERTY_TYPE_ADD: 'PropertyType',
    PROPERTY_TYPE_Get_ALL: 'PropertyType',
    PROPERTY_TYPE_by_id: 'PropertyType/:id',
    PROPERTY_TYPE_DELETE: 'PropertyType',
    DEPARTMENT_QUESTION_ADD: 'DepartmentQuestion',
    DEPARTMENT_QUESTION_Get_ALL: 'DepartmentQuestion',
    DEPARTMENT_QUESTION_by_id: 'DepartmentQuestion/:id',
    DEPARTMENT_QUESTION_DELETE: 'DepartmentQuestion',
    PROPERTY_DEPARTMENT_QUESTION_ADD: 'PropDept_Question',
    PROPERTY_DEPARTMENT_QUESTION_Get_ALL: 'PropDept_Question',
    ROYALTY_POINT_ADD: 'RoyaltyPoint',
    ROYALTY_POINT_Get_ALL: 'RoyaltyPoint',
    ROYALTY_POINT_by_id: 'RoyaltyPoint/:id',
    ROYALTY_POINT_DELETE: 'RoyaltyPoint',
    ROYALTY_OFFER_ADD: 'RoyaltyOffer',
    ROYALTY_OFFER_Get_ALL: 'RoyaltyOffer',
    ROYALTY_OFFER_by_id: 'RoyaltyOffer/:id',
    ROYALTY_OFFER_DELETE: 'RoyaltyOffer',
    PROPERTY_ROYALTY_POINT_ADD: 'Prop_RoyaltyPoint',
    PROPERTY_ROYALTY_POINT_Get_ALL: 'Prop_RoyaltyPoint',
    PROPERTY_REDIM_VALUE_ADD: 'Prop_RedimValue',
    PROPERTY_REDIM_VALUE_Get_ALL: 'Prop_RedimValue',
    PROPERTY_API_SMS_ADD: 'PropertyAPI',
    PROPERTY_API_SMS_Get_ALL: 'PropertyAPI',
    PROPERTY_API_PARAM_ADD: 'PropAPIParameter',
    PROPERTY_API_PARAM_Get_ALL: 'PropAPIParameter',
    CUSTOMER_TYPE_ADD: 'CustomerType',
    CUSTOMER_TYPE_Get_ALL: 'CustomerType',
    CUSTOMER_by_id: 'CustomerType/:id',
    CUSTOMER_DELETE: 'CustomerType',
    CASHIER_REPORT_Get_ALL: 'CashierReport',
    PROPERTY_CUSTOMER_TYPE_ADD: 'PropCustomerType',
    PROPERTY_CUSTOMER_TYPE_Get_ALL: 'PropCustomerType',
    PROPERTY_CUSTOMER_TYPE_by_id: 'PropCustomerType/:id',
    PROPERTY_CUSTOMER_TYPE_DELETE: 'PropCustomerType',
    PROPERTY_SMS_EMAIL_ADD: 'PropertyAPI',
    PROPERTY_SMS_EMAIL_Get_ALL: 'PropertyAPI',
    PROPERTY_SMS_EMAIL_by_id: 'PropertyAPI/:id',
    PROPERTY_SMS_EMAIL_DELETE: 'PropertyAPI',
    PROPERTY_APIParameter_ADD: 'PropAPIParameter',
    PROPERTY_APIParameter_Get_ALL: 'PropAPIParameter',
    PROPERTY_APIParameter_by_id: 'PropAPIParameter/:id',
    PROPERTY_APIParameter_DELETE: 'PropAPIParameter',
    RANGE_TYPE_MASTER_ADD: 'RangeTypeMaster',
    RANGE_TYPE_MASTER_Get_ALL: 'RangeTypeMaster',
    RANGE_TYPE_MASTER_by_id: 'RangeTypeMaster/:id',
    RANGE_TYPE_MASTER_DELETE: 'RangeTypeMaster',
    RANGE_MASTER_ADD: 'RangeMaster',
    RANGE_MASTER_Get_ALL: 'RangeMaster',
    RANGE_MASTER_by_id: 'RangeMaster/:id',
    RANGE_MASTER_DELETE: 'RangeMaster',
    PROPERTY_RANGE_MASTER_ADD: 'PropRangeMaster',
    PROPERTY_RANGE_MASTER_Get_ALL: 'PropRangeMaster',
    PROPERTY_RANGE_by_id: 'PropRangeMaster/:id',
    PROPERTY_RANGE_MASTER_DELETE: 'PropRangeMaster',
    GUEST_HISTORY_SUMMARY_REPORT_Get_ALL: 'GuestHistory',
    REVIEW_FOR_ADD: 'ReviewFor',
    REVIEW_FOR_Get_ALL: 'ReviewFor',
    REVIEW_FOR_by_id: 'ReviewFor/:id',
    REVIEW_FOR_DELETE: 'ReviewFor',
    PROPERTY_MASTER_ADD: 'PropertyMaster',
    ELEMENT_MASTER_ADD: 'Elements',
    GET_TEMPLATES: 'CategoryTemplates',
    PROPERTY_MASTER_Get_ALL: 'PropertyMaster',
    PROPERTY_MASTER_by_id: 'PropertyMaster/:id',
    PROPERTY_MASTER_DELETE: 'PropertyMaster',
    PROPERTY_ADD: 'Property',
    PROPERTY_Get_ALL: 'Property',
    PROPERTY_by_id: 'Property/:id',
    PROPERTY_DELETE: 'Property',
    PROPERTY_DEPARTMENT_ADD: 'PropertyDepartment',
    PROPERTY_DEPARTMENT_Get_ALL: 'PropertyDepartment',
    PROPERTY_DEPARTMENT_by_id: 'PropertyDepartment/:id',
    PROPERTY_DEPARTMENT_DELETE: 'PropertyDepartment',
    PROPERTY_DEPARTMENT_QUE_ADD: 'PropDept_Question',
    PROPERTY_DEPARTMENT_QUE_Get_ALL: 'PropDept_Question',
    PROPERTY_DEPARTMENT_QUE_by_id: 'PropDept_Question/:id',
    PROPERTY_DEPARTMENT_QUE_DELETE: 'PropDept_Question',
    PROPERTY_CUSTOMER_CATEGORY_ADD: 'PropCustomerCategory',
    PROPERTY_CUSTOMER_CATEGORY_Get_ALL: 'PropCustomerCategory',
    PROPERTY_CUSTOMER_CATEGORY_by_id: 'PropCustomerCategory/:id',
    PROPERTY_CUSTOMER_CATEGORY_DELETE: 'PropCustomerCategory',
    CUSTOMER_SEARCH: 'CustomerSearch',
    CUSTOMER_OFFERS: 'CustomerOffers',
    CUSTOMER_ADD: 'Customer', // UPDATION NOT REQUIRED
    CUSTOMER_Get_ALL: 'Customer',
    CUSTOMER_Get_DobDoa: 'GetCustomerDobDoa',
    CUSTOMER_DETAILS_Get_ALL: 'CustomerDetails',
    CUSTOMER_RESPONSE_ADD: 'CustomerResponse',
    CUSTOMER_RESPONSE_Get_ALL: 'CustomerResponse',
    CUSTOMER_POINTS_REDIM_ADD: 'CustomerPointsRedim',
    CUSTOMER_POINTS_REDIM_Get_ALL: 'CustomerPointsRedim',
    CUSTOMER_DASHBOARD: 'CustomerDashboard',
    CUSTOMER_IMPORT: 'CustomerImport',
    PROPERTY_TODAY_OFFER_ADD: 'PropertyTodayOffer',
    PROPERTY_TODAY_OFFER_Get_ALL: 'PropertyTodayOffer',
    PROPERTY_CUSTOMER_CAT_ADD: 'PropCustomerCategory',
    PROPERTY_CUSTOMER_CAT_Get_ALL: 'PropCustomerCategory',
    GENERATE_OTP: 'Otp',
    GET_DASHBOARD_DETAILS: 'UserDashboard',
    GET_COUNTRIES: 'commonMaster',
    GET_STATES: 'commonMaster1',
    GET_CITIES: 'commonMaster2',
    SOCIAL_MEDIA_MASTER_ADD: 'MediaCategory',
    SOCIAL_MEDIA_MASTER_Get_ALL: 'MediaCategory',
    SOCIAL_MEDIA_MASTER_by_id: 'MediaCategory/:id',
    SOCIAL_MEDIA_MASTER_DELETE: 'MediaCategory',
    SOCIAL_MEDIA_QUOTE_Get_ID: 'MediaQuotation',
    SOCIAL_MEDIA_QUOTE_ADD: 'MediaQuotation',
    SOCIAL_MEDIA_QUOTE_by_id: 'MediaQuotation/:id',
    SOCIAL_MEDIA_QUOTE_DELETE: 'MediaQuotation',
    SOCIAL_MEDIA_BANNER_Get_ID: 'MediaImage',
    SOCIAL_MEDIA_BANNER_ADD: 'MediaImage',
    SOCIAL_MEDIA_BANNER_DELETE: 'MediaImage',
    BONUS_POINT_ADD: 'CustomerPoints',
    BONUS_POINT_by_id: 'CustomerPoints/:id',
    AUTO_PILOT_ADD: 'PropertyAutoPilotDetails',
    AUTO_PILOT_DETAILS: 'PropertyAutoPilot',
    AUTO_PILOT_DAYS: 'AutoPilotRewardsDays',
    AUTO_PILOT_EXISTING: 'PropertyAutoPilotDetails',
    AUTO_PILOT_SETTING: 'AutoPilotSetting',
    NO_OF_VISITS: 'NoofVisits',
    PROMOTION_CUSTOMERS: 'PromotionCustomer',
    SMS_Balance: 'SMSBalance',
    SEND_CAMPAIGN: 'PromotionCampaign',
    SEND_PROMOTION: 'Promotion',
    GET_PROMOTION_by_id: 'Promotion/:id',
    PROMOTION_CUSTOMER_COUNT: 'PromotionAttachedCustomer',
    GET_WEEKDAYS: 'WeekDays',
    PROMOTION_GET: 'PromotionSummary',
    PROMOTION_COMBO: 'PromotionStatus',
    PROMOTION_SUMMARY_DETAILS: 'PromotionSummaryDetails',
    CURRENT_OFFER_GET: 'PropertyTodayOffer',
    CURRENT_OFFER_POST: 'PropertyTodayOffer',
    CURRENT_OFFER_by_id: 'PropertyTodayOffer/:id',
    QRDetails: 'QRDetails',
    VERIFY_TOKEN: 'Qsrverify',
    VERIFY_TOKEN_OLD: 'QsrverifyOld',
    QSR_QUESTION: 'QuestionsByUser',
    QSR_QUESTION_OLD: 'QuestionsByUserOld',
    REVIEW_BY_USER: 'ReviewByUser',
    REVIEW_BY_USER_OLD: 'ReviewByUserOld',
    SAVE_IMAGE_SERVER: 'SocialMediaLibrary',
    NO_OF_REDEEMS: 'NoofRedim',
    BLIND_DEPARTMENT_COMBO: 'PropertyDepartmentCombo',
    DND_POST: 'MobileDnd',
    USER_TYPE_COMBO: 'UserType',
    USER_TYPE_ADD: 'Owner/User',
    USER_TYPE_Get_ALL: 'Owner/User',
    USER_TYPE_by_id: 'Owner/User/:id',
    USER_TYPE_DELETE: 'Owner/User',
    USER_DEPARTMENT_COMBO: 'UserDepartmentCombo',
    UPDATE_USER_MOBILE: 'MobileNoUpdate',
    UPDATE_DND_SERVICE: 'ContactNoDnd',
    MISSED_CALL_OFFER: 'MissedCallOffer',
    GET_SMS_TYPE_LIST: 'SMSType',
    SMS_SETTING_CONFIG: 'SMSConfiguration',
    EMAIL_SETTING_CONFIG: 'EmailConfiguration',
    PROPERTY_CONFIGURATION: 'PropertyConfiguration',
    MISS_CALL_SUMMARY: 'MissedCallSummary',
    MISS_CALL_DETAILS: 'MissedCallDetails',
    AUTO_PILOT_SUMMARY: 'AutoPilotSummary',
    ANNOUNCEMENT: 'Announcement',
    SMCONNECTION: 'SMConnections',
    SMPost: 'SMPost',
    CustomerDashboardAll: 'CustomerDashboardAll',
    ReferralValidity: 'ReferralValidity',
    ReferralCustomer: 'ReferralCustomer',
    QrCurrentOffer: 'QrCurrentOffer',
    QrCurrentOfferDetails: 'QrCurrentOfferDetails',
    PromotionVisitedCustomer: 'PromotionVisitedCustomer',
    ReferralCampaign: 'ReferralCampaign',
    UpdateCustInfo: 'UpdateCustInfo',
    CustInfoVerify: 'CustInfoVerify',
    ChangePasswordOthers: 'ChangePasswordOthers',
    Property_SendLoginSMS: 'Property_SendLoginSMS',
    Property_SendLoginEmail: 'Property_SendLoginEmail',
    CustomerExport: 'CustomerExport',
    CustomerDetailsWithReview: 'CustomerDetailsWithReview',
    CustomerDetailsWithReviewNext: 'CustomerDetailsWithReviewNext',
    UserWiseCountReport: 'UserWiseCountReport',
    UserWiseCountDetailsReport: 'UserWiseCountDetailsReport',
    Notification: 'Notification',
    VerifyPwd: 'VerifyPwd',
    UserWiseNotification: 'UserWiseNotification',
    ActualImagePath: 'ActualImagePath',
    PromotionRevenueCustomer: 'PromotionRevenueCustomer',
    AutoPilotSummaryDetails: 'AutoPilotSummaryDetails',
    ReportPropertyWiseSummary: 'ReportPropertyWiseSummary',
    VerifyPwdSA: 'VerifyPwdSA',
    PropertyValidityExtend: 'PropertyValidityExtend',
    CustomerWisePromotionSummary: 'CustomerWisePromotionSummary',
    ReportRepeatedGuestSummary: 'ReportRepeatedGuestSummary',
    ReportRepeatedGuestDetails: 'ReportRepeatedGuestDetails',
    ReportSMSBalanceSummary: 'ReportSMSBalanceSummary',
    RemainingPointCampaign: 'RemainingPointCampaign',
    EMAILER: 'common/contentvaluedata',
    COMMON_CODE: 'CommonMaster?CommonCode=',
    COMMON_MASTER: 'CommonMaster',
    FEEDBACK: 'CustomerDetailsDashboard'
};
