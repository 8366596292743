import { Component, Inject, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IUserService } from '../../../iservices/iUser';
import { SharedService } from '../../../services/shared.service';
import { STORAGE_KEYS } from '../../../services/storage-keys-constants';
import * as _ from 'lodash';
import { CommonPermissionsGuard } from '../../../gaurds/flow.guard';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isCollapsed = false;
  property: any = {};
  @Input() sidenav;
  @Input() sidebar;
  @Input() drawer;
  @Input() matDrawerShow;

  searchOpen = false;
  toolbarHelpers = {
    notifications: {},
    currentUser: {
      photoURL: 'assets/images/avatars/hari.jpg',
      currentUserName: 'Hari Krishna'
    }
  };
  constructor(
    @Inject('IUserService') private userService: IUserService,
    private localStorageService: SharedService,
    private router: Router,
    private commonPermissionsGuard: CommonPermissionsGuard
  ) {
  }
  /**
   * @method ngOnInit()
   * @desc used to Initialize Page.
   */
  ngOnInit(): void {
    this.property = {
      code: this.localStorageService.getDataFromStorage('propertyCode'),
      name: this.localStorageService.getDataFromStorage('propertyName')
    };
  }
}
