<div class="scroll-container" customScrollbar [suppressX]="false">
    <ng-container *ngFor="let item of editorUi.getNavItems()">
        <div class="separator" *ngIf="item.type === 'separator'"></div>

        <button *ngIf="item.type !== 'separator'"
                class="no-style control-button"
                (click)="executeNavItemAction(item)"
                [disabled]="navItemIsDisabled(item)">
            <svg-icon [name]="item.icon"></svg-icon>
            <span class="name" trans>{{item.display_name || item.name}}</span>
        </button>
    </ng-container>
</div>