<h2 trans>Open a photo or design to get started.</h2>

<div class="buttons" *ngIf="!newCanvasFormVisible">
    <button mat-flat-button color="primary" (click)="openUploadDialog()" trans>Open Photo</button>
    <button mat-stroked-button color="primary" (click)="newCanvasFormVisible = true" trans>Create New</button>
</div>

<div class="new-canvas-container" *ngIf="newCanvasFormVisible">
    <form class="many-inputs" ngNativeValidate [formGroup]="newCanvasForm" (ngSubmit)="createNewCanvas()">
        <div class="input-container">
            <label for="width" trans>Width</label>
            <input type="number" id="width" min="1" formControlName="width">
        </div>

        <div class="input-container">
            <label for="height" trans>Height</label>
            <input type="number" id="height" min="1" formControlName="height">
        </div>

        <div class="form-buttons">
            <button type="button" mat-button (click)="newCanvasFormVisible = false" trans>Cancel</button>
            <button type="submit" mat-raised-button color="primary" trans>Create</button>
        </div>
    </form>
</div>

<div class="separator">
    <hr>
    <span class="text" trans>or use a sample</span>
</div>

<div class="samples">
    <img [src]="image.thumbnail || image.url" *ngFor="let image of sampleImages" (click)="openSampleImage(image.url)">
</div>