// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false, 
   //url: 'https://api.revugain.com/api/v1',
    //url: 'http://localhost:51638/api/v1',
   url: 'https://api.timessquare.asia/api/v1',
};
 
