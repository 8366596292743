import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { ISocialMediaMasterService } from '../../iservices/iMasterServices/iSocialMediaMaster';
import { MatDialog } from '@angular/material/dialog';
import {
  AddSocialMediaCategoryComponent
} from '../../component/master/masterComponents/social-media-category/add-social-media-category/add-social-media-category.component';
import {
  AddQuotationComponent
} from '../../component/master/masterComponents/social-media-category/quotation/add-quotation/add-quotation.component';
import {
  QuotationComponent
} from '../../component/master/masterComponents/social-media-category/quotation/quotation.component';
import {
  BannerComponent
} from '../../component/master/masterComponents/social-media-category/banner/banner.component';
import {
  AddBannerComponent
} from '../../component/master/masterComponents/social-media-category/banner/add-banner/add-banner.component';
@Injectable({
  providedIn: 'root'
})
export class SocialMediaMasterService implements ISocialMediaMasterService {
  categoryId;
  id;
  private apiObject: IApiBaseObject;
  dataHasBeenDeleted = new BehaviorSubject(false);
  updateGalary = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService,
    public dialog: MatDialog) {

  }
  /*
    * @method getCategoryDetails()
    * @desc used to get social media category list .
    * @return json array data.
  */
  getCategoryDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.SOCIAL_MEDIA_MASTER_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('categoryMasterDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
    * @method addCategory()
    * @desc used to add category Type.
    * @param permData :array - category data.
    * @return json array data.
  */
  addCategory(permData): any {
    const requestData = this.modelBindingService.categoryMasterEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.SOCIAL_MEDIA_MASTER_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
 * @method openAddDialog()
 * @desc used to get add dialog data.
 */

  openAddDialog(id, type: string): any {
    this.categoryId = id;
    if (type === 'popup') {
      this.dialog.open(AddQuotationComponent, {
        width: '450px',
        panelClass: 'mat-dialog-box',
        data: {}
      });
    }
    if (type === 'notpopup') {
      this.dialog.open(QuotationComponent, {
        width: '850px',
        panelClass: 'mat-dialog-box',
        data: {}
      });
    }
    if (type === 'viewImage') {
      this.dialog.open(BannerComponent, {
        width: '850px',
        panelClass: 'mat-dialog-box',
        data: {}
      });
    }
    if (type === 'addImage') {
      this.dialog.open(AddBannerComponent, {
        width: '850px',
        panelClass: 'mat-dialog-box',
        data: {}
      });
    }
  }
  /*
   * @method getQuoteId()
   * @desc used to get quote id.
   */

  getQuoteId(): any {
    return this.categoryId;
  }
  /*
    * @method getCategoryDetailsById()
    * @desc used to get social media category details by id.
    * @param id :string - social media category id.
    * @return json array data.
  */
  getCategoryDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.SOCIAL_MEDIA_MASTER_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService.categoryMasterDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }
  /*
   * @method delete()
   * @desc used to social media category row data.
   * @param id :array - social  media category id .
   * @param id :dialogType - dialogType .
   * @return string.
  */
  delete(id, dialogType): any {
    let endPoint;
    if (dialogType === 'quote') {
      endPoint = API_ENDPOINTS.SOCIAL_MEDIA_QUOTE_DELETE;
    }
    if (dialogType === 'socialmedia') {
      endPoint = API_ENDPOINTS.SOCIAL_MEDIA_MASTER_DELETE;
    }
    if (dialogType === 'removeImage') {
      endPoint = API_ENDPOINTS.SOCIAL_MEDIA_BANNER_DELETE;
    }
    const url = this.modelBindingService.replaceUrlForDelete(
      endPoint, id);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }

  /*
    * @method getQuoteDetails()
    * @desc used to get social media category list .
    * @return json array data.
  */
  getQuoteDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.SOCIAL_MEDIA_QUOTE_Get_ID, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('quoteDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
   * @method addQuote()
   * @desc used to add quote Type.
   * @param permData :array - quote data.
   * @return json array data.
 */
  addQuote(permData): any {
    const requestData = this.modelBindingService.quoteEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.SOCIAL_MEDIA_QUOTE_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

   /*
    * @method getQuoteDetailsById()
    * @desc used to get social media quote details by id.
    * @param id :string - social media quote id.
    * @return json array data.
    */
  getQuoteDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.SOCIAL_MEDIA_QUOTE_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService.quoteDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }
  /*
   * @method addBanner()
   * @desc used to add images Type.
   * @param permData :array - images data.
   * @return json array data.
 */
  addBanner(permData): any {
    const requestData = this.modelBindingService.bannerEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.SOCIAL_MEDIA_BANNER_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }
  /*
  * @method getBannerDetails()
  * @desc used to get social media banner list .
  * @return json array data.
*/
  getBannerDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.SOCIAL_MEDIA_BANNER_Get_ID, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('bannerDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }


  getSocialMediaConnectionsToken = () => {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.SMCONNECTION, {}, true, false);
    return this.apiService.getApi(this.apiObject);
  }

  postSocialMediaPost = (requestData) => {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.SMPost, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject);
  }

  getSocialMediaPost = (params) => {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.SMPost, params, true, false);
    return this.apiService.getApi(this.apiObject);
  }
  deleteSocialMediaPost = (id) => {
    /* this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.SMPost, params, true, false);
    return this.apiService.deleteApi(this.apiObject); */

    const url = `${API_ENDPOINTS.SMPost}?Id=${id}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }
}
