import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map, isEmpty } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { IReportsService } from '../../iservices/iReportsServices/iReports';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ReportsService implements IReportsService {
  private apiObject: IApiBaseObject;
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService) {

  }
  /*
    * @method getRepeatGuestDetails()
    * @desc used to get Repeat Guest Reports list .
    * @return json array data.
  */
  getRepeatGuestDetails(apiGetParameters): any {
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.GUEST_HISTORY_SUMMARY_REPORT_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['response'] = this.modelDecoderBindingService
            .mappingDataReturn('houseDecoder1', resp);
          const json = { data: resp['response'].data, 'itemCount': resp['response'].count };
          return json;
        }
      }
      ));
  }
  /*
    * @method getCustomerDetailsWithReview()
    * @desc used to get customer detail with review list .
    * @return json array data.
  */
  getCustomerDetailsWithReview(apiGetParameters): any {
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.CUSTOMER_DETAILS_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['response'] = this.modelDecoderBindingService
            .mappingDataReturn('houseDecoder1', resp);
          const json = { data: resp['response'].data, 'itemCount': resp['response'].count };
          return json;
        }
      }
      ));
  }


   /*
    * @method getMissCallSummary()
    * @desc used to get misscall report summary
  */
  getMissCallSummary(): any {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.MISS_CALL_SUMMARY, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          return resp;
        }
      }
      ));
  }

   /*
    * @method getMissCallDetails()
  */
  getMissCallDetails(apiGetParameters): any {
    const d = apiGetParameters;
    const s = apiGetParameters.searchData ;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    let url = `${API_ENDPOINTS.MISS_CALL_DETAILS}?page=${d.page}&size=${d.size}` ;
    if (!_.isEmpty(s)) {
      // tslint:disable-next-line:max-line-length
       url = `${API_ENDPOINTS.MISS_CALL_DETAILS}?page=${d.page}&size=${d.size}&FromDate=${s.FromDate}&ToDate=${s.ToDate}&MobileNo=${s.MobileNo}`;
    }
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp: any) => {
        if (resp && resp.data) {
          const json = { data: resp.data, 'itemCount': resp['data'][0].totalRows };
          return json;
        }
      }
      ));
  }

getAutoPilotSummary(apiGetParameters): any {
  apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
  apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
  const url = this.modelBindingService.replaceUrl(
    API_ENDPOINTS.AUTO_PILOT_SUMMARY, apiGetParameters);
  this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
  return this.apiService.getApi(this.apiObject).pipe(
    map((resp) => {
      if (resp) {
        return resp['data'];
      }
    }
    ));
}

 /*
  * @method getDetailTab()
  * @desc used to get detail list .
  * @return json array data.
*/
getDetailTab(apiGetParameters): any {
  let json;
  apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
  apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
  const url = this.modelBindingService.replaceUrl(
    API_ENDPOINTS.AUTO_PILOT_SUMMARY, apiGetParameters);
  this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
  return this.apiService.getApi(this.apiObject).pipe(
    map((resp) => {
      if (resp) {
        // resp['data'] = this.modelDecoderBindingService
        //   .mappingDataReturn('promotionDecoder', resp);
        if (resp['data'].data) {
          json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
        } else {
          json = { data: resp['data'].data, 'itemCount': 0 };
        }
        return json;
      }
    }
    ));
}


deactiveAutoPilotResult = (id, IsActive) => {
  const url = `${API_ENDPOINTS.AUTO_PILOT_ADD}?Id=${id}&IsActive=${IsActive}`;
  this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
  return this.apiService.deleteApi(this.apiObject);
}

getCustomerWithReview(apiGetParameters): any {
  const searchData = {...apiGetParameters.searchData};
  searchData.PropertyDepartmentId  = searchData.PropertyDepartmentId ? searchData.PropertyDepartmentId : '';
  searchData.CustomerName  = searchData.CustomerName ? searchData.CustomerName : '';
  searchData.FromDt  = searchData.FromDt ? searchData.FromDt : '';
  searchData.ToDt  = searchData.ToDt ? searchData.ToDt : '';
  // tslint:disable-next-line:max-line-length
  const url = `${API_ENDPOINTS.CustomerDetailsWithReview}?PropertyDepartmentId=${searchData.PropertyDepartmentId}&page=${apiGetParameters.page}&size=${apiGetParameters.size}&CustomerName=${searchData.CustomerName}&FromDt=${searchData.FromDt}&ToDt=${searchData.ToDt}`;
  let json;
  apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
  apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
  // const url = this.modelBindingService.replaceUrl(
  //   API_ENDPOINTS.CustomerDetailsWithReview, apiGetParameters);
  this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
  return this.apiService.getApi(this.apiObject).pipe(
    map((resp: any) => {
      if (resp.code === 1000) {
        if (resp.data.length > 0) {
          json = { data: resp.data, 'itemCount': resp.data[0].totalRows ? resp.data[0].totalRows : resp.data.length };
        } else {
          json = { data: [], 'itemCount': 0 };
        }
        return json;
      } else {
        return json = { data: [], 'itemCount': 0 };
      }
    }
    ));
}

getCustomerDetailsWithReviewNext = (data) => {
  let json;
  const url = `${API_ENDPOINTS.CustomerDetailsWithReviewNext}?Customerid=${data.Customerid}&GroupId=${data.GroupId}`;
  this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
  return this.apiService.getApi(this.apiObject).pipe(
    map((resp: any) => {
      if (resp.code === 1000) {
        if (resp.data.length > 0) {
          json = { data: resp.data, 'itemCount': resp.data[0].totalRows ? resp.data[0].totalRows : resp.data.length };
        } else {
          json = { data: [], 'itemCount': 0 };
        }
        return json;
      } else {
        return json = { data: [], 'itemCount': 0 };
      }
    }
    ));
}

getUserWiseCountReport = (data) => {
  let json;
  const searchData = {...data.searchData};
  searchData.FromDt  = searchData.FromDt ? searchData.FromDt : '';
  searchData.ToDt  = searchData.ToDt ? searchData.ToDt : '';
  const url = `${API_ENDPOINTS.UserWiseCountReport}?FromDt=${searchData.FromDt}&ToDt=${searchData.ToDt}`;
  this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
  return this.apiService.getApi(this.apiObject).pipe(
    map((resp: any) => {
      if (resp.code === 1000) {
        if (resp.data.length > 0) {
          json = { data: resp.data, 'itemCount': resp.data[0].totalRows ? resp.data[0].totalRows : resp.data.length };
        } else {
          json = { data: [], 'itemCount': 0 };
        }
        return json;
      } else {
        return json = { data: [], 'itemCount': 0 };
      }
    }
    ));
}


getUserWiseCountDetailsReport = (data) => {
  let json;
  const searchData = {...data.searchData};
  searchData.FromDt  = searchData.FromDt ? searchData.FromDt : '';
  searchData.ToDt  = searchData.ToDt ? searchData.ToDt : '';
  searchData.UserDetails  = searchData.UserDetails ? searchData.UserDetails : '';
  searchData.NoofVisit  = searchData.NoofVisit ? searchData.NoofVisit : '';
  searchData.MobileNo  = searchData.MobileNo ? searchData.MobileNo : '';
  searchData.Amount  = searchData.Amount ? searchData.Amount : '';
  // tslint:disable-next-line:max-line-length
  const url = `${API_ENDPOINTS.UserWiseCountDetailsReport}?UserDetails=${searchData.UserDetails}&page=${data.page}&size=${data.size}&FromDt=${searchData.FromDt}&ToDt=${searchData.ToDt}&NoofVisit=${searchData.NoofVisit}&Amount=${searchData.Amount}&MobileNo=${searchData.MobileNo}`;
  this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
  return this.apiService.getApi(this.apiObject).pipe(
    map((resp: any) => {
      if (resp.code === 1000) {
        if (resp.data.length > 0) {
          json = { data: resp.data, 'itemCount': resp.data[0].totalRows ? resp.data[0].totalRows : resp.data.length };
        } else {
          json = { data: [], 'itemCount': 0 };
        }
        return json;
      } else {
        return json = { data: [], 'itemCount': 0 };
      }
    }
    ));
}
}

