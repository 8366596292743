import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { IQsrReviewsService } from '../../iservices/iQsrReviewServices/iQsrReview';
@Injectable({
  providedIn: 'root'
})
export class QsrReviewService implements IQsrReviewsService {
  private apiObject: IApiBaseObject;
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService,
  ) {

  }
  /*
    * @method getQuestionDetails()
    * @desc used to get question list .
    * @return json array data.
  */
  getQuestionDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    let url = API_ENDPOINTS.QSR_QUESTION;
    if (apiGetParameters.UserToken) {
      url = API_ENDPOINTS.QSR_QUESTION_OLD;
    }
    const requestUrl = this.modelBindingService.replaceUrl(url, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(requestUrl, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('qsrQuestionDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
    * @method addReviews()
    * @desc used to add Reviews Type.
    * @param permData :array - Reviews data.
    * @return json array data.
  */
  addReviews(permData): any {
    let url = API_ENDPOINTS.REVIEW_BY_USER;
    if (permData.UserToken) {
      url = API_ENDPOINTS.REVIEW_BY_USER_OLD;
    }
    const requestData = this.modelBindingService.qsrReviewEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(url, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
    * @method verifyToken()
    * @desc used to verify token.
    * @return json array data.
  */
  verifyToken(data): any {
    let url;
    if (data.UserToken) {
      url = API_ENDPOINTS.VERIFY_TOKEN_OLD + '?UserToken=' + data.UserToken;
    } else if (data.Ut) {
      url = API_ENDPOINTS.VERIFY_TOKEN + '?Ut=' + data.Ut;
    }
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, false, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          return resp;
        }
      }
      ));
  }
}
