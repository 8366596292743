<div class="mat-page-container">
        <!--header-->
            <div fxLayout="row" fxLayout.xs="column"  fxLayoutGap="15PX"  class="mat-page-top-header">
                <div fxFlex="1 0 auto" fxFlexAlign="center"><h3>{{ 'banner.mediaGallery' | translate }}</h3></div>
                <div fxFlex="100">
                  <div class="search-box-fix-width">

                  </div>
                </div>
                <div fxFlex="1 0 auto">
                   <button mat-raised-button color="primary" *ngIf="commonPermissionsGuard?.addPerms('Social Media Category','Master')" (click)="createBanners()"><i class="material-icons">add </i> {{ 'common.addNew' | translate }}</button>
                </div>
            </div>
       <!--header-->
          <div *ngIf="totalCount;else noRecord">
            <div class="media-gallery" cdkDropList (cdkDropListDropped)="drop($event)">
                <div class="media-gallery-content" *ngFor="let image of bannerImages; let i=index"  cdkDrag>
                    <img [src]="image.src"(click)="open(i)" />
                    <button mat-raised-button color="primary" *ngIf="commonPermissionsGuard?.deletePerms('Social Media Category','Master')" (click)="removeImage(image.id)" > {{ 'common.remove' | translate }}</button>
                  </div>
              </div>
        </div>
        <ng-template #noRecord>No Record Found</ng-template>
          <app-pagination *ngIf="totalCount" [totalItems]="totalCount" [pageSizeArrayFromComponent]="pageSizeArray"
          (goPrev)="goPrev($event)" (goNext)="goNext($event)" (goPage)="goToPage($event)" (setPageSize)="setPageSize($event)">
        </app-pagination>
    </div>
