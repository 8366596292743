import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddDepartmentComponent } from '../../component/master/masterComponents/department/add-department/add-department.component';
import * as _ from 'lodash';

import { IPromotionsService } from '../../iservices/iPromotionServices/iPromotions';
import { concat } from 'rxjs/internal/observable/concat';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromotionsService implements IPromotionsService {
  id;
  private apiObject: IApiBaseObject;
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  totalDelieveredCustomer: any[];
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService) {

  }
  /*
    * @method getPromotionsDetails()
    * @desc used to get Promotions list .
    * @return json array data.
  */
  getPromotionsDetails(apiGetParameters): any {
    // let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.PROMOTION_GET, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('promotionDecoder', resp);
          // if (resp['data'].data) {
          //   json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          // } else {
          //   json = { data: resp['data'].data, 'itemCount': 0 };
          // }
          return resp['data'];
        }
      }
      ));
  }
  /*
  * @method getDetailTab()
  * @desc used to get detail list .
  * @return json array data.
*/
  getDetailTab(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.PROMOTION_SUMMARY_DETAILS, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('promotionDecoder', resp);
          if (!_.isEmpty(resp['data'].data)) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: [], 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
  * @method getStatusCombo()
  * @desc used to get status list .
  * @return json array data.
*/
  getStatusCombo(): any {
    const url = API_ENDPOINTS.PROMOTION_COMBO;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          return resp['data'];
        }
      }
      ));
  }
  /*
  * @method getNoofVisits()
  * @desc used to get NoofVisits list .
  * @return json array data.
*/
  getNoofVisits(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.NO_OF_VISITS, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('propertyRangeDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
* @method getFilterData()
* @desc used to get data list .
* @return json array data.
*/
  getFilterData(param): any {
    const url = API_ENDPOINTS.PROMOTION_CUSTOMERS + param;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }
  /*
* @method getSMSBalance()
* @desc used to get sms list .
* @return json array data.
*/
  getSMSBalance(): any {
    const url = API_ENDPOINTS.SMS_Balance;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }
    /*
* @method getCustomerCountbyId()
* @desc used to get customer list .
* @return json array data.
*/
getCustomerCountbyId(id): any {
  const json = {
    id: id
  };
  const url = this.modelBindingService.replaceUrlForDelete(
    API_ENDPOINTS.PROMOTION_CUSTOMER_COUNT, json);
  this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
  return this.apiService.getApi(this.apiObject).pipe(
    map((res) => {
      return res;
    },
      (error) => {
        return error;
      }
    ));
}
  /*
* @method getWeekDays()
* @desc used to get week list .
* @return json array data.
*/
  getWeekDays(): any {
    const url = API_ENDPOINTS.GET_WEEKDAYS;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
    * @method addPromotions()
    * @desc used to add Promotions Type.
    * @param permData :array - Promotions data.
    * @return json array data.
  */
  addPromotions(permData): any {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.SEND_PROMOTION, permData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }
  /*
  * @method inactiveRow()
  * @desc used to inactive.
  * @param permData :array - inactive data.
  * @return json array data.
*/
  inactiveRow(id): any {
    const json = {
      id: id
    };
    const url = this.modelBindingService.replaceUrlForDelete(
      API_ENDPOINTS.SEND_PROMOTION, json);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }
  /*
  * @method duplicateRow()
  * @desc used to duplicateRow.
  * @param permData :array - duplicateRow data.
  * @return json array data.
*/
  duplicateRow(permData): any {
    // this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.SEND_PROMOTION, permData,
    //   true, false);
    // return this.apiService.postApi(this.apiObject).pipe(
    //   map((res) => {
    //     return res;
    //   },
    //     (error) => {
    //       return error;
    //     }
    //   ));
  }
  /*
    * @method getPromotionsDetailsById()
    * @desc used to get promotions details by id.
    * @param id :string - promotions id.
    * @return json array data.
  */
  getPromotionsDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.GET_PROMOTION_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          return resp;
        }
      }));
  }

  getAnnouncement(apiGetParameters): any {
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.ANNOUNCEMENT, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          return resp;
        }
      }));
  }

  /*
    * @method postAnnouncement()
    * @desc used to add Announcement type
    * @param permData :array - Promotions data.
    * @return json array data.
  */
 postAnnouncement(permData): any {
  this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.ANNOUNCEMENT, permData,
    true, false);
  return this.apiService.postApi(this.apiObject).pipe(
    map((res) => {
      return res;
    },
      (error) => {
        return error;
      }
    ));
}

getVisitedCustomerDetail(apiGetParameters) {
  let json;
  apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
  apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
  const url = this.modelBindingService.replaceUrl(
    API_ENDPOINTS.PromotionVisitedCustomer, apiGetParameters);
  this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
  return this.apiService.getApi(this.apiObject).pipe(
    map((resp: any) => {
      if (resp) {
        if (resp.data.length > 0) {
          json = { data: resp.data, 'itemCount': resp.data[0].totalRows };
        } else {
          json = { data: resp.data, 'itemCount': 0 };
        }
        return json;
      }
    }
    ));
}

getDeleiveredCustomerDetail(apiGetParameters) {
  if (apiGetParameters.customerId && !_.isEmpty(this.totalDelieveredCustomer)) {
    const data = _.filter(this.totalDelieveredCustomer, { parentId: apiGetParameters.customerId });
    const obj =  { data: data, 'itemCount': data.length };
    return of(obj);
  }
  let json;
  apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
  apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
  const url = this.modelBindingService.replaceUrl(
    API_ENDPOINTS.PROMOTION_CUSTOMER_COUNT, apiGetParameters);
  this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
  return this.apiService.getApi(this.apiObject).pipe(
    map((resp: any) => {
      if (resp) {
        if (resp.data.length > 0) {
          this.totalDelieveredCustomer = [...resp.data];
          json = { data: _.filter(resp.data, { parentId: 0 }), 'itemCount': resp.data[0].totalRows };
        } else {
          json = { data: resp.data, 'itemCount': 0 };
        }
        return json;
      }
    }
    ));
}

getTotalRevenueCustomerDetail(apiGetParameters) {
  let json;
  apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
  apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
  const url = this.modelBindingService.replaceUrl(
    API_ENDPOINTS.PromotionRevenueCustomer, apiGetParameters);
  this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
  return this.apiService.getApi(this.apiObject).pipe(
    map((resp: any) => {
      if (resp) {
        if (resp.data.length > 0) {
          this.totalDelieveredCustomer = [...resp.data];
          json = { data: resp.data, 'itemCount': resp.data[0].totalRows };
        } else {
          json = { data: resp.data, 'itemCount': 0 };
        }
        return json;
      }
    }
    ));
}

getAutoPilotTotalDelieverd(apiGetParameters) {
  let json;
  apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
  apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
  const url = this.modelBindingService.replaceUrl(
    API_ENDPOINTS.AutoPilotSummaryDetails, apiGetParameters);
  this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
  return this.apiService.getApi(this.apiObject).pipe(
    map((resp: any) => {
      if (resp) {
        if (resp.data.length > 0) {
          this.totalDelieveredCustomer = [...resp.data];
          json = { data: resp.data, 'itemCount': resp.data[0].totalRows };
        } else {
          json = { data: resp.data, 'itemCount': 0 };
        }
        return json;
      }
    }
    ));
}
}
