<div class="mat-page-container">
    <div fxLayout="row" fxLayout.xs="column"  fxLayoutGap="15PX"  class="mat-page-top-header">
        <div  fxFlex="1 0 auto" fxFlexAlign="center"><h3>{{ 'user.title' | translate }}</h3></div>
        <div fxFlex="100">
          <div class="search-box-fix-width">
            
          </div>
        </div>
        <div fxFlex="1 0 auto">
          <button mat-raised-button color="primary" *ngIf="commonPermissionsGuard?.addPerms('Property Customer Type','Property Management')" (click)="createUser()"><i class="material-icons">add </i> {{ 'common.addNew' | translate }}</button>
        </div>
    </div>
    <app-search [searchOptions]="searchOptions" [activateApis]="api" (searchEvent)="searchDetails($event)"></app-search>
    <app-table [tableSettings]="tableData"></app-table>
  </div>
  
  