
<div class="editor-search">
    <input (keydown.enter)="searchDetail()" [(ngModel)]="title" placeholder="Search Element Title" value="">
    <button (click)="getElements()"><i class="material-icons"> search</i></button>
</div>
<div class="or-option">
    <span>Or</span>
</div>
<button mat-button (click)="openOverlayImage()" class="open-option-btn">
    <svg-icon name="photo-library"></svg-icon>
    <span class="name" trans>Open</span>
</button>
<!-- <div class="content" class="img-selector-wrapper">
    <div class="img-select-item" *ngFor="let element of elementsList" (click)="addElement(element); button.blur()" #button>
        <img title="{{element.title}}" src = "{{element.imagePath}}"/>
    </div>
</div> -->

<div
class="search-results img-selector-wrapper content"
infiniteScroll
infiniteScrollDistance="2"
infiniteScrollUpDistance="1.5"
infiniteScrollThrottle="200"
[scrollWindow]="false"
(scrolled)="onScrollDown()"
>
<div class="img-select-item" *ngFor="let element of elementsList" (click)="addElement(element); button.blur()" #button>
        <img title="{{element.title}}" src = "{{element.imagePath}}"/>
    </div>
</div>