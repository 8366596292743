 <ng-container *ngIf="ImageType;else second">
        <input style="display: none"  accept="image/*" type="file" (change)="onFileSelected($event)" #fileInput>
        <button mat-raised-button color="primary"  (click)="fileInput.click()">
                <span [hidden]="isUpload">Select Image</span>
                <span [hidden]="!isUpload"><i class="fa fa-spinner fa-spin"></i></span>
               </button>
               <div [hidden]="isUpload" class="mt-10"><i>{{imageName}}</i></div>
               <p *ngIf="isExceed" class="red-txt">{{exceedMsg}}</p> 
           
 </ng-container>
<ng-template #second>
        <input style="display: none" multiple  accept="image/*" type="file" (change)="onFileSelected($event)" #fileInput>
        <button mat-raised-button color="primary" style="min-width:117px" (click)="fileInput.click()">
                <span [hidden]="isUpload">Select Image</span>
                <span [hidden]="!isUpload"><i class="fa fa-spinner fa-spin"></i></span>
               </button>
               <!-- <div [hidden]="isUpload" class="mt-10"><i>{{imageName}}</i></div> -->
               <p *ngIf="isExceed" class="red-txt">{{exceedMsg}}</p> 
           
    </ng-template>
    <!-- <button mat-raised-button color="primary" style="min-width:117px" (click)="fileInput.click()">
     <span [hidden]="isUpload">Select Image</span>
     <span [hidden]="!isUpload"><i class="fa fa-spinner fa-spin"></i></span>
    </button>
    <p *ngIf="isExceed" class="red-txt">{{exceedMsg}}</p>  -->
