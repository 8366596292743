import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-body',
  templateUrl: './email-body.component.html',
  styleUrls: ['./email-body.component.scss']
})
export class EmailBodyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
