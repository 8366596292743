<h1 mat-dialog-title>Email Template Details</h1>
<div mat-dialog-content>
  <form
    #addTemplateCategoryForm="ngForm"
    novalidate
    (ngSubmit)="addTemplateCategoryForm.form.valid && submit()"
  >
    <div class="mat-input-group">
      <mat-form-field>
        <input
          matInput
          name="fileElementName"
          [(ngModel)]="fileName"
          placeholder="Select File Name"
          required
          [ngClass]="{
            'is-invalid': addTemplateCategoryForm?.submitted && !fileName
          }"
        />
      </mat-form-field>
    </div>
    <div>
      <ng-select
        name="templateTypeElementName"
        [items]="templateTypeData"
        bindLabel="commonDesc"
        bindValue="uId"
        [(ngModel)]="templateType"
        required
        [ngClass]="{
          'ng-select-invalid':
            addTemplateCategoryForm?.submitted && !templateType
        }"
      >
      </ng-select>
    </div>
    <div>
      <ng-select
        name="categoryElementName"
        [items]="categoryData"
        bindLabel="commonDesc"
        bindValue="id"
        [(ngModel)]="templateCategory"
      >
      </ng-select>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="submit()" [disabled]="addTemplateCategoryForm.invalid">
    {{ "common.submit" | translate }}
  </button>
  <button mat-dialog-close mat-icon-button class="close-btn">
    <i class="material-icons">cancel</i>
  </button>
</div>
