
import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { Subject,BehaviorSubject, Observable } from 'rxjs';
import { ICustomersService } from '../../iservices/iCrmServices/iCustomers';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CustomersService implements ICustomersService {
  impId;
  private apiObject: IApiBaseObject;
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  crmCustomerDetails = [];
  importedCustomers = new Subject<void>();
  constructor(@Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService) {
  }
  /*
    * @method getCustomerDetails()
    * @desc used to get customer list .
    * @return json array data.
  */
  getCustomerDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.CUSTOMER_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('customerDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data };
          }
          return json;
        } else {
          return resp;
        }
      }
      ));
  }

  getCustomerDobdoa(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.CUSTOMER_Get_DobDoa, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('customerDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data };
          }
          return json;
        } else {
          return resp;
        }
      }
      ));
  }
  /*
    * @method addCustomer()
    * @desc used to add Customer.
    * @param permData :array - Customer data.
    * @return json array data.
  */
  addCustomer(permData): any {
    if (!permData.sendSMS) {
      permData.sendSMS = 0;
    }
    const requestData = this.modelBindingService.customerEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.CUSTOMER_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
  * @method getCustomerType()
  * @desc used to get Customer types.
  * @param permData :array - Customer data.
  * @return json array data.
*/
  getCustomerType(): any {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.PROPERTY_CUSTOMER_TYPE_Get_ALL, {},
      true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
* @method getRedeems()
* @desc used to get number of redeems.
* @param permData :array - Customer data.
* @return json array data.
*/
  getRedeems(propId): any {
    const url = API_ENDPOINTS.NO_OF_REDEEMS;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {},
      true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
* @method getVisits()
* @desc used to get number of Visits.
* @param permData :array - Customer data.
* @return json array data.
*/
  getVisits(propId): any {
    const url = API_ENDPOINTS.NO_OF_VISITS;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {},
      true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
  * @method searchCustomer()
  * @desc used to search Customer.
  * @param permData :array - Customer data.
  * @return json array data.
*/
  searchCustomer(permData, custIdentity): any {
    let url;
    if (custIdentity === 1) {
      url = API_ENDPOINTS.CUSTOMER_SEARCH + '?MobileNo=' + permData.contactNo;
    } else if (custIdentity === 1) {
      url = API_ENDPOINTS.CUSTOMER_SEARCH + '?Email=' + permData.email;
    } else {
      url = API_ENDPOINTS.CUSTOMER_SEARCH + '?MobileNo=' + permData.contactNo + '&Email=' + permData.email;
    }
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
* @method customerOffers()
* @desc used to search Customer.
* @param permData :array - Customer data.
* @return json array data.
*/
  customerOffers(custId): any {
    let url;
    url = API_ENDPOINTS.CUSTOMER_OFFERS + '?CustomerId=0' + custId;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
* @method getCustomerCategory()
* @desc used to search Customer.
* @param permData :array - Customer data.
* @return json array data.
*/
  getCustomerCategory(): any {
    let url;
    url = API_ENDPOINTS.PROPERTY_CUSTOMER_CAT_Get_ALL + '?Id=0&Category=';
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
* @method verifyOtp()
* @desc used to verify otp.
* @param permData :array - Customer data.
* @return json array data.
*/
  verifyOtp(data): any {
    let url;
    url = API_ENDPOINTS.GENERATE_OTP;
    this.apiObject = this.modelBindingService.setApiCallObject(url, data, true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
* @method genertaeOtp()
* @desc used to generate otp.
* @param permData :array - Customer data.
* @return json array data.
*/
  generateOtp(data): any {
    let url;
    url = API_ENDPOINTS.GENERATE_OTP + '?CustomerId=' + data.id + '&MobileNo=' + data.MobileNo;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
  * @method getCustomerDetailsById()
  * @desc used to get customer list .
  * @return json array data.
*/
  getCustomerDetailsById(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.CUSTOMER_DETAILS_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .customerDetailDecoder(resp['data']);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
            // if (resp['data'].crmCustomerDetails.data) {
            //   this.crmCustomerDetails = resp['data'].crmCustomerDetails.data;
            // }
          } else {
            json = { data: resp['data'], 'itemCount': 1 };
          }
          return json;
        }
      }
      ));
  }

  /*
  * @method addBonusPoint()
  * @desc used to add bonus.
  * @param permData :array - bonus data.
  * @return json array data.
*/
  addBonusPoint(permData): any {
    const requestData = this.modelBindingService.bonusPointEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.BONUS_POINT_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
   * @method openAddDialog()
   * @desc used to get add dialog data.
   */

  openAddDialog(id, type: string): any {
    if (type === 'forId') {
      this.impId = id;
    }
  }
  /*
   * @method getQuoteId()
   * @desc used to get quote id.
   */

  returnId(): any {
    return this.impId;
  }
  /*
    * @method getCustomerDashboard()
    * @desc used to get customer list .
    * @return json array data.
  */
  getCustomerDashboard(params): any {
    let url = API_ENDPOINTS.CUSTOMER_DASHBOARD;
    if (params) {
      url = `${API_ENDPOINTS.CUSTOMER_DASHBOARD}?FilterPropertyId=${params}`;
    }
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .customersDashboardDecoder(resp['data']);
          return resp['data'];
        }
      }
      ));
  }
  /*
* @method sendCampaign()
* @desc used to add campaign.
* @param permData :array - campaign data.
* @return json array data.
*/
  sendCampaign(permData): any {
    const requestData = this.modelBindingService.campaignEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.SEND_CAMPAIGN, requestData,
      true, false);
    // console.log(this.apiObject);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }


  /*
* @method sendReferral()
* @desc used to add send referral.
* @param permData :array - referral data.
* @return json array data.
*/
  sendReferral(permData): any {
    const requestData = this.modelBindingService.referralEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.ReferralCampaign, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
  * @method getPointDetailsById()
  * @desc used to get point details by id.
  * @param id :string - property id.
  * @return json array data.
*/
  getPointDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.BONUS_POINT_by_id, {
      id: id
    });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          // resp['data'] = this.modelDecoderBindingService.propertyTypeDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }


  /*
  * @method getPropertyForCustomerSearch()
  * @desc used to get point details by id.
  * @param id :string - property id.
  * @return json array data.
*/
  getPropertyForCustomerSearch(id): any {
    const url = `${API_ENDPOINTS.PROPERTY_ADD}?PropertyMasterId=${id}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          return resp;
        }
      }));
  }

  /*
     * @method importCustomer()
     * @desc used to post imported Customer.
   */
  importCustomer(data): any {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.CUSTOMER_IMPORT, data,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
     * @method getImportedCustomers()
     * @desc used to get import Customer.
   */
  getImportedCustomers(): any {
    return this.importedCustomers.asObservable();
  }

  /*
    * @method updateMobileNo()
    * @desc used to update customer mobile no.
  */
  updateMobileNo(data): any {
    const url = `${API_ENDPOINTS.UPDATE_USER_MOBILE}?CustomerId=${data.id}&MobileNo=${data.mobileNo}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {},
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
    * @method updateNotificationType()
    * @desc used to update customer mobile no.
  */
  updateNotificationType(data): any {
    // tslint:disable-next-line:max-line-length
    const url = `${API_ENDPOINTS.UPDATE_DND_SERVICE}?CustomerId=${data.id}&IsTransactionalDND=${data.transaction}&IsPromotionalDND=${data.promotion}&Reason=${data.reason}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {},
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  getAllDashBoardData() {
    const url = API_ENDPOINTS.CustomerDashboardAll;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {},
      true, false);
    return this.apiService.getApi(this.apiObject);
  }

  validateReferrel(data) {
    const url = `${API_ENDPOINTS.ReferralValidity}?t=${data.t}&c=${data.c}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {},
      true, false);
    return this.apiService.getApi(this.apiObject);
  }

  postReferrelCustomer(data) {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.ReferralCustomer, data, true, false);
    return this.apiService.postApi(this.apiObject);
  }

  verifyCustomerInfoToken(token) {
    const url = `${API_ENDPOINTS.CustInfoVerify}?c=${token}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, false, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          return resp;
        }
      }
      ));
  }

  /*
   * @method postCustomerInfo()
   * @desc used to add postCustomerInfo.
   * @param permData :array - postCustomerInfo
   * @return json array data.
 */
  postCustomerInfo(permData): any {
    const requestData = this.modelBindingService.customerInfoEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.UpdateCustInfo, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }


  getCustomerExportData = () => {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.CustomerExport, {}, true, false);
    return this.apiService.getApi(this.apiObject);
  }

  verifyPassword(password) {
    const url = `${API_ENDPOINTS.VerifyPwd}?Password=${password}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject);
  }

  verifyOtpForExport(otp) {
    const url = `${API_ENDPOINTS.VerifyPwd}?Code=${otp}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.postApi(this.apiObject);
  }

  postCustomersRemainingPointCampain(data) {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.RemainingPointCampaign, data, true, false);
    return this.apiService.postApi(this.apiObject);
  }
}
