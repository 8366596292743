import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddUserComponent } from './add-user/add-user.component';
import { CommonPermissionsGuard } from '../../../gaurds/flow.guard';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  api: any = {
    propertyTypeSelect: 'propertyTypeSelect', userTypeComboSelect: 'userTypeComboSelect',
    userDepartmentComboSelect: 'userDepartmentComboSelect'
  };
  selectObj: any = [{ value: '', displayName: 'All' }];
  tableData = {
    tableHeader: [
      'Sr. No.',
      'Property',
      'User Type',
      'User Code',
      'Department',
      'Login Id',
      'Email Id',
      'Mobile No',
      'Created On',
      'Action'
    ],
    serviceDetail: {
      service: 'UserService',
      method: 'getUserDetails',
      isParameter: true,
      parameterList: { page: '', size: ''}
    },
    mapping: [
      { type: 'index', dataValue: '' },
      { type: 'default', dataValue: 'propertyName' },
      { type: 'default', dataValue: 'userType' },
      { type: 'default', dataValue: 'userCode' },
      { type: 'default', dataValue: 'departmentName' },
      { type: 'default', dataValue: 'loginId' },
      { type: 'default', dataValue: 'email' },
      { type: 'default', dataValue: 'mobileNo' },
      { type: 'date', dataValue: 'createdOn' },
      {
        type: 'dialogWithPerm', urlValue: '../dialogPopup',
        urlArgument1: '', dialogType1: 'user',
        dataValue: 'id', parentclass: '', class: 'delete-icon',
        parent: 'Property Management', child: 'Property Customer Type',
        urlValue1: 'addpropertycustomertype',
        urlArgument: 'id', dialogType: 'user',
        loadComponent: AddUserComponent, width: '800px', panelClass: 'mat-dialog-box',
        class1: 'edit-icon', dataValue1: 'isEdit', directLogin: 'true'
      }
    ],
    options: {
      pageSizeArray: [10, 20, 30, 50, 100],
    },
    searchData: {},
  };

  searchOptions = [
    {
      'key': 'propertyId',
      'details': {
        'type': 'propertyTypeSelect',
        generalDetails: { lable: 'Property Name:' },
        DropdownDetails: [],
        preSelected: ''
      }
    },
    {
      'key': 'departmentId',
      'details': {
        'type': 'userDepartmentComboSelect',
        generalDetails: { lable: 'Department:' },
        DropdownDetails: []
      }
    },
    {
      'key': 'userTypeId',
      'details': {
        'type': 'userTypeComboSelect',
        generalDetails: { lable: 'User Type:' },
        DropdownDetails: []
      }
    },
  ];

  constructor(
    public commonPermissionsGuard: CommonPermissionsGuard,
    public dialog: MatDialog,
    private sharedService: SharedService,
  ) {
    const propertyId = this.sharedService.getDataFromStorage('propertyId');
    this.tableData.searchData = {propertyId: propertyId};
    this.searchOptions[0].details.preSelected = propertyId;
  }

  /**
   * @method searchDetails()
   * @desc Used to search details.
   * @param inputVal :  string - key on which data is searched.
   */
  searchDetails(inputVal): void {
    if (!inputVal.departmentId && !inputVal.propertyId && !inputVal.userTypeId) {
      const propertyId = this.sharedService.getDataFromStorage('propertyId');
    this.tableData.searchData = {propertyId: propertyId};
    // this.searchOptions[0].details.preSelected = propertyId;
    } else {
      this.tableData.searchData = inputVal;
    }
  }

  createUser(): void {
    this.dialog.open(AddUserComponent, {
      width: '800px',
      panelClass: 'mat-dialog-box',
      data: {}
    });
  }

}
