import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { IPropertyMasterService } from '../../iservices/iMasterServices/iPropertyMaster';
import { MatDialog } from '@angular/material/dialog';
import {
  AddPropertyMasterComponent
} from '../../component/master/masterComponents/property-master/add-property-master/add-property-master.component';

@Injectable({
  providedIn: 'root'
})
export class PropertyMasterService implements IPropertyMasterService {
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  private apiObject: IApiBaseObject;
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService,
    public dialog: MatDialog) {

  }
  /*
    * @method getPropertyMasterDetails()
    * @desc used to get PropertyMaster list .
    * @return json array data.
  */
  getPropertyMasterDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.PROPERTY_MASTER_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('propertyMasterDecoder', resp);
          // const json =  { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
    * @method addPropertyMaster()
    * @desc used to add PropertyMaster Type.
    * @param permData :array - PropertyMaster data.
    * @return json array data.
  */
  addPropertyMaster(permData): any {
    const requestData = this.modelBindingService.propertyMasterEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.PROPERTY_MASTER_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
  * @method getPropertyMasterDetailsById()
  * @desc used to get property master details by id.
  * @param id :string - property master id.
  * @return json array data.
*/
  getPropertyMasterDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.PROPERTY_MASTER_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService.propertyMasterDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }
  /*
   * @method delete()
   * @desc used to property masterrow data.
   * @param id :array - property master id .
   * @return string.
  */
  delete(id): string {
    const url = this.modelBindingService.replaceUrlForDelete(
      API_ENDPOINTS.PROPERTY_MASTER_DELETE, id);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }
}
