<toolbar class="img-ed-top"></toolbar>
<div class="editor-wrapper">
  <div class="scroll-container">
    <mat-tab-group
      (selectedTabChange)="executeNavItemAction($event)"
      class="editor-tab"
    >
      <mat-tab *ngFor="let item of items; let i = index" id="{{ i }}">
        <ng-template mat-tab-label>
          <svg-icon [name]="item.icon"></svg-icon>
          {{ item.display_name || item.name }}
        </ng-template>
        <app-elements-drawer
          (isDirty)="setDirty($event)"
          *ngIf="openDrawer === 'elements'"
        ></app-elements-drawer>
        <app-templates-drawer
          *ngIf="openDrawer === 'templates'"
        ></app-templates-drawer>
        <text-drawer
          (isDirty)="setDirty($event)"
          *ngIf="openDrawer === 'text'"
        ></text-drawer>
        <resize-drawer
          (isDirty)="setDirty($event)"
          *ngIf="openDrawer === 'resize'"
        ></resize-drawer>
        <shapes-drawer
          (isDirty)="setDirty($event)"
          *ngIf="openDrawer === 'shapes'"
        ></shapes-drawer>
        <filter-drawer
          (isDirty)="setDirty($event)"
          *ngIf="openDrawer === 'filter'"
        ></filter-drawer>
        <crop-drawer
          (isDirty)="setDirty($event)"
          *ngIf="openDrawer === 'crop'"
        ></crop-drawer>
        <draw-drawer
          #controlsDrawer
          (isDirty)="setDirty($event)"
          *ngIf="openDrawer === 'draw'"
        ></draw-drawer>
        <!-- <object-settings-drawer *ngIf="openSettings"></object-settings-drawer> -->
      </mat-tab>
    </mat-tab-group>
  </div>
  <ng-container>
    <div class="editor-control-btn">
      <!-- <button class="" mat-raised-button color="primary" (click)="activeDrawer.close()">
                    Cancel
                 </button> -->
      <button
        class="apply-button"
        mat-raised-button
        color="accent"
        (click)="apply()"
        trans
        *ngIf="dirty"
      >
        Apply
      </button>
    </div>
  </ng-container>
  <div class="editor-work-space">
    <div class="canvas-top-heading">
      <object-settings-drawer *ngIf="false"> </object-settings-drawer>
    </div>
    <div
      class="canvas-wrapper"
      #canvasWrapper
      backgroundImage="images/viewport-bg.png"
    >
      <div
        class="canvas-mask-wrapper not-loaded"
        #canvasMaskWrapper
        [class.empty]="canvas.state.isEmpty()"
        backgroundImage="images/canvas-bg.png"
      >
        <canvas></canvas>
      </div>
    </div>
  </div>
</div>
