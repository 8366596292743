import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { IUnsubscriptionService } from '../../iservices/iUnsubscriptionServices/iUnsubsciption';
@Injectable({
  providedIn: 'root'
})
export class UnsubsciptionService implements IUnsubscriptionService {
  private apiObject: IApiBaseObject;
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService,
  ) {

  }

  /*
    * @method addDND()
    * @desc used to  DND.
    * @param permData :array - Reviews data.
    * @return json array data.
  */
  addDND(permData): any {
    const url = API_ENDPOINTS.DND_POST + '?CustomerToken=' + permData.customerToken + '&Reason=' + permData.reason;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {},
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

}
