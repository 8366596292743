import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICommonService } from '../../../iservices/iCommon';
import { IAlertService } from '../../../iservices/iAlert';
// import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-dialog-popup',
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.scss']
})
export class DialogPopupComponent implements OnInit {
  ID;
  dialogType: string;
  service;
  // dataHasBeenDeleted = new BehaviorSubject(false);
  constructor(
    public dialog: MatDialog,
    @Inject('ICommonService') private commonService: ICommonService,
    @Inject('IAlertService') private alertService: IAlertService,
  ) { }

  ngOnInit(): void {
    const data = this.commonService.getDialogData();
    this.ID = data.id;
    this.dialogType = data.dialogType;
    this.service = data.dataService;
  }
  /**
   * @method remove()
   * @desc delete the row which is selected to remove by dialogType(page) ;
   */
  remove(): void {
    const json = {
      'id': this.ID,
    };
    this.service.delete(json, this.dialogType).
      subscribe(
      res => {
        if (res.code === 5015) {
        this.alertService.success('ROWDELETED', false);
        this.service.dataHasBeenDeleted.next(true);
        } else if (res.code === 1000) {
          this.alertService.success('ROWDELETED', false);
          this.service.dataHasBeenDeleted.next(true);
        } else if (res.code === 5000) {
          this.alertService.error('ERRORDEFAULTCODE', false);
        } else {
          this.alertService.error('ERRORDEFAULTCODE', false);
        }
      }, error => {
        this.alertService.error('ERRORDEFAULTCODE', false);
      });
  }

}
