<div fxLayout="row wrap" fxLayout.xs="column" class="dash-review-list">
  <div fxFlex  fxFlex.gt-sm="1 1 33%" fxFlex.gt-md="25%" style="height:160px">
      <div class="dashboard-review-card yellow">
          <div fxLayout="row">
              <div fxFlex="0 1 auto">
                  <div class="review-icon">
                     <!-- <i class="material-icons"> remove_red_eye </i> -->

                   <img src="..\..\assets\images\icon\dashboard\total-visit.gif" style="width: 100px;">
                  </div>
              </div>
              <div fxFlex>
                  <h5 class="align-right">Total Visit</h5>
                  <p class="align-right review-count">{{dashboardData ?dashboardData.headerSummary.totalVisit: 0}}</p>
              </div>
          </div>
      </div>
  </div>
  <div fxFlex  fxFlex.gt-sm="1 1 33%" fxFlex.gt-md="25%" style="height:160px">
      <div class="dashboard-review-card red">
          <div fxLayout="row">
              <div fxFlex="0 1 auto">
                  <div class="review-icon">
                      <!-- <i class="material-icons">supervised_user_circle</i> -->
                      <img src="..\..\assets\images\icon\dashboard\totalcustomer.jpg" style="width: 100px;margin-top: 15px;">
                  </div>
              </div>
              <div fxFlex>
                  <h5 class="align-right">Total Customer</h5>
                  <p class="align-right review-count">{{dashboardData ?dashboardData.headerSummary.totalCustomer: 0}}</p>
              </div>
          </div>
      </div>
  </div>
  <div fxFlex  fxFlex.gt-sm="1 1 33%" fxFlex.gt-md="25%" style="height:160px">
      <div class="dashboard-review-card green">
          <div fxLayout="row">
              <div fxFlex="0 1 auto">
                  <div class="review-icon">
                      <!-- <i class="material-icons">people</i> -->
                      <img src="..\..\assets\images\icon\dashboard\repeat-customer.gif" style="width: 115px;">
                  </div>
              </div>
              <div fxFlex>
                  <h5 class="align-right">Repeat Customer</h5>
                  <p class="align-right review-count">{{dashboardData ?dashboardData.headerSummary.repeatCustomer: 0}}</p>
              </div>
          </div>
      </div>
  </div>
  <div fxFlex  fxFlex.gt-sm="1 1 33%" fxFlex.gt-md="25%" style="height:160px">
      <div class="dashboard-review-card yellow">
          <div fxLayout="row">
              <div fxFlex="0 1 auto">
                  <div class="review-icon">
                      <!-- <i class="material-icons">local_offer</i> -->
                      <img src="..\..\assets\images\icon\dashboard\redeam-money.gif" style="width: 100px;">
                  </div>
              </div>
              <div fxFlex>
                  <h5 class="align-right">Redemption</h5>
                  <p class="align-right review-count">{{dashboardData ?dashboardData.headerSummary.reedemption : 0}}</p>
              </div>
          </div>
      </div>
  </div>
  <div fxFlex  fxFlex.gt-sm="1 1 33%" fxFlex.gt-md="25%" style="height:160px">
      <div class="dashboard-review-card red">
          <div fxLayout="row">
              <div fxFlex="0 1 auto">
                  <div class="review-icon">
                      <!-- <i class="material-icons">monetization_on</i> -->
                      <img src="..\..\assets\images\icon\dashboard\revenue.jpg" style="width: 93px;">
                  </div>
              </div>
              <div fxFlex>
                  <h5 class="align-right">Revenue</h5>
                  <p class="align-right review-count">{{dashboardData ?dashboardData.headerSummary.revenue: 0}}</p>
              </div>
          </div>
      </div>
  </div>
  <div fxFlex  fxFlex.gt-sm="1 1 33%" fxFlex.gt-md="25%" style="height:160px">
      <div class="dashboard-review-card green">
          <div fxLayout="row">
              <div fxFlex="0 1 auto">
                  <div class="review-icon">
                     <!-- <i class="material-icons">insert_comment</i> -->
                     <img src="..\..\assets\images\icon\dashboard\rating-reviews.jpg" style="width: 93px;">
                  </div>
              </div>
              <div fxFlex>
                  <h5 class="align-right">Feedback & Rating</h5>
                  <p class="align-right review-count">{{dashboardData ?dashboardData.headerSummary.feedbackAndRating: 0}}</p>
              </div>
          </div>
      </div>
  </div>
  <div fxFlex  fxFlex.gt-sm="1 1 33%" fxFlex.gt-md="25%" style="height:160px">
    <div class="dashboard-review-card yellow">
        <div fxLayout="row">
            <div fxFlex="0 1 auto">
                <div class="review-icon">
                   <!-- <i class="material-icons">insert_comment</i> -->
                   <img src="..\..\assets\images\icon\dashboard\total-referal.gif" style="width: 93px;">
                </div>
            </div>
            <div fxFlex>
                <h5 class="align-right">Total Referal</h5>
                <p class="align-right review-count">{{dashboardData ?dashboardData.headerSummary.feedbackAndRating: 0}}</p>
            </div>
        </div>
    </div>
</div>
<div fxFlex  fxFlex.gt-sm="1 1 33%" fxFlex.gt-md="25%" style="height:160px">
  <div class="dashboard-review-card red">
      <div fxLayout="row">
          <div fxFlex="0 1 auto">
              <div class="review-icon">
                 <!-- <i class="material-icons">insert_comment</i> -->
                 <img src="..\..\assets\images\icon\dashboard\refer-and-earn.gif" style="width: 93px;">
              </div>
          </div>
          <div fxFlex>
              <h5 class="align-right">Refer And Earn</h5>
              <p class="align-right review-count">{{dashboardData ?dashboardData.headerSummary.feedbackAndRating: 0}}</p>
          </div>
      </div>
  </div>
</div>
</div>
