import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_ENDPOINTS } from './api-endpoints-constants';
import { SharedService } from './shared.service';
import { STORAGE_KEYS } from './storage-keys-constants';
import { IApiBaseObject } from './../iModels/api-base-object.model';
import { IUserService } from './../iservices/iUser';
import { IApiService } from './../iservices/iApi';
import { ModelBindingService } from './binding.model.service';
import { ModelDecoderBindingService } from './bindingDecoder.model.service';

@Injectable()
export class UserService implements IUserService {
  serviceVariable = new BehaviorSubject('temp');
  computeUserName = this.serviceVariable.asObservable();
  response: any;
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  isHideShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hideShowRightBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private apiObject: IApiBaseObject;
  cust: any;
  directLogin = new BehaviorSubject(false);
  constructor( @Inject('IApiService') private apiService: IApiService,
    private sharedService: SharedService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService,
  ) { }

  /**
   * @method loginUser()
   * @desc used to sign-in user into revugain.
   * @param postLoginData :object - userdata .
   * @return json array data.
   */
  loginUser(postLoginData): any {
    postLoginData = this.modelBindingService.loginModelEncoder(postLoginData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.LOGIN, postLoginData,
      false, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        if (res && res['data'] && res['data'].token) {
          this.setAppDataInStorage(res);
        }
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  setAppDataInStorage(res) {
    console.log('aaa',res);
    this.sharedService.setDataInStorage(STORAGE_KEYS.SESSION, res['data'].token);
    sessionStorage.setItem(STORAGE_KEYS.PERMISSION_SESSION, JSON.stringify(res['data'].permission));
    sessionStorage.setItem(STORAGE_KEYS.SOCIAL_MEDIA_PERMISSION, JSON.stringify(res['data'].socialMediaConnections));
    this.sharedService.setDataInStorage('customerIdentity', res['data'].customerIdentity);
    this.sharedService.setDataInStorage('customerCategory', res['data'].allowCustomerCategory);
    this.sharedService.setDataInStorage('propertyId', res['data'].propertyId);
    this.sharedService.setDataInStorage('propertyCode', res['data'].propertyCode);
    this.sharedService.setDataInStorage('propertyName', res['data'].propertyName);
    this.sharedService.setDataInStorage(STORAGE_KEYS.ROLE, res['data'].userType);
    this.sharedService.setDataInStorage(STORAGE_KEYS.USER_NAME, res['data'].name);
    this.sharedService.setDataInStorage(STORAGE_KEYS.ASK_POPUP_SMS, res['data'].askPopup_SMS);
    this.sharedService.setDataInStorage('billAmountHide', res['data'].disableBillAmount);
    this.sharedService.setDataInStorage('billNoHide', res['data'].disableBillNo);
    this.sharedService.setDataInStorage('optRequired', res['data'].isOtpRequiredForRedim);
    this.sharedService.setDataInStorage('allowCompleteFormView', res['data'].allowCompleteFormView);
    this.sharedService.setDataInStorage('allowRedimCustomPoint', res['data'].allowRedimCustomPoint);
    this.sharedService.setDataInStorage('isCashierReportEnabled', res['data'].isCashierReportEnabled);
    this.sharedService.setDataInStorage('dynamicCategoryName', res['data'].dynamicCategoryName);
    this.sharedService.setDataInStorage('isMainProperty', res['data'].isMainProperty);
    this.sharedService.setDataInStorage('noOfProperty', res['data'].noOfProperty);
    this.sharedService.setDataInStorage('allowWhatsUp', res['data'].allowWhatsUp);
    this.sharedService.setDataInStorage('isWhatsupMarketing', res['data'].isWhatsupMarketing);
  }



   /**
   * @method directLoginUser()
   * @desc used to sign-in user into revugain.
   * @param postLoginData :object - userdata .
   * @return json array data.
   */
  directLoginUser(id): any {
    const url = `${API_ENDPOINTS.DIRECT_LOGIN}?Id=${id}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {},
      true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        if (res && res['data'] && res['data'].token) {
          /* Setting data in storage */
          this.setAppDataInStorage(res);
        }
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /**
   * @method changePassword()
   * @desc used to change the current password.
   * @param changePasswordData :object - userdata .
   */
  changePassword(changePasswordData): any {
    changePasswordData = this.modelBindingService.profilePasswordModelEncoder(changePasswordData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.CHANGE_PASSWORD,
      changePasswordData, true, false);
    return this.apiService.postApi(this.apiObject);
  }


  /**
   * @method changePasswordFromAdmin()
   * @desc used to change the current password.
   * @param changePasswordData :object - userdata .
   */
  changePasswordFromAdmin(data): any {
    const url = `${API_ENDPOINTS.ChangePasswordOthers}?Id=${data.Id}&Password=${data.Password}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.postApi(this.apiObject);
  }

  /**
 * @method addUser()
 * @desc used to add user.
 * @param perm :object - userdata .
 */
  addUser(perm): any {
    perm = this.modelBindingService.userTypeEncoder(perm);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.USER_TYPE_ADD,
      perm, true, false);
        return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }
  /*
  * @method getDashboardDetails()
  * @desc used to get dashboard details .
  * @return json array data.
*/
  getDashboardDetails(data): any {
    const url = `${API_ENDPOINTS.GET_DASHBOARD_DETAILS}?FilterPropertyId=${data.FilterPropertyId}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .dashboardDecoder(resp['data']);
          const json = resp['data'];
          return json;
        }
      }
      ));
  }
  /*
  * @method getUserDetails()
  * @desc used to get user details .
  * @return json array data.
*/
  getUserDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.USER_TYPE_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('userTypeDecoder', resp);
            if (resp['data'].data && resp['data'].data.length > 0) {
              json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
            } else {
              json = { data: resp['data'].data, 'itemCount': 0 };
            }
            return json;
        }
      }
      ));
  }
  /*
  * @method getUserDetails()
  * @desc used to get user details .
  * @return json array data.
*/
  getUserTypeCombo(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.USER_TYPE_COMBO, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('userTypeComboDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
      /*
    * @method getUserDepartmentCombo()
    * @desc used to get combo list .
    * @return json array data.
  */
  getUserDepartmentCombo(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.USER_DEPARTMENT_COMBO, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('propertyDepartmentDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
    * @method getUserDetailsById()
    * @desc used to get user  details by id.
    * @param id :string - id.
    * @return json array data.
  */
  getUserDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.USER_TYPE_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService.userTypeDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }
  /*
   * @method delete()
   * @desc used to delete row data.
   * @param id :array - user type id .
   * @param id :dialogType - dialogType .
   * @return string.
  */
  delete(id): string {
    const url = this.modelBindingService.replaceUrlForDelete(
      API_ENDPOINTS.USER_TYPE_DELETE, id);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }
}
