import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SharedService } from './../../../services/shared.service';
import { CONSTANT } from './../../../utils/constant';
import { ICashierReportService } from './../../../iservices/iCashierReportServices/iCashierReport';
import { ICommonService } from './../../../iservices/iCommon';
import { Component, OnInit, Inject } from '@angular/core';
import { VerifyPasswordComponent } from '../../crm/crmComponents/customers/verify-password/verify-password.component';
import { OtpVerifyComponent } from '../../crm/crmComponents/customers/otp-verify/otp-verify.component';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
declare var saveAs:any;
@Component({
  selector: 'app-cashier-report',
  templateUrl: './cashier-report.component.html',
  styleUrls: ['./cashier-report.component.scss']
})
export class CashierReportComponent {
  selectObj: any = [{ value: '', displayName: 'All' }];
  tableData = {
    id: 'cashierReportTable',
    tableHeader: [
      'Sr No.',
      'Customer Name',
      'Contact Number',
      'Date & Time',
      'Action',
      'Bill Amount',
      'Point',
      'Bill No',
      'Category',
      'Created By',
      'Remark'
    ],
    serviceDetail: {
      service: 'CashierReportService',
      method: 'getCashierReportDetails',
      isParameter: true,
      parameterList: { page: '', size: '', FilterPropertyId: '' }
    },
    mapping: [
      { type: 'index', dataValue: '' },
      { type: 'default', dataValue: 'customerName' },
      { type: 'default', dataValue: 'contactNo' },
      { type: 'datevale', dataValue: 'createdOn' },
      { type: 'default', dataValue: 'action' },
      { type: 'default', dataValue: 'billAmount' },
      { type: 'default', dataValue: 'redimPoint' },
      { type: 'default', dataValue: 'billNo' },
      { type: 'default', dataValue: 'categoryName' },
      { type: 'default', dataValue: 'createdBy' },
      { type: 'default', dataValue: 'remarks' },
    ],
    options: {
      pageSizeArray: [10, 20, 30, 50, 100],
    },
    searchData: {},
  };

  searchOptions = [
    {
      'key': 'customerName',
      'details': {
        'type': 'text',
        generalDetails: { placeholder: 'Search Customer Name' },
      }
    },
    {
      'key': 'BillNo',
      'details': {
        'type': 'text',
        generalDetails: { placeholder: 'Search BillNo' },
      }
    },
    {
      'key': 'CreatedByName',
      'details': {
        'type': 'text',
        generalDetails: { placeholder: 'Created By' },
      }
    },
    {
      'key': 'CustCat',
      'details': {
        'type': 'select',
        generalDetails: { lable: 'Type' },
        DropdownDetails: CONSTANT.CUSTOMER_HISTORY_CATEGORY
      }
    },
    {
      'key': 'CustomerCategoryId',
      'details': {
        'type': 'categorySelect',
        generalDetails: { placeholder: 'Category' },
      }
    },
    {
      'key': 'FromDt',
      'details': {
        'type': 'datePicker',
        generalDetails: { lable: 'From Date:' },
        DropdownDetails: []
      }
    },
    {
      'key': 'ToDt',
      'details': {
        'type': 'datePicker',
        generalDetails: { lable: 'To Date:' },
        DropdownDetails: []
      }
    }
  ];

  constructor(
    @Inject('ICommonService') private commonService: ICommonService,
    @Inject('ICashierReportService') private cashierReportService: ICashierReportService,
    public dialog: MatDialog,
    private sharedService: SharedService
  ) {
    this.tableData.serviceDetail.parameterList.FilterPropertyId = this.sharedService.getSelectedProperties(true);
  }

  /**
   * @method searchDetails()
   * @desc Used to search details.
   * @param inputVal :  string - key on which data is searched.
   */
  searchDetails(inputVal): void {
    const finalSearch = inputVal;
    if (finalSearch.FromDt) {
      finalSearch.FromDt = this.commonService.formatDate(finalSearch.FromDt);
    }
    if (finalSearch.ToDt) {
      finalSearch.ToDt = this.commonService.formatDate(finalSearch.ToDt);
    }
    this.tableData.searchData = finalSearch;
  }

  exportReportData = () => {
    const data = {
      page: 1,
      size: 0
    };
    this.cashierReportService.getCashierReportDetails(data).subscribe((response) => {
      if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
        const datatoExport = _.map(response.data, (element) => {
          return {
            'Customer Name': element.customerName,
            'Contact No': element.contactNo,
            'Date': element.createdOn,
            'Point': element.redimPoint,
            'Bill Amount': element.billAmount,
            'BillNo': element.billNo,
            'Category': element.custCategory,
            'CreatedBy': element.createdBy,
            'Remarks': element.remarks,
            'Pin Code' : element.pinCode,
          };
        });
        this.exportData(datatoExport);
      }
    },
      (error) => {
        console.log(error);
      });
  }

openPasswordVerifyModal = () => {
    let dialogConfig = new MatDialogConfig();
    dialogConfig = {
      disableClose: true,
      autoFocus: true,
      width: '450px',
      panelClass: 'mat-dialog-box',
      data: {}
    };
    const dialogRef = this.dialog.open(VerifyPasswordComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1000) {
        this.openOtpVerifyModal();
      }
    });
  }

  openOtpVerifyModal = () => {
    let dialogConfig = new MatDialogConfig();
    dialogConfig = {
      disableClose: true,
      autoFocus: true,
      width: '450px',
      panelClass: 'mat-dialog-box',
      data: {}
    };
    const dialogRef = this.dialog.open(OtpVerifyComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1000) {
        this.exportReportData();
      }
    });
  }
  /*
  exportCustomer = () => {
    this.customersService.getCustomerExportData().subscribe((response) => {
      if (response.code === 1000) {
        this.customerExportData = _.map(response.data, (element) => {
          return {
            'CustomerName': element.customerName,
            'ContactNo': element.contactNo,
            'Email': element.email,
            'DOB': element.dob,
            'DOA': element.doa,
            'Gender': element.gender,
            'Address': element.address,
            'City': element.city,
            'BillAmount': element.billAmount,
            'Visit' : element.visit,
            'Used Point' : element.usedPoint,
            'Remaining Point': element.remainingPoint,
            'No of Redeem': element.noofRedim,
            'Remarks': element.remarks,
            'BillNo': element.BillNo,
            // 'Categoy': element.Category,
            // 'Pin Code': element.PinCode

          };
        });
        this.exportData();
      }
    },
      (error) => {
        console.log(error);
      });
  }

  exportData = () => {
    const worksheet = XLSX.utils.json_to_sheet(this.customerExportData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'customer');
  }
  */

  exportData = (Data) => {
    const worksheet = XLSX.utils.json_to_sheet(Data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'customerHistory');
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    const EXCEL_EXTENSION = '.xlsx';
    saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  printData() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig = {
      disableClose: true,
      autoFocus: true,
      width: '450px',
      panelClass: 'mat-dialog-box',
      data: {}
    };
    const dialogRef = this.dialog.open(VerifyPasswordComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1000) {
        this.commonService.printDoc.next(true);
      }
    });

    // this.commonService.printDoc.next(true);
  }
}

