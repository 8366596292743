import { Injectable } from '@angular/core';
import { IApiResponse } from '../iModels/apiResponse.model';
import { ISocialMediaMasterModel, IQuoteModel, IBannerModel } from '../iModels/iMasterModels/socialMediaMaster.model';
import { IDepartmentModel } from '../iModels/iMasterModels/department.model';
import { IDepartmentQueModel } from '../iModels/iMasterModels/departmentQue.model';
import { ICustomerTypeModel } from '../iModels/iMasterModels/customerType.model';
import { RESPONSE_KEYS } from './storage-keys-constants';
import { IPropertyTypeModel } from '../iModels/iMasterModels/propertyType.model';
import { IReviewForModel } from '../iModels/iMasterModels/reviewFor.model';
import { IPropertyMasterModel } from '../iModels/iMasterModels/propertyMaster.model';
import { IRangeTypeModel } from '../iModels/iMasterModels/rangeType.model';
import { IRangeModel } from '../iModels/iMasterModels/range.model';
import { IPropertyRangeModel } from '../iModels/iMasterModels/propertyRange.model';
import { IDashboardDetailsModel } from '../iModels/idashboardDetails.model';
import { ILatestReviewListModel } from '../iModels/idashboardDetails.model';
import { ICustomerListModel } from '../iModels/idashboardDetails.model';
import { IElementMasterModel } from '../iModels/iMasterModels/elementMaster.model';
import { IUserTypeComboModel, IUserTypeModel } from '../iModels/login.model';
import { ICustomersModel, ICustomersDashboard } from '../iModels/iCRMModels/customers.model';
import {
  ICustomerDetailModel, ICustomerAllListModel,
  ICustomerBonusListModel, ICustomerAutopilotList, ICustomerEarnListModel, ICustomerPromotionsList, ICustomerPointsRedimListModel, ICustomerMissedCallList
} from '../iModels/iCRMModels/customerDetail.model';

import { IPropertyModel } from '../iModels/iPropertyManagementModels/property.model';
import { IPropertyDepartmentModel } from '../iModels/iPropertyManagementModels/propertyDepartment.model';
import { IPropertyDepartmentQueModel } from '../iModels/iPropertyManagementModels/propertyDepartmentQue.model';
import { IPropertyCustomerTypeModel } from '../iModels/iPropertyManagementModels/propertyCustomerType.model';
import { ISmsEmailApiModel, IApiParamModel } from '../iModels/iPropertyManagementModels/smsEmailApi.model';
import { IPropertyCustomerCategoryModel } from '../iModels/iPropertyManagementModels/propertyCustomerCategory.model';

import { ICashierReportModel } from '../iModels/iCashierReportModels/cashierReport.model';

import { IAutoPilotDetailsModel, IDaysModel } from '../iModels/iPromotionModels/autoPilot.model';
import { IFilterModel, IGetPromotionModel } from '../iModels/iPromotionModels/promotion.model';

import { IQsrQuestionModel } from '../iModels/iQsrReviewModels/qsrReview.model';

import { IReviewModel } from '../iModels/iReviewsModels/review.model';

import { ILoyaltyProgramModel } from '../iModels/iSettingModels/loyaltyProgram.model';
import { ILoyaltyOfferModel } from '../iModels/iSettingModels/loyaltyOffer.model';

@Injectable()
export class ModelDecoderBindingService {
  endpoint;

  apiResponseDecoder(json: any): IApiResponse {
    return {
      code: json.status,
      message: json.message,
      data: json.response,
      count: json.count,
      headerData: json.headerData ? json.headerData : []
    };
  }


  /*
   * @method apiResponseSuccessDecoder()
   * @desc method to decode api response  (when success) json data.
  */
  apiResponseSuccessDecoder(res): IApiResponse {
    return {
      code: res[RESPONSE_KEYS.STATUS],
      message: (res) ? res[RESPONSE_KEYS.MESSAGE] : '',
      data: (res) ? res[RESPONSE_KEYS.DATAKEY] : [],
      count: res.headers ? res.headers.get(RESPONSE_KEYS.TOTAL_COUNT) : '',
      headerData: res.headers ? res.headers : []
    };
  }

  /*
   * @method apiResponseErrorDecoder()
   * @desc method to decode api response  json data.
  */
  apiResponseErrorDecoder(res): IApiResponse {
    return {
      code: res[RESPONSE_KEYS.STATUS],
      message: res[RESPONSE_KEYS.MESSAGE],
      error: res.error || res[RESPONSE_KEYS.ERRORKEY],
    };
  }

  /*
   * @method loginDifferentDecoader()
   * @desc method to decode login   json data.
  */
  loginDifferentDecoader(): any {
    return {
      code: 40123,
      data: {}
    };
  }

  /*
   * @method categoryMasterDecoder()
   * @desc method to decode  json data.
   */
  categoryMasterDecoder(data: ISocialMediaMasterModel) {
    return {
      categoryName: data.categoryName,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
      noofImage: data.noofImage,
      noofQuatation: data.noofQuatation,
      isFestival_Event: data.isFestival_Event
    };
  }

  /*
   * @method departmentDecoder()
   * @desc method to decode  json data.
   */
  departmentDecoder(data: IDepartmentModel) {
    return {
      departmentName: data.departmentName,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
    };
  }
  /*
   * @method departmentDecoder()
   * @desc method to decode  json data.
   */
  departmentQueDecoder(data: IDepartmentQueModel) {
    return {
      departmentId: data.departmentId,
      questionDescription: data.questionDescription,
      isMainQueston: data.isMainQueston,
      departmentName: data.departmentName,
      id: data.id,
      createdOn: data.createdOn,
      propertyName: data.propertyName,
      totalRows: data.totalRows,
    };
  }

  /*
 * @method customerTypeDecoder()
 * @desc method to decode  json data.
 */
  customerTypeDecoder(data: ICustomerTypeModel) {
    return {
      noofDays: data.noofDays,
      customerTypeName: data.customerTypeName,
      billAmount: data.billAmount,
      noofVisits: data.noofVisits,
      ordering: data.ordering,
      createdOn: data.createdOn,
      id: data.id,
      totalRows: data.totalRows,
    };
  }
  /*
   * @method departmentDecoder()
   * @desc method to decode  json data.
   */
  propertyTypeDecoder(data: IPropertyTypeModel) {
    return {
      propertyTypeName: data.propertyTypeName,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
    };
  }

  /*
 * @method propertyMasterDecoder()
 * @desc method to decode json data.
 */
  propertyMasterDecoder(data: IPropertyMasterModel) {
    return {
      propertyName: data.propertyName,
      city: data.city,
      address: data.address,
      noOfProperty: data.noOfProperty,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      countryId: data.countryId,
      stateId: data.stateId,
      cityId: data.cityId,
      countryName: data.countryName,
      stateName: data.stateName,
      cityName: data.cityName,
      pincode: data.pincode
    };
  }

  /*
* @method propertyMasterDecoder()
* @desc method to decode json data.
*/
  elementMasterDecoder(data: any) {
    return {
      title: data.title,
      imageName: data.imageName,
      imagePath: data.imagePath,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows
    };
  }

  /*
   * @method reviewForDecoder()
   * @desc method to decode json data.
   */
  reviewForDecoder(data: IReviewForModel) {
    return {
      reviewForName: data.reviewForName,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
    };
  }
  /*
   * @method rangeTypeDecoder()
   * @desc method to decode  json data.
   */
  rangeTypeDecoder(data: IRangeTypeModel) {
    return {
      rangeTypeName: data.rangeTypeName,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isEdit: data.isEdit,
    };
  }

  /*
   * @method rangeDecoder()
   * @desc method to decode  json data.
   */
  rangeDecoder(data: IRangeModel) {
    return {
      type: data.type,
      id: data.id,
      createdOn: data.createdOn,
      rangeFrom: data.rangeFrom,
      rangeTo: data.rangeTo,
      ordering: data.ordering,
      rangeTypeMasterId: data.rangeTypeMasterId,
      rangeDetails: data.rangeDetails,
      totalRows: data.totalRows,
    };
  }
  /*
   * @method propertyRangeDecoder()
   * @desc method to decode  json data.
   */
  propertyRangeDecoder(data: IPropertyRangeModel) {
    return {
      property: data.property,
      type: data.type,
      id: data.id,
      createdOn: data.createdOn,
      rangeFrom: data.rangeFrom,
      rangeTo: data.rangeTo,
      ordering: data.ordering,
      propertyId: data.propertyId,
      rangeTypeMasterId: data.rangeTypeMasterId,
      rangeDetails: data.rangeDetails,
      totalRows: data.totalRows,
    };
  }
  /*
 * @method propertyRangeDecoder()
 * @desc method to decode  json data.
 */
  filterDecoder(data: IFilterModel) {
    return {
      customerName: data.customerName,
      contactNo: data.contactNo,
      customerId: data.customerId,
      email: data.email,
    };
  }
  /*
   * @method dashboardDecoder()
   * @desc method to decode  json data.
   */
  dashboardDecoder(data: IDashboardDetailsModel) {
    return {
      'headerSummary': {
        totalVisit: data?.headerSummary?.totalVisit,
        totalCustomer: data?.headerSummary?.totalCustomer,
        repeatCustomer: data?.headerSummary?.repeatCustomer,
        reedemption: data?.headerSummary?.reedemption,
        revenue: data?.headerSummary?.revenue,
        feedbackAndRating: data?.headerSummary?.feedbackAndRating,
      },
      'reviewDetail': {
        totalReviewPositive: data.reviewDetail.totalReviewPositive,
        totalReviewNegative: data.reviewDetail.totalReviewNegative,
        todayReviewPositive: data.reviewDetail.todayReviewPositive,
        todayReviewNegative: data.reviewDetail.todayReviewNegative,
        yesterdayReviewPositive: data.reviewDetail.yesterdayReviewPositive,
        yesterdayReviewNegative: data.reviewDetail.yesterdayReviewNegative,
        monthReviewPositive: data.reviewDetail.monthReviewPositive,
        monthReviewNegative: data.reviewDetail.monthReviewNegative
      },
      latestReviewList: this.mappingDataReturn('LatestReviewListDecoder', data.latestReviewList),
      customerList: this.mappingDataReturn('CustomerListDecoder', data.customerList),
      'customerDetail': {
        totalCustomer: data.customerDetail.totalCustomer,
        todayCustomer: data.customerDetail.todayCustomer,
        yesterdayCustomer: data.customerDetail.yesterdayCustomer,
        monthCustomer: data.customerDetail.monthCustomer
      },
      'smsBalance': {
        promotionSMS: data.smsBalance.promotionSMS,
        promotionEmail: data.smsBalance.promotionEmail,
        expireAccountDate: data.smsBalance.expireAccountDate,
      },
      'smsEmailPostSend': {
        totalSMSSend: data.smsEmailPostSend.totalSMSSend,
        todaySMSSend: data.smsEmailPostSend.todaySMSSend,
        yesterdaySMSSend: data.smsEmailPostSend.yesterdaySMSSend,
        thisMonthSMSSend: data.smsEmailPostSend.thisMonthSMSSend,
        totalEmailSend: data.smsEmailPostSend.totalEmailSend,
        todayEmailSend: data.smsEmailPostSend.todayEmailSend,
        yesterdayEmailSend: data.smsEmailPostSend.yesterdayEmailSend,
        thisMonthEmailSend: data.smsEmailPostSend.thisMonthEmailSend,
        totalPostSend: data.smsEmailPostSend.totalPostSend,
        todayPostSend: data.smsEmailPostSend.todayPostSend,
        yesterdayPostSend: data.smsEmailPostSend.yesterdayPostSend,
        thisMonthPostSend: data.smsEmailPostSend.thisMonthPostSend,
      },
      customerAnniversoryList: this.mappingDataReturn('customerAnniversoryListEncoder', data.customerAnniversoryList),
      customerBirthdayList: this.mappingDataReturn('customerBirthdayListEncoder', data.customerBirthdayList)
    };
  }

  /*
   * @method dashboardDecoder()
   * @desc method to decode  json data.
   */

  LatestReviewListDecoder(data: ILatestReviewListModel) {
    return {
      customerName: data.customerName,
      avgRating: data.avgRating,
    };
  }

  /*
  * @method dashboardDecoder()
  * @desc method to decode  json data.
  */

  CustomerListDecoder(data: ICustomerListModel) {
    return {
      customername: data.customerName,
      loginUserId: data.loginUserId,
      contactNo: data.contactNo,
      email: data.email,
      doa: data.doa,
      dob: data.dob,
      remarks: data.remarks,
      propertyId: data.propertyId,
      departmentId: data.departmentId,
      responseFrom: data.responseFrom,
      billAmount: data.billAmount,
      isForeignCustomer: data.isForeignCustomer,
      isOthers: data.isOthers,
      redimPoints: data.redimPoints,
      redimby: data.redimby,
      property_RedimValueId: data.property_RedimValueId,
      address: data.address,
      gender: data.gender,
      createdOnDDMMYYYY: data.createdOnDDMMYYYY,
      customerCategoryId: data.customerCategoryId,
      lastVisitedOn: data.lastVisitedOn,
      customerCategory: data.customerCategory,
      membershipId: data.membershipId,
      id: data.id,
      createdOn: data.createdOn,
      modifiedOn: data.modifiedOn,
      createdIp: data.createdIp,
      modifiedIp: data.modifiedIp,
      createdBy: data.createdBy,
      modifiedBy: data.modifiedBy,
      isActive: data.isActive,

    };
  }
  /*
  * @method customerDecoder()
  * @desc method to decode  json data.
  */
  customerDecoder(data: ICustomersModel): any {
    return {
      customerName: data.customerName,
      loginUserId: data.loginUserId,
      contactNo: data.contactNo,
      email: data.email,
      doa: data.doa,
      dob: data.dob,
      remarks: data.remarks,
      propertyId: data.propertyId,
      departmentId: data.departmentId,
      responseFrom: data.responseFrom,
      billAmount: data.billAmount,
      isForeignCustomer: data.isForeignCustomer,
      isOthers: data.isOthers,
      RedimPoints: data.RedimPoints,
      sendSMS: data.sendSMS,
      customerOffer_Promo_Camp_AutoId: data.customerOffer_Promo_Camp_AutoId,
      redimby: data.redimby,
      property_RedimValueId: data.property_RedimValueId,
      address: data.address,
      gender: data.gender,
      customerCategoryId: data.customerCategoryId,
      customerCategory: data.customerCategory,
      id: data.id,
      isEdit: data.isEdit,
      createdOn: data.createdOn,
      visit: data.visit,
      lastVisit: data.lastVisit,
      isActive: data.isActive,
      usedPoint: data.usedPoint,
      remainingPoint: data.remainingPoint,
      noofRedim: data.noofRedim,
      customerType: data.customerType,
      countryName: data.countryName,
      stateName: data.stateName,
      cityName: data.cityName,
      city: data.city,
      totalRows: data.totalRows,
      membershipId: data.membershipId,
      average: data.average,
      rating: data.rating
    };
  }

  /*
   * @method quoteDecoder()
   * @desc method to decode  json data.
   */

  quoteDecoder(data: IQuoteModel) {
    return {
      quatation: data.quatation,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
      approveTemplate:data.approveTemplate,
      type:data.type,
    };
  }
  /*
   * @method quoteDecoder()
   * @desc method to decode  json data.
   */

  bannerDecoder(data: IBannerModel) {
    return {
      title: data.title,
      imageName: data.imageName,
      categoryName: data.categoryName,
      imagePath: data.imagePath,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
    };
  }
  /*
  * @method customerAllListDecoder()
  * @desc method to decode  json data.
  */

  customerAllListDecoder(data: ICustomerAllListModel) {
    return {
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
      isEdit: data.isEdit,
      customerName: data.customerName,
      avgRating: data.avgRating,
      redimPoint: data.redimPoint,
      propertyId: data.propertyId,
      pointStatus: data.pointStatus,
      status: data.status,
      contactNo: data.contactNo,
      remarks: data.remarks,
      email: data.email,
      createdOnDDMMYYYY: data.createdOnDDMMYYYY,
      modifiedOn: data.modifiedOn,
      createdIp: data.createdIp,
      modifiedIp: data.modifiedIp,
      createdBy: data.createdBy,
      modifiedBy: data.modifiedBy,
    };
  }
  /*
* @method customerPointsRedimListDecoder()
* @desc method to decode  json data.
*/

  customerPointsRedimListDecoder(data: ICustomerPointsRedimListModel) {
    return {
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
      customerName: data.customerName,
      avgRating: data.avgRating,
      redimPoint: data.redimPoint,
      propertyId: data.propertyId,
      pointStatus: data.pointStatus,
      status: data.status,
      isEdit: data.isEdit,
      contactNo: data.contactNo,
      remarks: data.remarks,
      email: data.email,
      createdOnDDMMYYYY: data.createdOnDDMMYYYY,
      modifiedOn: data.modifiedOn,
      createdIp: data.createdIp,
      modifiedIp: data.modifiedIp,
      createdBy: data.createdBy,
      modifiedBy: data.modifiedBy,
      property_RedimValueId: data.property_RedimValueId,
      customerid: data.customerid,
      redimby: data.redimby,
    };
  }
  /*
* @method customerEarnListDecoder()
* @desc method to decode  json data.
*/

  customerEarnListDecoder(data: ICustomerEarnListModel) {
    return {
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
      redimPoint: data.redimPoint,
      propertyId: data.propertyId,
      pointStatus: data.pointStatus,
      status: data.status,
      isEdit: data.isEdit,
      contactNo: data.contactNo,
      remarks: data.remarks,
      email: data.email,
      createdOnDDMMYYYY: data.createdOnDDMMYYYY,
      modifiedOn: data.modifiedOn,
      createdIp: data.createdIp,
      modifiedIp: data.modifiedIp,
      createdBy: data.createdBy,
      modifiedBy: data.modifiedBy,
    };
  }

  /*
 * @method customerAutopilotListDecoder()
 * @desc method to decode  json data.
 */

  customerAutopilotListDecoder(data: ICustomerAutopilotList) {
    return {
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
      redimPoint: data.redimPoint,
      propertyId: data.propertyId,
      pointStatus: data.pointStatus,
      status: data.status,
      isEdit: data.isEdit,
      contactNo: data.contactNo,
      remarks: data.remarks,
      email: data.email,
      createdOnDDMMYYYY: data.createdOnDDMMYYYY,
      modifiedOn: data.modifiedOn,
      createdIp: data.createdIp,
      modifiedIp: data.modifiedIp,
      createdBy: data.createdBy,
      modifiedBy: data.modifiedBy,
    };
  }

  /*
* @method customerPromotionsListDecoder()
* @desc method to decode  json data.
*/

  customerPromotionsListDecoder(data: ICustomerPromotionsList) {
    return {
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
      redimPoint: data.redimPoint,
      propertyId: data.propertyId,
      pointStatus: data.pointStatus,
      status: data.status,
      isEdit: data.isEdit,
      contactNo: data.contactNo,
      remarks: data.remarks,
      email: data.email,
      createdOnDDMMYYYY: data.createdOnDDMMYYYY,
      modifiedOn: data.modifiedOn,
      createdIp: data.createdIp,
      modifiedIp: data.modifiedIp,
      createdBy: data.createdBy,
      modifiedBy: data.modifiedBy,
    };
  }

  /*
   @method customerMissedCallListDecoder()
* @desc method to decode  json data. */
customerMissedCallListDecoder(data: ICustomerMissedCallList) {
    return {
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
      redimPoint: data.redimPoint,
      propertyId: data.propertyId,
      pointStatus: data.pointStatus,
      status: data.status,
      isEdit: data.isEdit,
      contactNo: data.contactNo,
      remarks: data.remarks,
      email: data.email,
      createdOnDDMMYYYY: data.createdOnDDMMYYYY,
      modifiedOn: data.modifiedOn,
      createdIp: data.createdIp,
      modifiedIp: data.modifiedIp,
      createdBy: data.createdBy,
      modifiedBy: data.modifiedBy,
    };
  }

  /*
* @method customerBonusListDecoder()
* @desc method to decode  json data.
*/

  customerBonusListDecoder(data: ICustomerBonusListModel) {
    return {
      status: data.status,
      isEdit: data.isEdit,
      modifiedOn: data.modifiedOn,
      createdIp: data.createdIp,
      modifiedIp: data.modifiedIp,
      createdBy: data.createdBy,
      modifiedBy: data.modifiedBy,
      redimPoint: data.redimPoint,
      createdOnDDMMYYYY: data.createdOnDDMMYYYY,
      remarks: data.remarks,
    };
  }
  /*
 * @method propertyDecoder()
 * @desc method to decode  json data.
 */

  propertyDecoder(data: IPropertyModel) {
    return {
      imageName: data.imageName,
      propertyName: data.propertyName,
      contactPerson: data.contactPerson,
      propertyCode: data.propertyCode,
      propertyMasterName: data.propertyMasterName,
      reviewForName: data.reviewForName,
      email: data.email,
      propertyMasterId: data.propertyMasterId,
      contactNo: data.contactNo,
      contactNo1: data.contactNo1,
      propertyTypeId: data.propertyTypeId,
      reviewForId: data.reviewForId,
      countryId: data.countryId,
      stateId: data.stateId,
      cityId: data.cityId,
      address: data.address,
      logo_Base64String: data.logo_Base64String,
      id: data.id,
      createdOn: data.createdOn,
      expireDate: data.expireDate,
      totalRows: data.totalRows,
      isActive: data.isActive,
      adminUserId: data.adminUserId,
      pincode: data.pincode,
      guid: data.guid
    };
  }

  QrDecoder(data) {
    return {
      departmentName: data.departmentName,
      qsrUserId: data.qsrUserId,
      webUserId: data.webUserId,
      qsrURL: data.qsrURL,
      webURL: data.webURL,
    };
  }

  /*
* @method propertyDepartmentDecoder()
* @desc method to decode  json data.
*/

  propertyDepartmentDecoder(data: IPropertyDepartmentModel) {
    return {
      departmentId: data.departmentId,
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      departmentName: data.departmentName,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
    };
  }
  /*
* @method autoPilotDetailsDecoder()
* @desc method to decode  json data.
*/

  autoPilotDetailsDecoder(data: IAutoPilotDetailsModel) {
    return {
      linkName: data.linkName,
      slogan: data.slogan,
      autoPilotMasterId: data.autoPilotMasterId,
      details: data.details,
      propertyId: data.propertyId,
      autoPilotName: data.autoPilotName,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
      isSettingOn: data.isSettingOn
    };
  }
  /*
* @method autoPilotDaysDecoder()
* @desc method to decode  json data.
*/

  autoPilotDaysDecoder(data: IDaysModel) {
    return {
      days: data.days,
    };
  }
  /*
* @method propertyDepartmentQueDecoder()
* @desc method to decode  json data.
*/

  propertyDepartmentQueDecoder(data: IPropertyDepartmentQueModel) {
    return {
      departmentId: data.departmentId,
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      departmentName: data.departmentName,
      questionDescription: data.questionDescription,
      isMainQueston: data.isMainQueston,
      departmentQuestionId: data.departmentQuestionId,
      isDisplay: data.isDisplay,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
    };
  }
  /*
   * @method customerDetailDecoder()
   * @desc method to decode  json data.
   */
  customerDetailDecoder(data: ICustomerDetailModel) {
    return {
      crmCustomerDetails: this.mappingDataReturn('customerDecoder', data.crmCustomerDetails),
      customerAllList: this.mappingDataReturn('customerAllListDecoder', data.customerAllList),
      customerPointsRedimList:
        this.mappingDataReturn('customerPointsRedimListDecoder', data.customerPointsRedimList),
      customerEarnList: this.mappingDataReturn('customerEarnListDecoder', data.customerEarnList),
      customerBonusList: this.mappingDataReturn('customerBonusListDecoder', data.customerBonusList),
      customerAutopilotList: this.mappingDataReturn('customerAutopilotListDecoder', data.customerAutopilotList),
      customerPromotionsList: this.mappingDataReturn('customerPromotionsListDecoder', data.customerPromotionsList),
      customerMissedCallList: this.mappingDataReturn('customerMissedCallListDecoder', data.customerMissedCallList),
    };
  }
  /*
   * @method customersDashboardDecoder()
   * @desc method to decode  json data.
   */
  customersDashboardDecoder(data: ICustomersDashboard) {
    return {
      newCustomer: data.newCustomer,
      regularCustomer: data.regularCustomer,
      vipCustomer: data.vipCustomer,
      lapsedCustomer: data.lapsedCustomer,
      lostCustomer: data.lostCustomer,
      atRiskCustomer: data.atRiskCustomer,
      totalRevenue: data.totalRevenue,
      atRiskCustomerRevenue: data.atRiskCustomerRevenue,
      vipCustomerRevenue: data.vipCustomerRevenue,
      newCustomerRevenue: data.newCustomerRevenue,
      lostCustomerRevenue: data.lostCustomerRevenue,
      lapsedCustomerRevenue: data.lapsedCustomerRevenue,
      regularCustomerRevenue: data.regularCustomerRevenue,
      lost_LapsedCustomerRevenue: data.lost_LapsedCustomerRevenue,
      atRiskCustomerSale: data.atRiskCustomerSale,
      vipCustomerSale: data.vipCustomerSale,
      newCustomerSale: data.newCustomerSale,
      lostCustomerSale: data.lostCustomerSale,
      lapsedCustomerSale: data.lapsedCustomerSale,
      regularCustomerSale: data.regularCustomerSale,
      lost_LapsedCustomerSale: data.lost_LapsedCustomerSale
    };
  }
  /*
* @method loyaltyProgramDecoder()
* @desc method to decode  json data.
*/

  loyaltyProgramDecoder(data: ILoyaltyProgramModel) {
    return {
      royaltyPointType: data.royaltyPointType,
      amount: data.amount,
      amountPoint: data.amountPoint,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
    };
  }

  /*
   * @method loyaltyOfferDecoder()
   * @desc method to decode  json data.
   */

  loyaltyOfferDecoder(data: ILoyaltyOfferModel) {
    return {
      pointValue: data.pointValue,
      validityDate: data.validityDate,
      noofPonts: data.noofPonts,
      propertyName: data.propertyName,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
    };
  }
  /*
 * @method propertycustomerTypeDecoder()
 * @desc method to decode  json data.
 */
  propertyCustomerTypeDecoder(data: IPropertyCustomerTypeModel) {
    return {
      propertyId: data.propertyId,
      customerTypeId: data.customerTypeId,
      propertyName: data.propertyName,
      noofDays: data.noofDays,
      customerTypeName: data.customerTypeName,
      billAmount: data.billAmount,
      noofVisits: data.noofVisits,
      ordering: data.ordering,
      createdOn: data.createdOn,
      id: data.id,
      totalRows: data.totalRows,
      isEdit: data.isEdit,
    };
  }
  /*
* @method smsEmailApiDecoder()
* @desc method to decode  json data.
*/
  smsEmailApiDecoder(data: ISmsEmailApiModel) {
    return {
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      apiFor: data.apiFor,
      apiDetails: data.apiDetails,
      createdOn: data.createdOn,
      id: data.id,
      totalRows: data.totalRows,
    };
  }
  /*
* @method apiParamDecoder()
* @desc method to decode  json data.
*/
  apiParamDecoder(data: IApiParamModel) {
    return {
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      propertyAPIId: data.propertyAPIId,
      parameter: data.parameter,
      parameterValue: data.parameterValue,
      isMobile: data.isMobile,
      isMessage: data.isMessage,
      createdOn: data.createdOn,
      id: data.id,
      totalRows: data.totalRows,
    };
  }
  /*
* @method propertyCustomerCategoryDecoder()
* @desc method to decode  json data.
*/
  propertyCustomerCategoryDecoder(data: IPropertyCustomerCategoryModel) {
    return {
      category: data.category,
      propertyId: data.propertyId,
      createdOn: data.createdOn,
      id: data.id,
      totalRows: data.totalRows,
    };
  }
  /*
* @method cashierReportDecoder()
* @desc method to decode  json data.
*/
  cashierReportDecoder(data: ICashierReportModel) {
    return {
      customerName: data.customerName,
      contactNo: data.contactNo,
      email: data.email,
      billAmount: data.billAmount,
      redimPoint: data.redimPoint,
      action: data.action,
      totalRows: data.totalRows,
      createdOn: data.createdOn,
      billNo: data.billNo,
      custCategory: data.custCategory,
      createdBy: data.createdBy,
      categoryName: data.categoryName,
      remarks: data.remarks
    };
  }

  /*
* @method reviewDecoder()
* @desc method to decode  json data.
*/
  reviewDecoder(data: IReviewModel) {
    return {
      customerName: data.customerName,
      mobile: data.mobile,
      email: data.email,
      customerId: data.customerId,
      responseFrom: data.responseFrom,
      groupId: data.groupId,
      f1: data.f1,
      f2: data.f2,
      f3: data.f3,
      f4: data.f4,
      f5: data.f5,
      f6: data.f6,
      average: data.average,
      isActive: data.isActive,
      id: data.id,
      totalRows: data.totalRows,
      createdOn: data.createdOn,
    };
  }
  /*
* @method qsrQuestionDecoder()
* @desc method to decode  json data.
*/

  qsrQuestionDecoder(data: IQsrQuestionModel) {
    return {
      propertyid: data.propertyid,
      userId: data.userId,
      departmentId: data.departmentId,
      questionDescription: data.questionDescription,
      isMainQueston: data.isMainQueston,
      questionId: data.questionId,

    };
  }

  /*
* @method qsrQuestionDecoder()
* @desc method to decode  json data.
*/

  promotionDecoder(data: IGetPromotionModel) {
    return {
      title: data.title,
      totalDelivered: data.totalDelivered,
      isEdit: data.isEdit,
      totalVisits: data.totalVisits,
      totalRevenue: data.totalRevenue,
      promoDetails: data.promoDetails,
      validTill: data.validTill,
      isActive: data.isActive,
      id: data.id,
      totalRows: data.totalRows,
      createdOn: data.createdOn,
      scheduleId: data.scheduleId
    };
  }
  /*
* @method userTypeComboDecoder()
* @desc method to decode  json data.
*/

  userTypeComboDecoder(data: IUserTypeComboModel) {
    return {
      code: data.code,
      indexNo: data.indexNo,
      name: data.name,
      isActive: data.isActive,
      id: data.id,
      totalRows: data.totalRows,
      createdOn: data.createdOn,
    };
  }
  /*
* @method userTypeDecoder()
* @desc method to decode  json data.
*/

  userTypeDecoder(data: IUserTypeModel) {
    return {
      propertyDepartmentId: data.propertyDepartmentId,
      propertyId: data.propertyId,
      email: data.email,
      mobileNo: data.mobileNo,
      userTypeId: data.userTypeId,
      loginId: data.loginId,
      password: data.password,
      name: data.name,
      propertyName: data.propertyName,
      userType: data.userType,
      userCode: data.userCode,
      departmentName: data.departmentName,
      isActive: data.isActive,
      id: data.id,
      totalRows: data.totalRows,
      createdOn: data.createdOn,
      isEdit: data.isEdit,
      disableBillAmount: data.disableBillAmount,
      disableBillNo: data.disableBillNo
    };
  }
  /*
   * @method mappingDataReturn()
   * @desc method to decode   response data.
  */

  mappingDataReturn(functionName, apiresponseData): any {
    if (apiresponseData['data']) {
      apiresponseData['response'] = apiresponseData['data'].map(
        res => this[functionName](res)
      );
    }
    if (apiresponseData.length >= 0) {
      apiresponseData['response'] = apiresponseData.map(
        res => this[functionName](res)
      );
    }
    apiresponseData = this.apiResponseDecoder(apiresponseData);
    return apiresponseData;
  }

  /*
  * @method anniversaryDecoder()
  * @desc method to decode  json data.
  */

 customerAnniversoryListEncoder(data) {
  return {
    customerName: data.customerName,
    doa: data.doa,
    contactNo: data.contactNo
  };
}

 /*
  * @method birthdayDecoder()
  * @desc method to decode  json data.
  */
customerBirthdayListEncoder(data) {
  return {
    customerName: data.customerName,
    dob: data.dob,
    contactNo: data.contactNo
  };
}
}
