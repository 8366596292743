import { Component, Inject, OnInit, Renderer2, Input } from '@angular/core';
import { menus } from './menu-element';
import { CONSTANT } from '../../../utils/constant';
import { ISharedService } from '../../../iservices/iShared';
import { CommonPermissionsGuard } from '../../../gaurds/flow.guard';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
  isCollapsed = false;
  @Input() sidenav;
  @Input() sidebar;
  @Input() drawer;
  @Input() matDrawerShow;
  @Input() iconOnly = false;
  public menuItems = menus;
  activeMenuItem: boolean;
  constructor(private renderer: Renderer2,
    public commonPermissionsGuard: CommonPermissionsGuard,
    @Inject('ISharedService') private sharedService: ISharedService) {
    if (this.sharedService.getDataFromStorage(CONSTANT.COLLAPSE_MENU) === 'true') {
      this.renderer.addClass(document.body, 'is-collapsed');
      this.isCollapsed = true;
    }
  }

  ngOnInit() {}
  /**
   * @method onMenuChange()
   * @desc used to show/hide menu using 'is-collapsed class'.
   */
  onMenuChange(): void {
    if (this.isCollapsed === false) {
      this.renderer.addClass(document.body, 'is-collapsed');
      this.isCollapsed = true;
      this.sharedService.setDataInStorage(CONSTANT.COLLAPSE_MENU, true);
    } else {
      this.renderer.removeClass(document.body, 'is-collapsed');
      this.isCollapsed = false;
      this.sharedService.setDataInStorage(CONSTANT.COLLAPSE_MENU, false);
    }
  }

  getItem(item): any {
    this.activeMenuItem = item;
  }

  /**
* @method trackByFn()
* @desc to track the loop for current state.
* @return null
*/

  trackByFn(): any {
    return null;
  }
}
