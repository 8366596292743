import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISocialLoginService } from '../iservices/iSocialLoginServices/iSocialLogin';
import { SocialAuthService  } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SocialLoginService implements ISocialLoginService {
facebookAppToken: String = '';
  constructor(private authService: SocialAuthService, private http: HttpClient, private router: Router) { }

  signInWithFacebook(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
  getFacebookPageAccessToken(token) {
    const url = `https://graph.facebook.com/me/accounts?access_token=${token}`;
    return this.http.get(url);
  }

  signInWithLinkedIn() {
    const clientId = '818506xh057wjy';
    const client_secret = 'gEsWRHflln2XotUo';
    const redirectUrl = `https://localhost:4200/socialmedia/socialmediaconnection`;
    // tslint:disable-next-line: max-line-length
    const code_url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
    return this.http.get(code_url).pipe(
      switchMap((code: any) => {
    // tslint:disable-next-line: max-line-length
        const access_token_url = `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&client_id=${clientId}&redirect_uri=${redirectUrl}&client_secret=${client_secret}&code=${code}`;
       return this.http.get(access_token_url);
      })
    );
  }

  signInWithPinterest() {
    const app_id = '5024720109267105512';
    const redirect_url = window.location.href;
    const url = `https://api.pinterest.com/oauth/?client_id=${app_id}&redirect_uri=${redirect_url}&response_type=code&scope=read_public`;
    return this.http.get(url);
  }

  getPinterestAccessToken(code) {
    const app_id = '5024720109267105512';
    const secret_key = `c8450d8c7d0cadd6346cb8cc6c846e9aef0980843c5d272a79c4a772481077c8`;
    // tslint:disable-next-line:max-line-length
    const url = `https://api.pinterest.com/v1/oauth/token?grant_type=authorization_code&client_id=${app_id}&client_secret=${secret_key}&code=${code}`;
    return this.http.get(url);
  }

  signInWithInstagram() {
    const app_id = '795e5895f8f14b65b4472455df6937dc';
    const redirect_url = window.location.href;
    const url = `https://api.instagram.com/oauth/authorize/?client_id=${app_id}&redirect_uri=${redirect_url}&response_type=code`;
    // return this.http.get(url, { responseType: 'json' });
    window.location.replace(url);
  }

  getInstagramAccessToken(code) {
    const app_id = '795e5895f8f14b65b4472455df6937dc';
    const redirect_url = window.location.href.split('?')[0];
    const secret_key = `c58e879df2994a3ba92d8b21e8e3d7d8`;
    // tslint:disable-next-line:max-line-length
    const url = `https://api.instagram.com/oauth/access_token`;
    // ?client_id=${app_id}&client_secret=${secret_key}&grant_type=authorization_code&redirect_uri=${redirect_url}&code=${code}
    const body = {
      client_id: '795e5895f8f14b65b4472455df6937dc',
      client_secret: `c58e879df2994a3ba92d8b21e8e3d7d8`,
      grant_type: 'authorization_code',
      redirect_uri: window.location.href.split('?')[0],
      code: code
    };
    return this.http.post(url, body);
  }

  signOut(): void {
    this.authService.signOut();
  }

  getFbAppAccessToken = () => {
    const appId = '1044186112425729';
    const app_secret = '445aa67b6cd7e01a8500784c1f624d2e';
    // tslint:disable-next-line:max-line-length
    this.http.get(`https://graph.facebook.com/oauth/access_token?client_id=${appId}&client_secret=${app_secret}&grant_type=client_credentials`).subscribe(
      (response: any) => {
        this.facebookAppToken = response.access_token;
      });
  }

  debugFacebookToken = (token) => {
     return this.http.get(`https://graph.facebook.com/v2.9/debug_token?access_token=${this.facebookAppToken}&input_token=${token}`);
  }
}
