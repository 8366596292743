<div class="toolbar-user-container">
  <button mat-button (click)="isOpen = !isOpen" class="toolbar-user-btn" [class.open]="isOpen" [ngStyle.xs]="{'min-width': '70px'}"
   style="align-items: center;">
  		<span fxLayout="row" fxLayoutAlign="start center">
    		<!-- <img class="avatar" [src]="currentUser?.photoURL || 'assets/images/avatars/noavatar.png'"> -->
			<img src="./assets/images/dummy-user-image.png" alt="" class="user-login-image">
			<span class="name" fxHide fxShow.gt-xs> {{userName}}</span>
    		<mat-icon class="icon" fxHide fxShow.gt-xs>keyboard_arrow_down</mat-icon>
  		</span>
  	</button>


  	<div class="dropdown mat-elevation-z1" [class.open]="isOpen">
    	<div class="content">
      		<mat-nav-list *ngIf="type !== 'cashier'">
      			<mat-list-item *ngIf="type === 'sa' || type === 'admin'">
      				<a routerLink="/user" matLine >User</a>
				    <button routerLink="/user" mat-icon-button>
				       <mat-icon>account_circle</mat-icon>
				    </button>
      			</mat-list-item>
      			<mat-list-item>
      				<a matLine >Settings</a>
				    <button mat-icon-button>
				       <mat-icon>settings</mat-icon>
				    </button>
				  </mat-list-item>
				  <mat-list-item>
					<a matLine (click)="changePassword()">Change Password</a>
				  <button (click)="changePassword()" mat-icon-button>
					 <mat-icon>settings</mat-icon>
				  </button>
				</mat-list-item>
      			<mat-list-item>
      				<a matLine >Help</a>
				    <button mat-icon-button>
				       <mat-icon>help</mat-icon>
				    </button>
      			</mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item *ngIf="(type === 'sa' || type === 'admin') && (noOfProperty > 1) && (isMainProperty === 'true')">
      				<a routerLink="/propertySelect" matLine >Switch Property</a>
				    <button routerLink="/propertySelect" mat-icon-button>
              <mat-icon>switch_camera</mat-icon>
				    </button>
            </mat-list-item>
            <mat-divider></mat-divider>

      			<mat-list-item>
      				<a matLine (click)="signOut()">Logout</a>
				    <button (click)="signOut()" mat-icon-button>
				       <mat-icon>exit_to_app</mat-icon>
				    </button>
            </mat-list-item>
			  </mat-nav-list>
			<mat-nav-list *ngIf="type === 'cashier'">
					<mat-list-item>
							<a matLine (click)="changePassword()">Change Password</a>
						  <button (click)="changePassword()" mat-icon-button>
							 <mat-icon>settings</mat-icon>
						  </button>
						</mat-list-item>
					<mat-list-item>
							<a matLine (click)="signOut()">Logout</a>
						  <button (click)="signOut()" mat-icon-button>
							 <mat-icon>exit_to_app</mat-icon>
						  </button>
						</mat-list-item>
				</mat-nav-list>
    	</div>
  	</div>
</div>
