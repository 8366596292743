import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationMessage } from '../../../../../../utils/locale';
import { IAlertService } from '../../../../../../iservices/iAlert';
import { ISocialMediaMasterService } from '../../../../../../iservices/iMasterServices/iSocialMediaMaster';
import { COMMONFORMVALIDATIONRULE } from '../../../../../../utils/validations/commonFormRules';
import { ILoaderService } from '../../../../../../iservices/iLoader';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.scss']
})
export class AddBannerComponent implements OnInit {
  isSubmitted = false;
  serverErrorMsg;
  bannerForm: FormGroup;
  serverError;
  obj = Object.getOwnPropertyNames;
  rowId;
  categoryId;
  constructor(
    private formBuilder: FormBuilder,
    private validationMessage: ValidationMessage,
    private dialogRef: MatDialogRef<AddBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('ISocialMediaMasterService') private socialMediaMasterService: ISocialMediaMasterService,
    @Inject('IAlertService') private alertService: IAlertService,
    @Inject('ILoaderService') private loaderService: ILoaderService
  ) {
  }

  /**
    * @method ngOnInit()
    * @desc used to Initialize Page.
    */

  ngOnInit(): void {
    this.buildForm();
      this.categoryId = this.data.id;
  }

  /**
   * @method buildForm()
   * @desc used to build form.
   */

  buildForm(): void {
    this.bannerForm = this.formBuilder.group({
      imageDetailsList: ['', Validators.required],
      title: ['', COMMONFORMVALIDATIONRULE.commonChar],
      categoryId: [null]

    });
  }
  /**
     * @method submitImages()
     * @desc used to submit images.
     */

  submitImages(): void {
    this.isSubmitted = true;
    this.bannerForm.value.categoryId = this.categoryId;
    if (this.bannerForm.value.imageDetailsList === '') {
      this.bannerForm.get('imageDetailsList').setErrors({ required: true });
    } else {
      for (const file of this.bannerForm.value.imageDetailsList) {
        file.title = this.bannerForm.value.title;
      }
    }
    delete this.bannerForm.value['title'];
     if (this.bannerForm.valid) {
      this.loaderService.display(true);
    this.socialMediaMasterService.addBanner(this.bannerForm.value).subscribe(
      res => {
        if (res.code === 1000) {
          this.loaderService.display(false);
          this.alertService.success('BANNERUPLOAD', false);
          this.dialogRef.close();
          this.socialMediaMasterService.tableUpdated.next(true);
        } else if (res.code === 1001) {
          this.alertService.info(res.description, false);
          this.loaderService.display(false);
        } else {
          this.alertService.error('ERRORDEFAULTCODE', false);
          this.loaderService.display(false);
        }
      },
      error => {
        this.loaderService.display(false);
      }
    );
  }
  }
  /**
 * @method getImage()
 * @desc get Image from on upload .
 */

  getImage(imageData): void {
    if (imageData) {
      if (imageData.Error) {
        this.bannerForm.patchValue({
          imageDetailsList: ''
        });
      } else {
        this.baseconverter(imageData);
        this.bannerForm.patchValue({
          imageDetailsList: imageData
        });
      }
    }
  }

  /**
   * @method baseconverter()
   * @desc get image converter to base64.
   */
  baseconverter(imageData) {
    for (const p of imageData) {
      const rd = new FileReader();
      rd.readAsDataURL(p.image_Base64);
      rd.onloadend = (e) => {
        p.image_Base64 =  (rd.result as string).split(',')[1];
      };
    }
    return imageData;

  }
}
