import { Component, DoCheck, Inject, Input, OnChanges } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ILoaderService } from '../app/iservices/iLoader';
import { IUserService } from '../app/iservices/iUser';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { SharedService } from './services/shared.service';
import { STORAGE_KEYS } from './services/storage-keys-constants';
import * as _ from 'lodash';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnChanges, DoCheck {
  progressBar: boolean;
  showHideVar: boolean;
  showMenuComponent = true;
  title = '';
  visibility = 'shown';
  sideNavOpened = true;
  matDrawerOpened = false;
  matDrawerShow = true;
  sideNavMode = 'side';
  headerType = '';
  @Input() isVisible = true;
  ngOnChanges() {
    this.visibility = this.isVisible ? 'shown' : 'hidden';
  }
  constructor(private routerService: Router,
    private titleService: Title,
    private translate: TranslateService,
    private router: Router,
    @Inject('ILoaderService') private loaderService: ILoaderService,
    @Inject('IUserService') private userService: IUserService,
    private media: MediaObserver,
    private sharedService: SharedService
  ) {
    translate.setDefaultLang('en');

    routerService.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        this.title = event.state.root.children[0].data.title ||
          event.state.root.children[0].children[0].data.title;
        this.titleService.setTitle(this.title + '-Revugain');
      }
      let url = this.routerService.url.split('?')[0];
      if (_.includes(url, 'shorturlimage')) {
        url = '/shorturlimage';
      }
      switch (url) {
        case '/login':
        case '/register':
        case '/forgot':
        case '/Rev':
            this.showMenuComponent = false;
            break;
        case '/Review':
          this.showMenuComponent = false;
          break;
        case '/unsubscribe':
          this.showMenuComponent = false;
          break;
        case '/referral':
          this.showMenuComponent = false;
          break;
          case '/CurrentOffer':
          this.showMenuComponent = false;
          break;
          case '/custinfo':
            this.showMenuComponent = false;
            break;
          case '/shorturlimage':
            this.showMenuComponent = false;
            break;
        default:
          if (this.title === 'Page not Found' || this.title === 'Reset Password') {
            this.showMenuComponent = false;
          } else {
            this.showMenuComponent = true;
          }
          break;
      }
    });

    this.userService.isHideShow.subscribe((show: boolean) => {
      const showHide = show;
      if (showHide) {
        this.showHideVar = showHide;
      } else {
        this.showHideVar = showHide;
      }
    });

    this.loaderService.status.subscribe((load: boolean) => {
      const display = load;
      if (display) {
        this.progressBar = display;
      } else {
        this.progressBar = display;
      }
    });

    this.media.media$.subscribe((mediaChange: MediaChange) => {
      this.toggleView();
    });
  }

  getRouteAnimation(outlet) {

    return outlet.activatedRouteData.animation;
    // return outlet.isActivated ? outlet.activatedRoute : ''
  }

  toggleView() {
    if (this.media.isActive('gt-md')) {
      this.sideNavMode = 'side';
      this.sideNavOpened = true;
      this.matDrawerOpened = false;
      this.matDrawerShow = true;
    } else if (this.media.isActive('gt-xs')) {
      this.sideNavMode = 'side';
      this.sideNavOpened = false;
      this.matDrawerOpened = true;
      this.matDrawerShow = true;
    } else if (this.media.isActive('lt-sm')) {
      this.sideNavMode = 'over';
      this.sideNavOpened = false;
      this.matDrawerOpened = false;
      this.matDrawerShow = false;
    }
  }

  ngDoCheck(): void {
    const role = this.sharedService.getDataFromStorage(STORAGE_KEYS.ROLE);
    if (role === 'cashier') {
      this.headerType = role;
    } else {
      this.headerType = '';
    }
  }
}

