<div class="mat-search-row">
  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayout.sm="column"
    fxLayoutGap="2%"
    *ngFor="let rowData of searchOptions; let key = index; trackBy: trackByFn"
    class="mar-search-col"
  >
  <div fxFlex *ngIf="rowData.details.type === 'radio'">
    <mat-radio-group aria-label="Select an option" name="radioSelect" [(ngModel)]="searchData[key]">
      <mat-radio-button [value]="rowData.details.generalDetails.value1">{{rowData.details.generalDetails.option1}}</mat-radio-button>
      <mat-radio-button [value]="rowData.details.generalDetails.value2">{{rowData.details.generalDetails.option2}}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div fxFlex *ngIf="rowData.details.type === 'radio-custom'">
    <mat-radio-group aria-label="Select an option" name="radioSelect" [(ngModel)]="searchData[key]">
      <mat-radio-button [value]="row.value" *ngFor="let row of rowData.details.generalDetails.multipleradio">{{row.name}}</mat-radio-button>
    </mat-radio-group>
  </div>
    <div fxFlex *ngIf="rowData.details.type === 'select'">
      <mat-form-field *ngIf="rowData.details.type === 'select'">
        <mat-select
          [(ngModel)]="searchData[key]"
          placeholder="{{ rowData.details.generalDetails.lable }}"
        >
          <mat-option>
            Select
          </mat-option>
          <mat-option
            *ngFor="
              let customSearchValue of rowData.details.DropdownDetails;
              trackBy: trackByFn
            "
            [value]="customSearchValue.value"
          >
            {{ customSearchValue.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'departmentSelect'">
      <mat-form-field *ngIf="rowData.details.type === 'departmentSelect'">
        <mat-select
          [(ngModel)]="searchData[key]"
          placeholder="Select Department"
        >
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForDepartment"
          ></app-mat-select-search>
          <mat-option>Select Department</mat-option>
          <mat-option
            *ngFor="
              let deptname of filteredItemsMultiForDepartment | async;
              trackBy: trackByFn
            "
            (ng-checked)="(true)"
            [value]="deptname.id"
          >
            {{ deptname.departmentName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'customerTypeSelect'">
      <mat-form-field *ngIf="rowData.details.type === 'customerTypeSelect'">
        <mat-select [(ngModel)]="searchData[key]" placeholder="Select Type">
          <app-mat-select-search
            [formControl]="CustomerType"
          ></app-mat-select-search>
          <mat-option>Select Type</mat-option>
          <mat-option
            *ngFor="let type of customerTypes"
            (ng-checked)="(true)"
            [value]="type.customerTypeId"
          >
            {{ type.customerTypeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'categorySelect'">
      <mat-form-field *ngIf="rowData.details.type === 'categorySelect'">
        <mat-select [(ngModel)]="searchData[key]" placeholder="Select Type">
          <mat-option>Category</mat-option>
          <mat-option
            *ngFor="let category of propertyCustomerCategory;
            trackBy: trackByFn"
            (ng-checked)="(true)"
            [value]="category?.id"
          >
            {{ category?.category }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'redeemSelect'">
      <mat-form-field *ngIf="rowData.details.type === 'redeemSelect'">
        <mat-select
          [(ngModel)]="searchData[key]"
          placeholder="Select No OF Redeem"
        >
          <app-mat-select-search
            [formControl]="NoofRedimId"
          ></app-mat-select-search>
          <mat-option>Select No OF Redeem</mat-option>
          <mat-option
            *ngFor="let range of redeemList"
            (ng-checked)="(true)"
            [value]="range.id"
          >
            {{ range.rangeFrom }} - {{ range.rangeTo }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="(loginUserType==='sa'||loginUserType==='admin') && (noOfProperty > 1) && (isMainProperty === 'true') && (rowData.details.type === 'propertyCustomerSearchSelect')">
      <mat-form-field *ngIf="rowData.details.type === 'propertyCustomerSearchSelect'">
        <mat-select
          [(ngModel)]="searchData[key]"
          placeholder="Select Property"
        >
          <app-mat-select-search
            [formControl]="NoofRedimId"
          ></app-mat-select-search>
          <mat-option>Select Property</mat-option>
          <mat-option
            *ngFor="let item of propertyCustomerSearchData"
            (ng-checked)="(true)"
            [value]="item.id"
          >
            {{ item.propertyName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'visitSelect'">
      <mat-form-field *ngIf="rowData.details.type === 'visitSelect'">
        <mat-select
          [(ngModel)]="searchData[key]"
          placeholder="Select No OF Visits"
        >
          <app-mat-select-search
            [formControl]="NoofVisitId"
          ></app-mat-select-search>
          <mat-option>Select No OF Visits</mat-option>
          <mat-option
            *ngFor="let visitList of visitsList"
            (ng-checked)="(true)"
            [value]="visitList.id"
          >
            {{ visitList.rangeFrom }} - {{ visitList.rangeTo }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'propertySelect'">
      <mat-form-field *ngIf="rowData.details.type === 'propertySelect'">
        <mat-select
          [(ngModel)]="searchData[key]"
          placeholder="Select Property Name"
        >
          <mat-option>Select Property Name</mat-option>
          <mat-option
            *ngFor="let propertyType of propertyTypeData; trackBy: trackByFn"
            [value]="propertyType.id"
          >
            {{ propertyType.propertyTypeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'sourceTypeSelect'">
      <mat-form-field *ngIf="rowData.details.type === 'sourceTypeSelect'">
        <mat-select [(ngModel)]="searchData[key]" placeholder="Select Source">
          <mat-option>Select Source</mat-option>
          <mat-option
            *ngFor="let user of sourceTypeSelectData; trackBy: trackByFn"
            [value]="user?.userDetails"
          >
            {{ user?.users }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'propertyTypeSelect'">
      <mat-form-field *ngIf="rowData.details.type === 'propertyTypeSelect'">
        <mat-select
          [(value)]="searchData[key]"
          placeholder="Select Property Name"
          (selectionChange)="getPropId($event.value)"
        >
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForProperty"
          ></app-mat-select-search>
          <mat-option>Select Property Name</mat-option>
          <mat-option
            *ngFor="
              let propertyName of filteredItemsMultiForProperty | async;
              trackBy: trackByFn
            "
            (ng-checked)="(true)"
            [value]="propertyName.id"
          >
            {{ propertyName.propertyName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'rangeTypeSelect'">
      <mat-form-field *ngIf="rowData.details.type === 'rangeTypeSelect'">
        <mat-select
          [(ngModel)]="searchData[key]"
          placeholder="Select Range Type"
        >
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForRangeType"
          ></app-mat-select-search>
          <mat-option>Select Range Type</mat-option>
          <mat-option
            *ngFor="
              let range of filteredItemsMultiForRangeType | async;
              trackBy: trackByFn
            "
            (ng-checked)="(true)"
            [value]="range.id"
          >
            {{ range.rangeTypeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex *ngIf="rowData.details.type === 'propertyDepartmentSelect'">
      <mat-form-field>
        <mat-select
          [(ngModel)]="searchData[key]"
          placeholder="Select Property Department "
        >
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForPropertyDepartment"
          ></app-mat-select-search>
          <mat-option>Select Property Department</mat-option>
          <mat-option
            *ngFor="
              let deptname of filteredItemsMultiForPropertyDepartment | async;
              trackBy: trackByFn
            "
            (ng-checked)="(true)"
            [value]="deptname.id"
          >
            {{ deptname.departmentName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex *ngIf="rowData.details.type === 'propertyMasterSelect'">
      <mat-form-field>
        <mat-select
          [(ngModel)]="searchData[key]"
          placeholder="Select Main Property"
        >
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForPropertyMaster"
          ></app-mat-select-search>
          <mat-option>Select Main Property</mat-option>
          <mat-option
            *ngFor="
              let type of filteredItemsMultiForPropertyMaster | async;
              trackBy: trackByFn
            "
            [value]="type.id"
            (ng-checked)="(true)"
          >
            {{ type.propertyName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'promotionStatusSelect'">
      <mat-form-field>
        <mat-select [(ngModel)]="searchData[key]" placeholder="Select Status">
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForPromotionStatus"
          ></app-mat-select-search>
          <mat-option>Select Status</mat-option>
          <mat-option
            *ngFor="
              let type of filteredItemsMultiForPromotionStatus | async;
              trackBy: trackByFn
            "
            [value]="type.id"
            (ng-checked)="(true)"
          >
            {{ type.statusName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'reviewForSelect'">
      <mat-form-field>
        <mat-select
          [(ngModel)]="searchData[key]"
          placeholder="Select Review For"
        >
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForReviewFor"
          ></app-mat-select-search>
          <mat-option>Select Review For</mat-option>
          <mat-option
            *ngFor="
              let type of filteredItemsMultiForReviewFor | async;
              trackBy: trackByFn
            "
            [value]="type.id"
            (ng-checked)="(true)"
          >
            {{ type.reviewForName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex *ngIf="rowData.details.type === 'departmentComboSelect'">
      <mat-form-field>
        <mat-select [(value)]="searchData[key]" placeholder="Select Department">
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForDepartmentCombo"
          ></app-mat-select-search>
          <mat-option
            *ngFor="
              let type of filteredItemsMultiForDepartmentCombo | async;
              trackBy: trackByFn
            "
            [value]="type.id"
            (ng-checked)="(true)"
          >
            {{ type.departmentName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex *ngIf="rowData.details.type === 'userTypeComboSelect'">
      <mat-form-field>
        <mat-select [(value)]="searchData[key]" placeholder="Select User Type">
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForUserType"
          ></app-mat-select-search>
          <mat-option>Select User Type</mat-option>
          <mat-option
            *ngFor="
              let user of filteredItemsMultiForUserType | async;
              trackBy: trackByFn
            "
            (ng-checked)="(true)"
            [value]="user.id"
          >
            {{ user.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex *ngIf="rowData.details.type === 'userDepartmentComboSelect'">
      <mat-form-field>
        <mat-select [(value)]="searchData[key]" placeholder="Select Department">
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForUserDepartment"
          ></app-mat-select-search>
          <mat-option
            *ngFor="
              let type of filteredItemsMultiForUserDepartment | async;
              trackBy: trackByFn
            "
            [value]="type.id"
            (ng-checked)="(true)"
          >
            {{ type.departmentName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'text'">
      <mat-form-field class="example-full-width">
        <input
          matInput
          (keydown.enter)="searchDetail()"
          [(ngModel)]="searchData[key]"
          placeholder="{{ rowData.details.generalDetails.placeholder }}"
          value=""
        />
      </mat-form-field>
      <!-- <input (keydown.enter)="searchDetail()" [(ngModel)]="searchData[key]" placeholder="{{ rowData.details.generalDetails.placeholder }}"> -->
    </div>
    <div fxFlex *ngIf="rowData.details.type === 'number'">
      <mat-form-field class="example-full-width">
        <input
          matInput
          (keydown.enter)="searchDetail()"
          [(ngModel)]="searchData[key]"
          placeholder="{{ rowData.details.generalDetails.placeholder }}"
          value=""
          type="number"
        />
      </mat-form-field>
      <!-- <input (keydown.enter)="searchDetail()" [(ngModel)]="searchData[key]" placeholder="{{ rowData.details.generalDetails.placeholder }}"> -->
    </div>
    <div fxFlex *ngIf="rowData.details.type === 'countrySelect'">
      <mat-form-field *ngIf="rowData.details.type === 'countrySelect'">
        <mat-select
          [(ngModel)]="searchData[key]"
          placeholder="Select Country"
          (selectionChange)="changeCountry($event.value)"
        >
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForCountry"
          ></app-mat-select-search>
          <mat-option>Select Country</mat-option>
          <mat-option
            *ngFor="
              let country of filteredItemsMultiForCountry | async;
              trackBy: trackByFn
            "
            (ng-checked)="(true)"
            [value]="country.id"
          >
            {{ country.commonDesc }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'stateSelect'">
      <mat-form-field *ngIf="rowData.details.type === 'stateSelect'">
        <mat-select
          [(ngModel)]="searchData[key]"
          placeholder="Select State"
          (selectionChange)="changeState($event.value)"
        >
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForState"
          ></app-mat-select-search>
          <mat-option>Select State</mat-option>
          <mat-option
            *ngFor="
              let state of filteredItemsMultiForState | async;
              trackBy: trackByFn
            "
            (ng-checked)="(true)"
            [value]="state.id"
          >
            {{ state.commonDesc1 }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex *ngIf="rowData.details.type === 'citySelect'">
      <mat-form-field *ngIf="rowData.details.type === 'citySelect'">
        <mat-select [(ngModel)]="searchData[key]" placeholder="Select City">
          <app-mat-select-search
            [formControl]="itemMultiFilterCtrlForCity"
          ></app-mat-select-search>
          <mat-option>Select City</mat-option>
          <mat-option
            *ngFor="
              let city of filteredItemsMultiForCity | async;
              trackBy: trackByFn
            "
            (ng-checked)="(true)"
            [value]="city.id"
          >
            {{ city.commonDesc2 }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div [ngClass]="{'no-grow': rowData?.details?.generalDetails?.notAllowGrow}"
     fxFlex *ngIf="rowData.details.type === 'datePicker'">
      <mat-form-field *ngIf="rowData.details.type === 'datePicker'">
        <input
          [(ngModel)]="searchData[key]"
          matInput
          [matDatepicker]="createdOn"
          (click)="createdOn.open()"
          readonly
          placeholder="{{ rowData.details.generalDetails.lable }}"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="createdOn"
        ></mat-datepicker-toggle>
        <mat-datepicker #createdOn></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <button
    *ngIf="searchOptions.length"
    type="button"
    mat-raised-button
    color="primary"
    class="btn btn-info-unfilled btn-mini search-btn pull-left"
    (click)="searchDetail()"
  >
    {{ "commonSearch.button" | translate }}
  </button>
  <button
    *ngIf="searchOptions.length"
    type="button"
    mat-raised-button
    color="primary"
    class="btn btn-info-unfilled btn-mini search-btn pull-left ml-5"
    (click)="resetDetail()"
  >
    {{ "commonSearch.reset" | translate }}
  </button>
</div>
