import { Component, Inject, OnInit, Input, HostListener, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { STORAGE_KEYS } from '../../../services/storage-keys-constants';
import { CONSTANT } from '../../../utils/constant';
import { Router } from '@angular/router';
import { ISharedService } from '../../../iservices/iShared';
import { IUserService } from '../../../iservices/iUser';
import { LocalStorageService } from '../../../services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../../auth/change-password/change-password.component';
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  isOpen = false;
  userName: any;
  @Input() currentUser = null;
  CURRENT_USER_DATA;
  noOfProperty: any;
  isMainProperty: any;
  type;
  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isOpen = false;
    }
  }
  constructor(private elementRef: ElementRef,
    @Inject('ISharedService') private sharedService: ISharedService,
    @Inject('IUserService') private userService: IUserService,
    private localStorageService: LocalStorageService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.isMainProperty = this.sharedService.getDataFromStorage('isMainProperty');
    this.noOfProperty = this.sharedService.getDataFromStorage('noOfProperty');
   }
  /**
   * @method ngOnInit()
   * @desc used to Initialize Page.
   */
  ngOnInit(): void {
    this.initUserData();
    this.initDirectLogin();
  }

  /**
 * @method signOut()
 * @desc used to clear session of user.
 */
  signOut(): void {
    this.sharedService.setDataInStorage(STORAGE_KEYS.SESSION, null);
    this.sharedService.setDataInStorage(STORAGE_KEYS.CHAIN_SESSION, null);
    this.sharedService.setDataInStorage(STORAGE_KEYS.PERMISSION_SESSION, null);
    this.sharedService.setDataInStorage(STORAGE_KEYS.ROLE, null);
    this.sharedService.setDataInStorage(STORAGE_KEYS.USER_NAME, null);
    this.router.navigate(['/login']);
  }
  /**
* @method changePassword()
* @desc used to navigate change password.
*/
  changePassword(): void {
    // this.router.navigate(['/changepassword']);
    this.dialog.open(ChangePasswordComponent, {
      width: '',
      disableClose: true,
      panelClass: ['mat-dialog-box', 'mid-hr-ver-popup'],
      data: {}
    });
  }

  /**
 * @method getUserProfileDetail()
 * @desc used to get user details to show them in header.
 */
  getUserProfileDetail(): void {
    const response = this.sharedService.getDataFromStorage(STORAGE_KEYS.SESSION);
    if (response) {
      this.userName = `${response.firstName} ${response.lastName}`;
    }
  }

  initUserData = () => {
    this.userName = this.sharedService.getDataFromStorage(STORAGE_KEYS.USER_NAME);
    this.CURRENT_USER_DATA =
      JSON.parse(this.localStorageService.getLocalStorageData(STORAGE_KEYS.CURRENT_USER_DATA));
    this.type = this.sharedService.getDataFromStorage(STORAGE_KEYS.ROLE);
  }

  initDirectLogin = () => {
    this.userService.directLogin.subscribe((response) => {
      if (response === true) {
        this.initUserData();
      }
    });
  }
}
