<div class="content">
    <color-widget [formControl]="activeObject.getControl('fill')" trans>Color Picker</color-widget>
    <div class="">

    <div class="buttons">
        <button (click)="toggleValue('text.underline')" [class.selected]="activeObject.getValue('text.underline')"
            mat-icon-button>
            <svg-icon name="format-underlined"></svg-icon>
        </button>
        <button (click)="toggleValue('text.linethrough')" [class.selected]="activeObject.getValue('text.linethrough')"
            mat-icon-button>
            <svg-icon name="format-strikethrough"></svg-icon>
        </button>
        <button (click)="toggleItalicStyle()" [class.selected]="activeObject.getValue('text.fontStyle') === 'italic'"
            mat-icon-button>
            <svg-icon name="format-italic"></svg-icon>
        </button>
        <button (click)="toggleBoldStyle()" [class.selected]="activeObject.getValue('text.fontStyle') === 'bold'"
            mat-icon-button>
            B
        </button>
    </div>
        <mat-form-field appearance="outline" class="white-bdr-input">
        <mat-label>Select Font Size</mat-label>
        <mat-select (selectionChange) = "setTextSize()" [(ngModel)] = "fontSize">
            <mat-option *ngFor="let size of fontSizes" [value]="size">
                {{size}}
            </mat-option>
        </mat-select>
        </mat-form-field>
    </div>
    <div class="add-text-button-wrapper">
        <div class="add-text-btn" (click)="addText()" trans>Add Text <i class="material-icons ml-5"> add_circle_outline</i></div>
        <div class="" >
            <!-- <svg-icon name="add"></svg-icon> -->
        </div>
    </div>
<section class="select-font-wrapper">
    <div class="bottom-label" trans>Font</div>
    <button class="select-font-button no-style" (click)="openGoogleFontsPanel(fontsButton)" #fontsButton [style.fontFamily]="textTool.getStyle('fontFamily')">
        <span class="text">{{textTool.getStyle('fontFamily')}}</span>
        <svg-icon name="keyboard-arrow-down"></svg-icon>
    </button>
</section>
</div>