import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { IPropertyCustomerCategoryService } from '../../iservices/iPropertyMngServices/iPropertyCustomerCategory';
import { MatDialog } from '@angular/material/dialog';
// tslint:disable-next-line:max-line-length
import { AddPropertyCustomerCategoryComponent } from '../../component/property-management/property-managementComponents/property-customer-category/add-property-customer-category/add-property-customer-category.component';
@Injectable({
  providedIn: 'root'
})
export class PropertyCustomerCategoryService implements IPropertyCustomerCategoryService {
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  private apiObject: IApiBaseObject;
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    public dialog: MatDialog,
    private modelDecoderBindingService: ModelDecoderBindingService) {

  }
  /*
    * @method getPropertyCustomerCategoryDetails()
    * @desc used to get Property Customer Category list .
    * @return json array data.
  */
  getPropertyCustomerCategoryDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.PROPERTY_CUSTOMER_CATEGORY_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('propertyCustomerCategoryDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
    * @method addPropertyCustomerCategory()
    * @desc used to add Property Customer Category.
    * @param permData :array - Property Customer Category data.
    * @return json array data.
  */
  addPropertyCustomerCategory(permData): any {
    const requestData = this.modelBindingService.propertyCustomerCategoryEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.PROPERTY_CUSTOMER_CATEGORY_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
    * @method getPropertyCustomerCategoryDetailsById()
    * @desc used to get Property Customer Category details by id.
    * @param id :string - Property Customer Categoryid.
    * @return json array data.
  */
  getPropertyCustomerCategoryDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.PROPERTY_CUSTOMER_CATEGORY_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService.propertyCustomerCategoryDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }
  /*
   * @method delete()
   * @desc used to Property Customer Categoryrow data.
   * @param id :array - Property Customer Category id .
   * @param id :dialogType - dialogType .
   * @return string.
  */
  delete(id): string {
    const url = this.modelBindingService.replaceUrlForDelete(
      API_ENDPOINTS.PROPERTY_CUSTOMER_CATEGORY_DELETE, id);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }
}

