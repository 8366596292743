import { TotalReferredComponent } from './../total-referred/total-referred.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-total-visited-detail',
  templateUrl: './total-visited-detail.component.html',
  styleUrls: ['./total-visited-detail.component.scss']
})
export class TotalVisitedDetailComponent implements OnInit {
  rowId;
  selectObj: any = [{ value: '', displayName: 'All' }];
  tableData = {};
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data.type === 'Visited') {
      this.tableData = {
        tableHeader: [
          'Sr. No.',
          'Customer Name',
          'Contact No',
          'Email'
        ],
        serviceDetail: {
          service: 'PromotionsService',
          method: 'getVisitedCustomerDetail',
          isParameter: true,
          parameterList: { page: '', size: '', id: this.data.id, ScheduleId: this.data.ScheduleId }
        },
        mapping: [
          { type: 'index', dataValue: '' },
          { type: 'default', dataValue: 'customerName' },
          { type: 'default', dataValue: 'contactNo' },
          { type: 'default', dataValue: 'email' },
        ],
        options: {
          pageSizeArray: [10, 20, 30, 50, 100],
        },
        searchData: {},
      };
    } else if (this.data.type === 'Delivered') {
      this.tableData = {
        tableHeader: [
          'Sr. No.',
          'Customer Name',
          'Contact No',
          'Email',
          'Refer Count'
          // 'Referance Name',
          // 'Referance Mobile',
          // 'Referance Date'
        ],
        serviceDetail: {
          service: 'PromotionsService',
          method: 'getDeleiveredCustomerDetail',
          isParameter: true,
          parameterList: { page: '', size: '', id: this.data.id, ScheduleId: this.data.ScheduleId }
        },
        mapping: [
          { type: 'index', dataValue: '' },
          { type: 'default', dataValue: 'customerName' },
          { type: 'default', dataValue: 'contactNo' },
          { type: 'default', dataValue: 'email' },
          {
            type: 'dialogWithPerm', dataValue: 'referanceCount',
            subType: 'anchorAction', dataValue1: 'customerId',
            loadComponent: TotalReferredComponent, width: '800px', panelClass: 'mat-dialog-box'
          },
          // { type: 'default', dataValue: 'referanceByName' },
          // { type: 'default', dataValue: 'referanceByMobileNo' },
          // { type: 'date', dataValue: 'referanceDate' },
        ],
        options: {
          pageSizeArray: [10, 20, 30, 50, 100],
        },
        searchData: {},
      };
    } else if (this.data.type === 'promotionDelivered') {
      this.tableData = {
        tableHeader: [
          'Sr. No.',
          'Customer Name',
          'Contact No',
          'Email'
        ],
        serviceDetail: {
          service: 'PromotionsService',
          method: 'getDeleiveredCustomerDetail',
          isParameter: true,
          parameterList: { page: '', size: '', id: this.data.id, ScheduleId: this.data.ScheduleId }
        },
        mapping: [
          { type: 'index', dataValue: '' },
          { type: 'default', dataValue: 'customerName' },
          { type: 'default', dataValue: 'contactNo' },
          { type: 'default', dataValue: 'email' }
        ],
        options: {
          pageSizeArray: [10, 20, 30, 50, 100],
        },
        searchData: {},
      };
    } else if (this.data.type === 'Revenue') {
      this.tableData = {
        tableHeader: [
          'Sr. No.',
          'Customer Name',
          'Contact No',
          'Email',
          'Amount'
        ],
        serviceDetail: {
          service: 'PromotionsService',
          method: 'getTotalRevenueCustomerDetail',
          isParameter: true,
          parameterList: { page: '', size: '', id: this.data.id, ScheduleId: this.data.ScheduleId }
        },
        mapping: [
          { type: 'index', dataValue: '' },
          { type: 'default', dataValue: 'customerName' },
          { type: 'default', dataValue: 'contactNo' },
          { type: 'default', dataValue: 'email' },
          { type: 'default', dataValue: 'billAmount' }
        ],
        options: {
          pageSizeArray: [10, 20, 30, 50, 100],
        },
        searchData: {},
      };
    } else if (this.data.type === 'AutoPilot') {
      this.tableData = {
        tableHeader: [
          'Sr. No.',
          'Customer Name',
          'Contact No',
          'Email'
        ],
        serviceDetail: {
          service: 'PromotionsService',
          method: 'getAutoPilotTotalDelieverd',
          isParameter: true,
          parameterList: { page: '', size: '', id: this.data.id }
        },
        mapping: [
          { type: 'index', dataValue: '' },
          { type: 'default', dataValue: 'customerName' },
          { type: 'default', dataValue: 'contactNo' },
          { type: 'default', dataValue: 'email' },
        ],
        options: {
          pageSizeArray: [10, 20, 30, 50, 100],
        },
        searchData: {},
      };
    }
  }

  ngOnInit() {
  }

}

