import { Component, OnInit, Inject } from '@angular/core';
import { IUserService } from '../../../iservices/iUser';
@Component({
  selector: 'app-right-side-bar',
  templateUrl: './right-side-bar.component.html',
  styleUrls: ['./right-side-bar.component.scss']
})
export class RightSideBarComponent implements OnInit {
  value: Boolean;
  constructor(@Inject('IUserService') private userService: IUserService) { }

  ngOnInit() {
    this.userService.hideShowRightBar.subscribe((show: Boolean) => {
      const showHide = show;
      if (showHide) {
        this.value = showHide;
      } else {
        this.value = showHide;
      }
    });
  }

}
