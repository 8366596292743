
import { Component, Inject } from '@angular/core';
import { Alert, AlertType } from './alert';
import { IAlertService } from '../../../iservices/iAlert';

@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['./alert.component.scss']
})

export class AlertComponent {
  alerts: Array<Alert> = [];

  constructor(
    @Inject('IAlertService') private alertService: IAlertService,
  ) {
    this.alertService.getAlert().subscribe((alert: Alert) => {
      if (!alert) {
        // clear alerts when an empty alert is received
        this.alerts = [];
        return;
      }
      // add alert to array
      this.alerts.push(alert);
    });
  }
  /**
   * @method removeAlert(param)
   * @desc to remove alert box based on alert type
   * @param alert :Alert - alert data
   */
  removeAlert(alert: Alert): void {
    this.alerts = this.alerts.filter(x => x !== alert);
  }
  /**
   * @method cssClass(param)
   * @desc to add css class based on alert type in alert box
   * @param alert :Alert - alert data
   * @return css class
   */
  cssClass(alert: Alert): any {
    if (!alert) {
      return;
    }
    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return 'alert alert-success';
      case AlertType.Error:
        return 'alert alert-danger';
      case AlertType.Info:
        return 'alert alert-info';
      case AlertType.Warning:
        return 'alert alert-warning';
      default:
        return '';
    }
  }

  /**
   * @method trackByFn()
   * @desc to track the loop for current state.
   * @return null
   */

  trackByFn(): any {
    return null;
  }
}
