import { ConfirmDialogeComponent } from './component/cashier-report/add-customer-report/confirm-dialoge/confirm-dialoge.component';
import { PaginationModule } from './component/common/table/pagination/pagination.module';
import { TotalVisitedDetailComponent } from './component/promotion/promotionComponents/total-visited-detail/total-visited-detail.component';
import { CashierReportComponent } from './component/cashier-report/cashier-report/cashier-report.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ImageEditorModule } from './component/social-media/image-editor/image-editor.module';
import { ImageEditorUIModule } from './component/social-media/image-editor-ui/image-editor-ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelectModule } from '@angular/material/select';
import { NgxPrintModule } from 'ngx-print';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TableComponent } from './component/common/table/table.component';
import {
  MatSelectSearchModule
} from './component/common/mat-select-search/mat-select-search.module';
import { HeaderComponent } from './component/common/header/header.component';
import { CashierHeaderComponent } from './component/cashier-report/cashier-header/cashier-header.component';
// import { CashierHeaderComponent } from './component/qsr-review/qsr-review/qsr-review.component';

import { CommonService } from './services/common.service';
import {NumberPickerModule} from 'ng-number-picker';

import { CustomerTypeService } from '../app/services/masterServices/customer-type.service';
import { DepartmentService } from '../app/services/masterServices/department.service';
import { PropertyMasterService } from '../app/services/masterServices/property-master.service';
import { ElementMasterService } from '../app/services/masterServices/element-master.service';
import { PropertyRangeService } from '../app/services/masterServices/property-range.service';
import { PropertyTypeService } from '../app/services/masterServices/property-type.service';
import { RangeTypeService } from '../app/services/masterServices/range-type.service';
import { RangeService } from '../app/services/masterServices/range.service';
import { ReviewForService } from '../app/services/masterServices/review-for.service';
import { DepartmentQueMasterService } from '../app/services/masterServices/department-que-master.service';
import { SocialMediaMasterService } from '../app/services/masterServices/social-media-master.service';

import { CustomersService } from '../app/services/crmServices/customers.service';

import { CashierReportService } from '../app/services/cashierReportServices/cashier-report.service';

import { QsrReviewService } from '../app/services/qsrServices/qsr-review.service';

import { UnsubsciptionService } from '../app/services/unsubsciptionServices/unsubsciption.service';

import { ReviewsService } from '../app/services/reviewsServices/reviews.service';

import { AutoPilotService } from '../app/services/promotionServices/auto-pilot.service';
import { PromotionsService } from '../app/services/promotionServices/promotions.service';
import { CurrentOfferService } from '../app/services/promotionServices/current-offer.service';


import { SettingService } from '../app/services/settingServices/setting.service';

import { PropertyService } from '../app/services/propertyMngServices/property.service';
import { PropertyDepartmentService } from '../app/services/propertyMngServices/property-department.service';
import { PropertyDepartmentQueService } from '../app/services/propertyMngServices/property-department-que.service';
import { PropertyCustomerTypeService } from '../app/services/propertyMngServices/property-customer-type.service';
import { SmsEmailApiService } from '../app/services/propertyMngServices/sms-email-api.service';
import { PropertyCustomerCategoryService } from '../app/services/propertyMngServices/property-customer-category.service';

import { DialogPopupComponent } from './component/common/dialog-popup/dialog-popup.component';
import {
  UploadProfilePictureComponent
} from './component/common/upload-profile-picture/upload-profile-picture.component';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { SearchComponent } from './component/common/search/search.component';
import { FooterComponent } from './component/common/footer/footer.component';
import { RightSideBarComponent } from './component/common/right-side-bar/right-side-bar.component';
import { SidemenuComponent } from './component/common/sidemenu/sidemenu.component';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ToolbarNotificationComponent } from './component/common/toolbar-notification/toolbar-notification.component';
import { SearchBarComponent } from './component/common/search-bar/search-bar.component';
import { FullscreenComponent } from './component/common/fullscreen/fullscreen.component';
import { UserMenuComponent } from './component/common/user-menu/user-menu.component';
import { OpenCloseMenuDirective } from './directives/open-close-menu.directive';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { ExportFilesComponent } from './component/common/export-files/export-files.component';
import { LightboxModule } from 'ngx-lightbox';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ReportsService } from './services/reportsServices/reports.service';
import { DetailDialogComponent } from './component/promotion/promotionComponents/promotions/detail-dialog/detail-dialog.component';
import { MatNativeDateModule } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSliderModule} from '@angular/material/slider';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatChipsModule} from '@angular/material/chips';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import { ChartsModule } from 'ng2-charts';
import { EmailEditorModule } from 'angular-email-editor';
import { NgSelectModule } from '@ng-select/ng-select';
import {StarRatingModule} from 'angular-star-rating';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { EmailBodyComponent } from './component/email-body/email-body.component';
import { AddEmailTemplateComponent } from './component/add-email-template/add-email-template.component';
import { AddEmailCategoryComponent } from './component/add-email-category/add-email-category.component';
FlexLayoutModule.withConfig({
  useColumnBasisZero: false,
  printWithBreakpoints: ['md', 'lt-lg', 'lt-xl', 'gt-sm', 'gt-xs']
});

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    ImageEditorUIModule,
    ImageEditorModule,
    LightboxModule,
    MatListModule,
    NgxPrintModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatTabsModule,
    MatSliderModule,
    MatTableModule,
    MatTooltipModule,
    // previous
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    Ng2ImgMaxModule,
    MatChipsModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    NumberPickerModule,
    MatExpansionModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatGridListModule,
    AngularEditorModule,
    DragDropModule,
    MatCardModule,
    MatSelectSearchModule,
    NgxMaterialTimepickerModule,
    ChartsModule,
    PaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    EmailEditorModule,
    NgSelectModule,
    StarRatingModule.forRoot(),
    RichTextEditorAllModule,
    NgSelectModule
  ],
  entryComponents: [DetailDialogComponent, EmailBodyComponent, AddEmailTemplateComponent,
    AddEmailCategoryComponent],
  providers: [
    DecimalPipe,
    ElementMasterService,
    { provide: 'IELementMasterService', useClass: ElementMasterService },
    CommonService,
    { provide: 'ICommonService', useClass: CommonService },
    CustomerTypeService,
    { provide: 'ICustomerTypeService', useClass: CustomerTypeService },
    DepartmentService,
    { provide: 'IDepartmentService', useClass: DepartmentService },
    DepartmentQueMasterService,
    { provide: 'IDepartmentQueService', useClass: DepartmentQueMasterService },
    PropertyMasterService,
    { provide: 'IPropertyMasterService', useClass: PropertyMasterService },
    PropertyRangeService,
    { provide: 'IPropertyRangeService', useClass: PropertyRangeService },
    PropertyTypeService,
    { provide: 'IPropertyTypeService', useClass: PropertyTypeService },
    RangeTypeService,
    { provide: 'IRangeTypeService', useClass: RangeTypeService },
    RangeService,
    { provide: 'IRangeService', useClass: RangeService },
    ReviewForService,
    { provide: 'IReviewForService', useClass: ReviewForService },
    SocialMediaMasterService,
    { provide: 'ISocialMediaMasterService', useClass: SocialMediaMasterService },
    CustomersService,
    { provide: 'ICustomersService', useClass: CustomersService },
    PropertyService,
    { provide: 'IPropertyService', useClass: PropertyService },
    PropertyDepartmentService,
    { provide: 'IPropertyDepartmentService', useClass: PropertyDepartmentService },
    PropertyDepartmentQueService,
    { provide: 'IPropertyDepartmentQueService', useClass: PropertyDepartmentQueService },
    PropertyCustomerTypeService,
    { provide: 'IPropertyCustomerTypeService', useClass: PropertyCustomerTypeService },
    SmsEmailApiService,
    { provide: 'ISmsEmailApiService', useClass: SmsEmailApiService },
    PropertyCustomerCategoryService,
    { provide: 'IPropertyCustomerCategoryService', useClass: PropertyCustomerCategoryService },
    AutoPilotService,
    { provide: 'IAutoPilotService', useClass: AutoPilotService },
    PromotionsService,
    { provide: 'IPromotionsService', useClass: PromotionsService },
    CurrentOfferService,
    { provide: 'ICurrentOfferService', useClass: CurrentOfferService },
    CashierReportService,
    { provide: 'ICashierReportService', useClass: CashierReportService },
    ReportsService,
    { provide: 'IReportsService', useClass: ReportsService },
    ReviewsService,
    { provide: 'IReviewsService', useClass: ReviewsService },
    QsrReviewService,
    { provide: 'IQsrReviewsService', useClass: QsrReviewService },
    SettingService,
    { provide: 'ISettingService', useClass: SettingService },
    UnsubsciptionService,
    { provide: 'IUnsubscriptionService', useClass: UnsubsciptionService },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  declarations: [
    TableComponent,
    HeaderComponent,
    CashierHeaderComponent,
    DialogPopupComponent,
    UploadProfilePictureComponent,
    SearchComponent,
    FooterComponent,
    RightSideBarComponent,
    SidemenuComponent,
    ToolbarNotificationComponent,
    SearchBarComponent,
    FullscreenComponent,
    UserMenuComponent,
    OpenCloseMenuDirective,
    ExportFilesComponent,
    CashierReportComponent,
    DetailDialogComponent,
    TotalVisitedDetailComponent,
    ConfirmDialogeComponent,
    EmailBodyComponent,
     AddEmailTemplateComponent,
     AddEmailCategoryComponent
  ],
  exports: [
    ImageEditorUIModule,
    ImageEditorModule,
    NumberPickerModule,
    CommonModule,
    MatListModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatTabsModule,
    MatTableModule,
    MatSliderModule,
    DragDropModule,
    MatCardModule,
    MatTooltipModule,
    ChartsModule,
    // real
    RightSideBarComponent,
    FooterComponent,
    TableComponent,
    ExportFilesComponent,
    SearchComponent,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatChipsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatRadioModule,
    TranslateModule,
    PaginationModule,
    MatSidenavModule,
    HeaderComponent,
    CashierHeaderComponent,
    MatInputModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatExpansionModule,
    LightboxModule,
    DialogPopupComponent,
    AngularEditorModule,
    UploadProfilePictureComponent,
    Ng2ImgMaxModule,
    MatButtonModule,
    MatGridListModule,
    MatSelectSearchModule,
    NgxMaterialTimepickerModule,
    SidemenuComponent,
    ToolbarNotificationComponent,
    SearchBarComponent,
    FullscreenComponent,
    UserMenuComponent,
    OpenCloseMenuDirective,
    CashierReportComponent,
    DetailDialogComponent,
    TotalVisitedDetailComponent,
    EmailEditorModule,
    ConfirmDialogeComponent,
    NgSelectModule,
    StarRatingModule,
    RichTextEditorAllModule,
    AddEmailTemplateComponent,
     AddEmailCategoryComponent,
     NgSelectModule
  ]
})
export class SharedModule {
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('en');
  }
}
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
