<div class="brand-sidebar">
    <h1 class="logo-wrapper">
        <a routerLink="./dashboard" class="brand-logo darken-1" *ngIf="commonPermissionsGuard?.viewPerms('Dashboard')">
            <img src="./assets/images/logo/time-logo.png" alt="" class="img_logo" style="width: 170px; height: 30px;">

        </a>
        <a (click)="onMenuChange()" class="navbar-toggler">
        <span>
            <mat-icon *ngIf="!isCollapsed">radio_button_checked</mat-icon>
            <mat-icon *ngIf="isCollapsed">radio_button_unchecked</mat-icon>
        </span>
    </a>
    </h1>
</div>
<perfect-scrollbar style="height: calc(100% - 10px);">
    <!-- <div fxLayout="column" >
        <app-sidemenu-item *ngFor="let menu of menus" [menu]="menu" [iconOnly]="iconOnly"> </app-sidemenu-item>
    </div> -->
    <mat-accordion>
        <mat-expansion-panel *ngFor="let item of menuItems; trackBy:trackByFn;" [disabled]="!item?.sub">
            <mat-expansion-panel-header [class.active]="item?.name==activeMenuItem" *ngIf="commonPermissionsGuard?.viewPerms(item?.permName)">
                <mat-panel-title class="nav-item {{ item?.class }}" (click)="getItem(item?.name)">
                        <!-- <mat-list-item > -->
                            <div *ngIf="!isIcon" class="menu-text-icon">
                                <mat-icon class="material-icons">{{ item?.icon }}</mat-icon>
                                <h3 *ngIf="item.link !== false" matLine [routerLink]="[item.link]">{{ item?.name }}</h3>
                                <h3 *ngIf="item.link === false" matLine>{{ item?.name }}</h3>
                                <mat-chip-list *ngIf="item?.chip">
                                    <mat-chip >{{item?.chip?.value}}  </mat-chip>
                                </mat-chip-list>
                            </div>
                            <!-- <div *ngIf="isIcon" [class.active]="item?.name==activeMenuItem">
                                <mat-icon class="material-icons">{{ item?.icon }}</mat-icon>
                            </div> -->
                        <!-- </mat-list-item> -->
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="item?.sub && commonPermissionsGuard?.viewPerms(item?.permName)">
                <mat-nav-list class="">
                    <ng-container *ngFor="let sub of item?.sub; trackBy:trackByFn;">
                        <mat-list-item class="nav-item {{ sub?.class }}" [class.active]="sub?.name==activeMenuItem"
                         *ngIf="commonPermissionsGuard?.viewPerms(sub?.permName, item?.permName)"
                        (click)="getItem(sub?.name)">
                            <div *ngIf="!isIcon" class="menu-text-icon" (click)="getItem(sub?.itemName)"
                             [routerLink]="[sub.link]" [class.active]="sub?.name==activeMenuItem">
                                <mat-icon class="material-icons" >{{ sub?.icon }}</mat-icon>
                                <h3 *ngIf="sub.link !== false" matLine [routerLink]="[sub.link]">{{ sub?.name }} </h3>
                                <h3 *ngIf="sub.link == false" matLine>{{ sub?.name }} {{sub.link}}</h3>
                            </div>
                            <div *ngIf="isIcon" >
                                <mat-icon class="material-icons">{{ sub?.icon }}</mat-icon>
                            </div>
                        </mat-list-item>
                    </ng-container>
                </mat-nav-list>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</perfect-scrollbar>
