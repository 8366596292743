<mat-toolbar   class="mat-elevation-z4">
    <div class="mat-lft-top-menu">
            <span class="spacer"></span>
           
            <span>{{this.property?.name}} -</span>
            <span>{{this.property?.code}} </span>
    </div>  
      <app-fullscreen></app-fullscreen>
  
      <app-toolbar-notification></app-toolbar-notification>
  
      <app-user-menu [currentUser]="toolbarHelpers?.currentUser"></app-user-menu>
      <!-- <mat-sidenav #sidenav2 position="end" mode="over">
            <app-right-side-bar></app-right-side-bar>
        </mat-sidenav> -->
       <!-- <button mat-icon-button (click)="sidebar?.toggle();">
          <i class="material-icons app-toolbar-menu">menu </i>
      </button> -->
  </mat-toolbar>