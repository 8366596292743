<div class="content draw-content">
    <ng-container >
        <color-widget [(ngModel)]="drawTool.currentBrush.color" (ngModelChange)="drawTool.setBrushColor($event)" trans>Brush Color</color-widget>
        <div class="brush-type-button-wrapper">
            <div class="bottom-label" trans>Brush Type</div>
            <button mat-icon-button class="round-action-button" [style.color]="drawTool.getBrushColor()" (click)="controls.openPanel('draw.brushTypes')">
                <svg-icon name="pencil-custom"></svg-icon>
            </button>
        </div>
        <div class="brush-sizes brush-controls">
            <div class="bottom-label" trans>Brush Size</div>
            <div class="sizes controls">
                <button
                        class="no-style size control"
                        (click)="drawTool.setBrushSize(size)"
                        *ngFor="let size of brushSizes"
                        [ngStyle]="{width: size+'px', height: size+'px'}"
                        [class.selected]="drawTool.getBrushSize() === size"
                ></button>
            </div>
        </div>
    </ng-container>
</div>

<div class="content brush-types-content">
    <button class="no-style button-with-image" *ngFor="let type of brushTypes" (click)="setBrushType(type)" [class.selected]="drawTool.getBrushType() == type">
        <img [src]="getBrushPreviewUrl(type)" alt="{{type}} brush preview">
    </button>
</div>