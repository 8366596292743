<div id="footer">
    <footer class="page-footer gradient-45deg-light-blue-cyan">
        <div class="footer-copyright" style="text-align:center">
            <div class="container">
                <span>
                    Copyright ©
                    <a class="grey-text text-lighten-2" href="" target="_blank">Revugain Brand Management Pvt. Ltd</a> All rights reserved.
                </span>
            </div>
        </div>
    </footer>
</div>
