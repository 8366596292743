
export const menus = [
  {
    'name': 'Dashboard',
    'open': false,
    'link': './dashboard',
    'icon': 'dashboard',
    'permName': 'Dashboard',
  },
  {
    'name': 'Master',
    'icon': 'important_devices',
    'link': false,
    'open': false,
    'permName': 'Master',
    'sub': [
      {
        'name': 'Social Media Category',
        'link': './master/category',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Social Media Category',
      },
      {
        'name': 'Department',
        'link': './master/department',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Department',
      },
      {
        'name': 'Elements',
        'link': './master/elements',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Elements',
      },
      {
        'name': 'Department Question',
        'link': './master/departmentquemaster',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Department Question',
      },
      {
        'name': 'Customer Type',
        'link': './master/customertype',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Customer Type',
      },
      {
        'name': 'Property Type',
        'link': './master/propertytypecomponent',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Property Type',
      },
      {

        'name': 'Review For',
        'link': './master/reviewfor',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Review For',
      },
      {

        'name': 'Property Master',
        'link': './master/propertymaster',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Property Master',
      },
      {
        'name': 'Range Type',
        'link': './master/rangetype',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Range Type',
      },
      {
        'name': 'Range',
        'link': './master/range',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Range',
      },
      {
        'name': 'Property Range',
        'link': './master/propertyrange',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Property Range',
      },
      {
        'name': 'Notification',
        'link': './master/notification',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Notification',
      },
      {
        'name': 'Content Category',
        'link': './master/contentCategory',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Content Category',
      },
      {
        'name': 'Emailer',
        'link': './master/email-campaign',
        'icon': 'chevron_right',
        'chip': false,
        'open': false,
        'permName': 'Emailer',
      }
    ]
  },
  {
    'name': 'Reviews',
    'icon': 'chat',
    'open': false,
    'link': false,
    'permName': 'Reviews',
    'sub': [
      {
        'name': 'All Reviews',
        'icon': 'chevron_right',
        'open': false,
        'link': './reviews/allreviews',
        'permName': 'All Reviews',
      },
      {
        'name': 'Summary',
        'icon': 'chevron_right',
        'open': false,
        'link': './reviews/review-summary',
        'permName': 'Summary',
      },
      {
        'name': 'Feedback',
        'icon': 'chevron_right',
        'open': false,
        'link': './reviews/feedback',
        'permName': 'Feedback',
      }
    ]
  },
  {
    'name': 'CRM',
    'icon': 'security',
    'open': false,
    'link': false,
    'permName': 'CRM',
    'sub': [
      {
        'name': 'Customers',
        'icon': 'chevron_right',
        'open': false,
        'link': './crm/customers',
        'permName': 'Customers',
      },
    ]
  },
  // {
  //     'name': 'Survey',
  //     'icon': 'report',
  //     'link': false,
  //     'open': false,
  //     'permName': 'Dashboard',
  // },
  {
    'name': 'Social Media',
    'icon': 'group',
    'open': false,
    'link': false,
    'permName': 'Social Media',
    'sub': [
      {
        'name': 'Social Media Post',
        'icon': 'chevron_right',
        'link': './socialmedia/socialmediapost',
        'chip': false,
        'open': false,
        'permName': 'Social Media Post',
      },
      {
        'name': 'Social Media Connection',
        'icon': 'chevron_right',
        'link': './socialmedia/socialmediaconnection',
        'chip': false,
        'open': false,
        'permName': 'Social Media Connection',
      },
    ]
  }
  , {
    'name': 'Promotion',
    'icon': 'event_note',
    'open': false,
    'link': false,
    'permName': 'Promotion',
    'sub': [
      {
        'name': 'Promotion',
        'icon': 'chevron_right',
        'open': false,
        'link': './promotion/promotions',
        'permName': 'Promotion',
      },
      {
        'name': 'Referral',
        'icon': 'chevron_right',
        'open': false,
        'link': './promotion/referral',
        'permName': 'Referral',
      },
      {
        'name': 'Auto Pilot',
        'icon': 'chevron_right',
        'open': false,
        'link': './promotion/autopilot',
        'permName': 'Auto Pilot',
      },
      {
        'name': 'Current Offer',
        'icon': 'chevron_right',
        'open': false,
        'link': './promotion/currentoffer',
        'permName': 'Current Offer',
      },
      {
        'name': 'Announcement',
        'icon': 'chevron_right',
        'open': false,
        'link': './promotion/announcementReport',
        'permName': 'Announcement',
      },
      {
        'name': 'Missed Call Offer',
        'icon': 'chevron_right',
        'open': false,
        'link': './promotion/misscall-offer',
        'permName': 'Missed Call Offer',
      }
    ]
  }
  , {

    'name': 'Property Management',
    'icon': 'business',
    'open': false,
    'link': false,
    'permName': 'Property Management',
    'sub': [
      {
        'name': 'Property',
        'icon': 'chevron_right',
        'link': './propertymanagement/property',
        'open': false,
        'permName': 'Property',

      },
      {
        'name': 'Property Department',
        'icon': 'chevron_right',
        'open': false,
        'link': './propertymanagement/propertydepartment',
        'permName': 'Property Department',
      },
      {
        'name': 'Department Question',
        'icon': 'chevron_right',
        'open': false,
        'link': './propertymanagement/propertydepartmentque',
        'permName': 'Department Question',
      },
      {
        'name': 'Property Customer Type',
        'icon': 'chevron_right',
        'open': false,
        'link': './propertymanagement/propertycustomertype',
        'permName': 'Property Customer Type',
      },
      {
        'name': 'SMS/E-Mail API',
        'icon': 'chevron_right',
        'open': false,
        'link': './propertymanagement/smsemailapi',
        'permName': 'SMS/E-Mail API',
      },
      {
        'name': 'Customer Category',
        'icon': 'chevron_right',
        'open': false,
        'link': './propertymanagement/propertycustomercategory',
        'permName': 'Customer Category',
      }
    ]
  }, {
    'name': 'Reports',
    'icon': 'assignment',
    'open': false,
    'link': false,
    'permName': 'Reports',
    'sub': [
      {
        'name': 'Customer History',
        'icon': 'chevron_right',
        'link': './cashierreport/cashierreport',
        'open': false,
        'permName': 'Customer History',
      },
      {
        'name': 'Customer Detail With Review',
        'icon': 'chevron_right',
        'link': './reports/customerdetailwith',
        'open': false,
        'permName': 'Customer Detail With Review',
      },
      {
        'name': 'Repeat Guest',
        'icon': 'chevron_right',
        'link': './reports/repeatguest',
        'open': false,
        'permName': 'Repeat Guest',
      },
      {
        'name': 'User Wise',
        'icon': 'chevron_right',
        'link': './reports/userwise',
        'open': false,
        'permName': 'User Wise',
      },
      {
        'name': 'Missed Call',
        'icon': 'chevron_right',
        'link': './reports/misscall',
        'open': false,
        'permName': 'Missed Call',
      },
      {
        'name': 'Autopilot Result',
        'icon': 'chevron_right',
        'link': './reports/autoPilotResult',
        'open': false,
        'permName': 'Autopilot Result',
      },
      {
        'name': 'SMS Balance',
        'icon': 'chevron_right',
        'link': './reports/sms-balance',
        'open': false,
        'permName': 'SMS Balance',
      },
      {
        'name': 'Property wise Summary',
        'icon': 'chevron_right',
        'link': './reports/property-wise-summary',
        'open': false,
        'permName': 'Property wise Summary',
      }
    ]
  },
  {
    'name': 'Setting',
    'icon': 'build',
    'open': false,
    'link': false,
    'permName': 'Setting',
    'sub': [
      {
        'name': 'Loyalty Program',
        'icon': 'chevron_right',
        'link': './setting/loyaltyprogram',
        'open': false,
        'permName': 'Loyalty Program',
      },
      {
        'name': 'Loyalty Offer',
        'icon': 'chevron_right',
        'link': './setting/loyaltyoffer',
        'open': false,
        'permName': 'Loyalty Offer',
      }
    ]
  }
];
