import { STORAGE_KEYS } from './../../../services/storage-keys-constants';
import { SharedService } from './../../../services/shared.service';
import { ILoaderService } from './../../../iservices/iLoader';
import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ICustomersDashboard } from '../../../iModels/iCRMModels/customers.model';
import { ICustomersService } from '../../../iservices/iCrmServices/iCustomers';
import { IAlertService } from '../../../iservices/iAlert';
import { CommonPermissionsGuard } from '../../../gaurds/flow.guard';
import { ICommonService } from '../../../iservices/iCommon';
import * as _ from 'lodash';

@Component({
  selector: 'app-customers-detail',
  templateUrl: './dashboard-customer-details.component.html',
  styleUrls: ['./dashboard-customer-details.component.scss']
})
export class DashboardCusttomerDetailsComponent {
  @ViewChild('fileDownload') fileDownload: ElementRef;
  idArray = [];
  customerExportData: Array<any> = [];
  customerDashboard: ICustomersDashboard;
  selectObj: any = [{ value: '', displayName: 'All' }];
  propertyId: any;
  isMainProperty: any;
  userType: any;
  selectedProperties: any;
  dayCheck: any = [];
  dayFlag: boolean;
  day = [{ value: '0', name: 'N/A' }, { value: '1', name: 'DOB' }, { value: '2', name: 'DOA' }];
  tableData = {
    tableHeader: [
      'Customer Name',
      'Contact No',
      'DOA',
      'DOB'
    ],
    serviceDetail: {
      service: 'CustomersService',
      method: 'getCustomerDobdoa',
      isParameter: true,
      parameterList: { propertyId: 0, page: '', size: '', FilterPropertyId: '' }
    },
    mapping: [
      {
        type: 'anchor', dataValue: 'customerName', urlValue: 'customersdetail/', urlArgument: 'id',
        key: 'customerName', allowOrderByFilter: true
      },
      { type: 'default', dataValue: 'contactNo', key: 'contactNo', allowOrderByFilter: true },
      { type: 'date', dataValue: 'doa', key: 'doa', allowOrderByFilter: true },
      { type: 'date', dataValue: 'dob', key: 'dob', allowOrderByFilter: true },
    ],
    options: {
      pageSizeArray: [10, 20, 30, 50, 100, 'Select All'],
    },
    searchData: {},
  };

  searchOptions = [
    {
      'key': 'NameMobileNo',
      'details': {
        'type': 'text',
        generalDetails: { placeholder: 'Search Customer Name' },
      }
    },
    {
      'key': 'Status',
      'details': {
        'type': 'radio-custom',
        generalDetails: { multipleradio :[{ value: '4', name: 'N/A' }, { value: '1', name: 'DOB' }, { value: '2', name: 'DOA' }]},
      }
    },
    {
      'key': 'Days',
      'details': {
        'type': 'number',
        generalDetails: { lable: 'Days' ,placeholder: 'Days' },
        DropdownDetails: []
      }
    }
  ];
  isMultipleProperty = false;


  

  /**
   * @method searchDetails()
   * @desc Used to search details.
   * @param inputVal :  string - key on which data is searched.
   */
  searchDetails(inputVal): void {
  
      const finalSearch = inputVal;
      this.tableData.searchData = finalSearch;
  
    }
    /**this.tableData.searchData = inputVal;
    *console.log('this.tableData.searchData',this.tableData.searchData)
   
  } */

  /**
   * @method getCustomerIds()
   * @desc Used to get checks details.
   * @param ids :  ids - key on id.
   */
  getCustomerIds(ids): void {
    this.idArray = ids;
    console.log('this.tableData.this.idArray',this.idArray)
    // this.tableData.searchData = ids;
  }
  /**
   * @method formatDate()
   * @desc used to get formatted date.
   */
  formatDate(date): string {
    return this.commonService.formatDate(date);
  }
  constructor(private router: Router,
    public commonPermissionsGuard: CommonPermissionsGuard,
    @Inject('IAlertService') private alertService: IAlertService,
    @Inject('ICustomersService') private customersService: ICustomersService,
    @Inject('ILoaderService') private loaderService: ILoaderService,
    @Inject('ICommonService') private commonService: ICommonService,
    public dialog: MatDialog,
    private sharedService: SharedService
  ) {
    this.tableData.serviceDetail.parameterList.FilterPropertyId = this.sharedService.getSelectedProperties(true);
    if (!_.isEmpty(this.tableData.serviceDetail.parameterList.FilterPropertyId)
      && this.tableData.serviceDetail.parameterList.FilterPropertyId.length > 1) {
      this.isMultipleProperty = true;
    } else {
      this.isMultipleProperty = false;
    }
    this.getCustomerDobdoa();
  
  }

  getCustomerDobdoa(): void {
    const data = this.sharedService.getSelectedProperties();
    this.customersService.getCustomerDobdoa({}).subscribe(
      resp => {
        if (resp) {
          this.customerDashboard = resp;
        }
      });        
  }
  navigateTo(path) {
    this.router.navigate([path]);
  }

}
