import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from './api-endpoints-constants';
import { ICommonService } from './../iservices/iCommon';
import { IApiBaseObject } from './../iModels/api-base-object.model';
import { IApiService } from './../iservices/iApi';
import { ModelBindingService } from './binding.model.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogPopupComponent } from '../component/common/dialog-popup/dialog-popup.component';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
@Injectable()
export class CommonService implements ICommonService {
  printDoc = new Subject<boolean>();
  today;
  id;
  dialogType;
  dataService;
  private apiObject: IApiBaseObject;
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    public dialog: MatDialog) { }

  /**
   * @method formatDate()
   * @desc to format date in yyyy-mm-dd format.
   */

  formatDate(date): string {
    let newDate;
    const myDate = new Date(date);
    newDate = (('0' + (myDate.getMonth() + 1)).slice(-2) +
    '/' + ('0' + (myDate.getDate())).slice(-2) + '/' +  myDate.getFullYear());
    return newDate;
  }


  /*
   * @method timestampintoDate()
   * @desc used to convert timestamp into Date.
   */

  timestampintoDate(timestamp): number {
    return (new Date(timestamp)).getTime();
  }

  /*
  * @method openDialog()
  * @desc used to open a dialog box.
  */

  openDialog(id, dataService, dialogType): any {
    this.id = id;
    this.dialogType = dialogType;
    this.dataService = dataService;
    this.dialog.open(DialogPopupComponent, {
      width: '450px',
      data: {}
    });
  }

  /*
   * @method getDialogData()
   * @desc used to get dialog data.
   */

  getDialogData(): any {
    return { 'id': this.id, 'dataService': this.dataService, 'dialogType': this.dialogType };
  }


  /**
   * @method compareDates()
   * @desc used to compare start and end dates.
   */

  compareDates(from: any, to: any): any {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (new Date(f.value) > new Date(t.value)) {
        return {
          dates: true
        };
      }
      return {};
    };
  }

  /**
   * @method dateFunc()
   * @desc used to format the date in 'dayname , dd-mm-yyyy'.
   * @return date
   */

  dateFunc(): any {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const d = new Date();
    const monthName = monthNames[d.getMonth()];
    const dayName = days[d.getDay()];
    const dd = d.getDate();
    const yyyy = d.getFullYear();
    this.today = dayName + ',' + dd + '-' + monthName + '-' + yyyy;
    return this.today;
  }

  /**
   * @method getStates()
   * @desc to get All states.
   */
  getStates(params): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.GET_STATES, params);
    this.apiObject = this.modelBindingService.setApiCallObject(url,
      {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      }));
  }

    /**
   * @method getCites()
   * @desc to get All cities.
   */
  getCites(params): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.GET_CITIES, params);
    this.apiObject = this.modelBindingService.setApiCallObject(url,
      {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      }));
  }

  /**
   * @method getCountries()
   * @desc to get countries.
   */
  getCountries(params): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.GET_COUNTRIES, params);
     this.apiObject = this.modelBindingService.setApiCallObject(url,
      {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      }));
  }
  /**
   * @method dateFun()
   * @desc to format date to MM/dd/yyyy.
   * @return date object
   */
  dateFun(date) {
    const d1 = date;
    const d2 = d1.replace(/^(\d{1,2}\/)(\d{1,2}\/)(\d{4})$/, '$2$1$3');
    return new Date(d2);
  }


  getShortUrlImage(shortPath): any {
    const url = `${API_ENDPOINTS.ActualImagePath}/${shortPath}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url,
      {}, false, false);
    return this.apiService.getApi(this.apiObject);
  }

  getQueryStringFromObject(obj) {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

}
