import { AbstractControl, ValidatorFn } from '@angular/forms';

export const validateEmail: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } => {
  const EMAIL_REGEXP =
    // tslint:disable-next-line:max-line-length
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/i;
  if (control.value) {
    return EMAIL_REGEXP.test(control.value) ? null : {
      validateEmail: true
    };
  } else {
    return null;
  }
};
