import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CONSTANT } from './../utils/constant';
import { STORAGE_KEYS } from '../services/storage-keys-constants';
@Injectable()
export class LocalStorageService {
  storageKeys = {
    SESSION: '_revugain_session',
    CHAIN_SESSION: '_session_chaindist',
    PERMISSION_SESSION: '_session_permissionsdist',
    DISTRIBUTOR_ID: 'distId',
    DISTCENTER_ID: 'distCenterId',
    CURRENT_USER_DATA: 'currentUserData',
    COLLAPSE_MENU: '_jellydist_collapsed_nav',
    TOTAL_COUNT: 'item-count',
    TWILIO_SESSION: '_chat_session'
  };

  constructor(private cookieService: CookieService) {
    // Constructor body
  }
  /**
   * @method setLocalStorageData
   * @description Method to set local storage data.
   * @param key: string; key for the local storage.
   * @param value: any; value for the local storage.
   */
  setLocalStorageData(key, value): void {
    const expired = new Date();
    expired.setTime(expired.getTime() + (CONSTANT.SESSION_TIME_OUT));
    this.cookieService.set(key, value, expired, '/');
    if (value === 'null') {
      this.cookieService.delete(key);
    }
    if (key === STORAGE_KEYS.SESSION && value === 'null') {
      for (const property in this.storageKeys) {
        if (this.cookieService.getAll() &&
          this.cookieService.getAll()[this.storageKeys[property]]) {
          this.cookieService.delete(this.storageKeys[property]);
        }
      }
      sessionStorage.removeItem(STORAGE_KEYS.PERMISSION_SESSION);
    }
  }
  /**
   * @method getLocalStorageData
   * @description Method to get local storage data.
   * @param key: string; key for the local storage.
   */
  getLocalStorageData(key): any {
    return this.cookieService.getAll() && this.cookieService.getAll()[key]
      ? this.cookieService.get(key) : 'null';
  }
  /**
   * @method getAllStorageData
   * @description Method to get All local storage data.
   * @param key: string; key for the local storage.
   */
  getAllStorageData(): any {
    return this.cookieService.getAll();
  }

  /*
    * @method removeLocalStorageData
    * @description Method to remove  local storage data.
    * @param key: string; key for the local storage.
  */

  removeLocalStorageData(key): any {

    for (const property in this.storageKeys) {
      if (this.cookieService.getAll() &&
        this.cookieService.getAll()[this.storageKeys[property]]) {
        this.cookieService.delete(this.storageKeys[property]);
      }
    }
  }
}
