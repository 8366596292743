import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Ng2ImgMaxService } from 'ng2-img-max';
@Component({
  selector: 'app-upload-profile-picture',
  templateUrl: './upload-profile-picture.component.html',
  styleUrls: ['./upload-profile-picture.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class UploadProfilePictureComponent implements OnInit {
  selectorFile: File = null;
  apiURL = environment.url;
  url;
  fileUpload = true;
  clientID;
  profilePic;
  isExceed = false;
  isUpload = false;
  exceedMsg = '';
  imageList: any = [];
  @Output() valueChange = new EventEmitter();
  @Input() ImageType: boolean;
  imageName;
  constructor(private ng2ImgMax: Ng2ImgMaxService, private activeroute: ActivatedRoute) { }

  ngOnInit(): void {

    // this.baseconverter();
    this.activeroute.queryParams.subscribe(params => {
      this.clientID = params['"clientId"'];
    });
  }

  /*
   * @method onFileSelected()
   * @desc used to select a file .
  */

  onFileSelected(event): any {
    const fakepath = event.target.value;
    const image = event.target.files;
    let json;
    if (image.length > 0 && this.imageList.length > 0) {
      this.imageList = [];
    }
    // Array.from(image).forEach(file => {
    for (const file of image) {
      // console.log(file);
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
        if (file.size > 30000000) {
          this.isExceed = true;
          this.exceedMsg = 'Image size should not exceed 30 MB';
        } else {
          this.isExceed = false;
          this.isUpload = true;
          this.ng2ImgMax.compressImage(file, 0.999,true).subscribe(
            result => {
              // console.log(result);
              try {
                this.selectorFile = new File([result], result.name);
                if (this.selectorFile) {
                  this.imageName = result.name;
                  this.isUpload = false;
                  json = {
                    title: '',
                    imageName: this.selectorFile.name,
                    image_Base64: this.selectorFile
                  };
                  this.imageList.push(json);
                  this.valueChange.emit(this.imageList);
                }
              } catch (err) { // Workaround for IE 11
                // this.documentService.blobToFile(blob, this.document.documentName);
                this.selectorFile = result;
                if (this.selectorFile) {
                  this.isUpload = false;
                  json = {
                    title: '',
                    imageName: this.selectorFile.name,
                    image_Base64: this.selectorFile
                  };
                  this.imageList.push(json);
                  this.valueChange.emit(this.imageList);
                }
              }
            });
        }
      } else {
        const jsonError = {
          'Error': ' The selected file ' + '"' + fakepath + '"' + ' cannot be uploaded .' +
            ' Only files with the following extensions are allowed: jpeg, jpg, png . '
        };
        this.valueChange.emit(jsonError);
      }
    }
    // });
  }
}
