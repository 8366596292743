import { Component, ElementRef, Inject, HostBinding, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { ValidationMessage } from '../../../utils/locale';
import { IAlertService } from '../../../iservices/iAlert';
import { IUserService } from '../../../iservices/iUser';
import { ILoaderService } from '../../../iservices/iLoader';
import { COMMONFORMVALIDATIONRULE } from '../../../utils/validations/commonFormRules';
import { SharedService } from '../../../services/shared.service';
import { STORAGE_KEYS } from '../../../services/storage-keys-constants';
import { fadeInAnimation } from '../../../animations/fade-in.animation';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInAnimation]
})
export class LoginComponent implements OnInit {
  @HostBinding('@fadeInAnimation') fadeInAnimation = true;
  isSubmitted = false;
  pstatus = false;
  userName: string;
  serverError = false;
  serverErrorMsg;
  loginForm: FormGroup;
  user = {
    username: '',
    password: '',
    propertyCode: ''
  };
  obj = Object.getOwnPropertyNames;
  @ViewChild('password') password: ElementRef;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private validationMessage: ValidationMessage,
    @Inject('IUserService') private userService: IUserService,
    @Inject('IAlertService') private alertService: IAlertService,
    @Inject('ILoaderService') private loaderService: ILoaderService,
    private sharedService: SharedService,
  ) {
  }

  /**
   * @method ngOnInit()
   * @desc used to Initialize Page.
   */

  ngOnInit(): void {
    this.buildForm();
    const remember = this.sharedService.getDataFromStorage(STORAGE_KEYS.REMEMBER_ME);
    if (remember) {
      if (remember !== 'null') {
        const username = this.sharedService.getDataFromStorage(STORAGE_KEYS.USER_NAME);
        const password = this.sharedService.getDataFromStorage(STORAGE_KEYS.PASSWORD);
        const code = this.sharedService.getDataFromStorage(STORAGE_KEYS.CODE);
        this.loginForm.patchValue({ username: username, password: password, propertyCode: code, rememberMe: remember });
      }
    }
    if (remember === 'false') {
      const username = this.sharedService.setDataInStorage(STORAGE_KEYS.USER_NAME, null);
      const password = this.sharedService.setDataInStorage(STORAGE_KEYS.PASSWORD, null);
      const code = this.sharedService.setDataInStorage(STORAGE_KEYS.CODE, null);
      this.loginForm.patchValue({
        username: this.user.username, password: this.user.password, propertyCode: this.user.propertyCode,
        rememberMe: false
      });
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
  }

  /**
   * @method buildForm()
   * @desc used to build form.
   */

  buildForm(): void {
    this.loginForm = this.formBuilder.group({
      'username': [this.user.username, Validators.required],
      'password': [this.user.password, Validators.required],
      'propertyCode': [this.user.propertyCode, Validators.required],
      'rememberMe': [],
    });
  }

  /**
   * @method submitLogin()
   * @desc used to submit login details.
   */

  submitLogin(): void {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
    this.loaderService.display(true);
    const postLoginData = {
      password: this.loginForm.value.password,
      username: this.loginForm.value.username,
      propertyCode: this.loginForm.value.propertyCode,
    };
    this.userService.loginUser(postLoginData).subscribe(
      res => {
        if (res.code === 1000) {
          this.isSubmitted = false;
          if (this.loginForm.value.rememberMe) {
            this.sharedService.setDataInStorage(STORAGE_KEYS.REMEMBER_ME, this.loginForm.value.rememberMe);
            this.sharedService.setDataInStorage(STORAGE_KEYS.USER_NAME, postLoginData.username);
            this.sharedService.setDataInStorage(STORAGE_KEYS.PASSWORD, postLoginData.password);
            this.sharedService.setDataInStorage(STORAGE_KEYS.CODE, postLoginData.propertyCode);
          } else {
            this.sharedService.setDataInStorage(STORAGE_KEYS.REMEMBER_ME, this.loginForm.value.rememberMe);
          }
          this.loaderService.display(false);
          const role = this.sharedService.getDataFromStorage(STORAGE_KEYS.ROLE);
          const isMainProperty = this.sharedService.getDataFromStorage('isMainProperty');
          const noOfProperty = this.sharedService.getDataFromStorage('noOfProperty');
          if ((role === 'sa' || role === 'admin') && (noOfProperty > 1) && (isMainProperty === 'true')) {
            this.router.navigate(['/propertySelect']);
          } else {
            if (role !== 'cashier') {
              this.router.navigate(['dashboard']);
            } else {
              const isCompleteForm = this.sharedService.getDataFromStorage('allowCompleteFormView') === 'true' ? true : false ;
                if (isCompleteForm) {
                  this.router.navigate(['/cashierreports']);
                } else {
                  this.router.navigate(['/cashierreport']);
                }
            }
          }
          this.alertService.success('1000', false);
          // this.router.navigate(['dashboard']);
        } else if (res.code === 5003) {
          this.alertService.error('INVALIDUSERLOGIN', false);
          this.loaderService.display(false);
        } else {
          this.alertService.error('INVALIDUSERLOGIN', false);
          this.loaderService.display(false);
        }
      },
      error => {
        if (error.status === 403) {
          this.alertService.error('USERDIFFERENTPORTAL', false);
        } else if (error.status === 422) {
          error.error.fieldErrors.forEach(element => {
            this.serverError = true;
            this.serverErrorMsg = element.message;
          });
        } else if (error.status === 401) {
          // this.alertService.error('INVALIDUSERLOGIN', false);
        } else {
          this.alertService.error('INVALIDUSERLOGIN', false);
        }
        this.loaderService.display(false);
      }
    );
  }
}

}
