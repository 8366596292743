import {
    ActivatedRouteSnapshot, ActivatedRoute, CanActivate, Router, RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { STORAGE_KEYS } from '../services/storage-keys-constants';

@Injectable()
export class RegisterFlowGuard implements CanActivate {

    constructor(private sharedService: SharedService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.sharedService.isUserAuthenticated()) {
            return false;
        } else {
            return true;
        }
    }
}


@Injectable()
export class CommonFlowGuard implements CanActivate {

    constructor(private sharedService: SharedService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.sharedService.isUserAuthenticated()) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}



@Injectable()
export class ForgotFlowGuard implements CanActivate {

    constructor(private sharedService: SharedService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.sharedService.isUserAuthenticated()) {
            this.router.navigate(['/dashboard']);
            return false;
        } else {
            return true;
        }
    }
}

// Reset Password Flow Gaurd
@Injectable()
export class ResetPasswordFlowGuard implements CanActivate {

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return true;
        // if (this.sharedService.isUserAuthenticated()) {
        //     return true;
        // } else {
        //     this.router.navigate(['/login']);
        //     return false;
        // }
    }
}


@Injectable()
export class DashboardFlowGuard implements CanActivate {

    constructor(private sharedService: SharedService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.sharedService.isUserAuthenticated()) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}

@Injectable()
export class CashierGuard implements CanActivate {
    role;
    constructor(protected router: Router, private sharedService: SharedService
     ) {
        this.role = this.sharedService.getDataFromStorage(STORAGE_KEYS.ROLE);
    }
     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const path = state.url;
        if (this.sharedService.isUserAuthenticated()) {
            if (this.role === 'cashier' && path === '/dashboard') {
              const isCompleteForm = this.sharedService.getDataFromStorage('allowCompleteFormView') === 'true' ? true : false ;
              if (isCompleteForm) {
                this.router.navigate(['/cashierreports']);
              } else {
                this.router.navigate(['/cashierreport']);
              }
            }
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigate(['/login']);
        return false;
    }
}

@Injectable()
export class CommonPermissionsGuard implements CanActivate {
  permission: any;
  constructor(private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  viewPerms(component, parentComponent?): any {
    const perms = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.PERMISSION_SESSION));
    if (perms) {
      if (this.permission = perms.find(x => x.menuName === component &&
        (!parentComponent || (parentComponent && x.parentMenuName === parentComponent)))) {
        if (this.permission.menuPermissionIdList && this.permission.menuPermissionIdList.includes('1')) {
          return true;
        }
      }
    }
    return false;
  }

  deletePerms(component, parentComponent?): any {
    const perms = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.PERMISSION_SESSION));
    if (perms) {
      if (this.permission = perms.find(x => x.menuName === component &&
        (!parentComponent || (parentComponent && x.parentMenuName === parentComponent)))) {
        if (this.permission.menuPermissionIdList && this.permission.menuPermissionIdList.includes('3')) {
          return true;
        }
      }
    }
    return false;
  }


  addPerms(component, parentComponent?): any {
    const perms = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.PERMISSION_SESSION));
    if (perms) {
      if (this.permission = perms.find(x => x.menuName === component &&
        (!parentComponent || (parentComponent && x.parentMenuName === parentComponent)))) {
        if (this.permission.menuPermissionIdList && this.permission.menuPermissionIdList.includes('2')) {
          return true;
        }
      }
    }
    return false;
  }

  editPerms(component, parentComponent?): any {
    const perms = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.PERMISSION_SESSION));
    if (perms) {
      if (this.permission = perms.find(x => x.menuName === component &&
        (!parentComponent || (parentComponent && x.parentMenuName === parentComponent)))) {
        if (this.permission.menuPermissionIdList && this.permission.menuPermissionIdList.includes('2')) {
          return true;
        }
      }
    }
    return false;
  }
}
