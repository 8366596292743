<div class="">
  <!--header-->
  <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" >
    <div fxFlex="90">
      <h3>{{ "EmailCampaignMaster.addEmailer" | translate }}</h3></div>
    <div fxFlex="10">
        <button mat-flat-button color="primary" class="bg-button" (click)="postDesign()">Save</button>
    </div>
  </div>

  <!-- <button mat-raised-button color="primary" (click)="submit()">Save</button> -->
  <button mat-dialog-close mat-icon-button class="close-btn">
    <i class="material-icons">cancel</i>
  </button>
  <!--header-->
  <div mat-dialog-content>
    <div class="container" style="padding: 5%;">
      <!-- <angular-editor name="editorElementName" [config]="editorConfig" [(ngModel)]="htmlContent"></angular-editor> -->
      <!-- <ejs-richtexteditor (created)="rteCreated()"
      width="605px" height="500px"
      [toolbarSettings]='tools' id='defaultRTE' [(ngModel)]="htmlContent" [(value)]='htmlContent'
      [insertImageSettings]='insertImageSettings'> </ejs-richtexteditor> -->
      <email-editor (loaded)="editorLoaded($event)"></email-editor>
      
      <!-- <div fxLayout="row">
        <div>
          <a (click)="fileTypeInput.click()"
            >Insert Image
          </a>
        </div>
        <input
        type="file"
        #fileTypeInput
        id="FileUpload1"
        (change)="onFileChanges($event)"
        style="display: none"
        multiple
      />
      </div> -->
    </div>
  </div>
</div>