export const CONSTANT = {
  status: [
    { value: 1, displayName: 'Active' },
    { value: 'inActive', displayName: 'Inactive' }
  ],
  royaltyType: [
    { value: 'Bill', displayName: 'Bill' },
    { value: 'Review', displayName: 'Review' }
  ],
  deptFields: [
    { value: 'departmentName', displayName: 'Department Name' },
  ],
  apiFor: [
    'SMS', 'Email'
  ],
  SESSION_TIME_OUT: 14400000,
  CommonCodes: { commonCode: 101, commonCode2: 102, commonCode3: 103 },
  COLLAPSE_MENU: '_revugain_collapsed_nav',
  CUSTOMER_HISTORY_CATEGORY : [
    {
      value: -1,
      displayName: 'All',
    },
    {
      value: 1,
      displayName: 'Buyer',
    },
    {
      value: 0,
      displayName: 'NonBuyer',
    }
  ],
};
export const ORDERSTATUS = {
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  APPROVED: 'APPROVED',
  SUBMITTED: 'SUBMITTED',
  SHIPPED: 'SHIPPED',
  COMPLETED: 'COMPLETED'
};

