import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ILoaderService } from './../iservices/iLoader';

@Injectable()
export class LoaderService implements ILoaderService {
  status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * @method display()
   * @desc Just pass ‘true’ in order to display the loader and ‘false’ in order to hide the loader
   * @param value :boolean - true/false
   */

  display(value: boolean): void {
    if (value) {
      this.status.next(true);
    } else {
      this.status.next(false);
    }
  }
}
