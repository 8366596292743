import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialoge',
  templateUrl: './confirm-dialoge.component.html',
  styleUrls: ['./confirm-dialoge.component.scss']
})
export class ConfirmDialogeComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmDialogeComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  closePop(data, param?): void {
    let json = {};
    if (param) {
      json = {
        result: data,
        param: param
      };
    } else {
      json = {
        result: data,
      };
    }
    this.dialogRef.close(json);
  }
}
