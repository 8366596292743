import { Component, EventEmitter, Output, ViewEncapsulation, Inject, AfterViewInit} from '@angular/core';
import {EditorControlsService} from '../../editor-controls.service';
import { Settings } from '../../../../editor-tools/core/config/settings.service';
import {BaseDrawer} from '../base-drawer';
import { fabric } from 'fabric';
import { ImportToolService } from '../../../../image-editor/tools/import-tool.service';
import { CanvasService } from '../../../../image-editor/canvas/canvas.service';
import {HistoryToolService} from '../../../../image-editor/history/history-tool.service';
import { Object as FObject } from 'fabric';
import { IELementMasterService } from '../../../../../../iservices/iMasterServices/iElementMaster';
import { ISocialMediaService } from '../../../../../../iservices/ISocialMediaServices/iSocialMedia';
import * as _ from 'lodash';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-elements-drawer',
    templateUrl: './elements-drawer.component.html',
    styleUrls: ['./elements-drawer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ElementsDrawerComponent extends BaseDrawer {
    elementImageList: Array<any> = [];
    @Output() isDirty = new EventEmitter();

    /**
     * Name of this drawer.
     */
    public name = 'Elements';

    public elementsList: any;

    title: string;

    /**
     * ShapesDrawerComponent Constructor.
     */
    constructor(
        protected history: HistoryToolService,
        public editorControls: EditorControlsService,
        private config: Settings,
        private importTool: ImportToolService,
        @Inject('ISocialMediaService') private editorService: ISocialMediaService,
        @Inject('IELementMasterService') private elementMasterService: IELementMasterService,
        private canvas: CanvasService
    ) {
        super(history, editorControls);
    }

    public openOverlayImage() {
        this.importTool.openUploadDialog().then(obj => {
            if (!obj) { return; }
            this.canvas.fabric().setActiveObject(obj);
            this.history.add('added: overlay Image', 'photo-library');
        });
    }

    getElements(): void {
        this.elementMasterService.getElements({Title: this.title}).subscribe(res => {
            this.elementImageList = res.data;
            this.elementsList = _.take(res.data, 10);
        }, error => {
            console.log(error);
        });
    }

    public addElement(ele) {
        this.dirty = true;
        this.isDirty.emit(true);
        fabric.Image.fromURL(ele.imagePath, img => {
/*             const sticker = new fabric.Image(img); */
            this.addAndPositionSticker(img, 'sticker');
        }, { crossOrigin: 'Annoymous'});
    }

    private addAndPositionSticker(sticker: FObject, name: string = 'shape') {
        sticker.name = name;
        this.canvas.fabric().add(sticker);
        this.canvas.fabric().setActiveObject(sticker);
        sticker.scaleToWidth(this.canvas.fabric().getWidth() / 2);
        sticker.viewportCenter();
        sticker.setCoords();
        this.canvas.render();
    }

    /**
     * Add specified shape to canvas.
     */
/*     public addShape(shape: BasicShape) {
        this.shapesTool.addBasicShape(shape);
        this.dirty = true;
        this.isDirty.emit(true);
    } */

    public close() {
        this.editorControls.closeAllPanels();
        super.close();
    }

    onScrollDown = () => {
        const data = this.elementImageList.slice(this.elementsList.length, this.elementsList.length + 10);
        this.elementsList = [...this.elementsList, ...data];
    }

    searchDetail() {

    }
}
