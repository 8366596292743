import { API_ENDPOINTS } from './../../services/api-endpoints-constants';
import { IApiBaseObject } from './../../iModels/api-base-object.model';
import { ModelBindingService } from './../../services/binding.model.service';
import { IApiService } from './../../iservices/iApi';
import { ModelDecoderBindingService } from './../../services/bindingDecoder.model.service';
import { Injectable, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContentCategoryService {
  private apiObject: IApiBaseObject;
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);

  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService,
    public dialog: MatDialog) {
  }

  postData(data) {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.COMMON_MASTER,
      data, true, false);
    return this.apiService.postApi(this.apiObject);
  }

  getContentCategoryData(apiGetParameters) {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.COMMON_MASTER, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url,
      {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp: any) => {
        if (resp) {
          if (resp.data) {
            json = {
              data: resp.data,
              'itemCount': resp.data[0].totalRows ? resp.data[0].totalRows : resp.data.length
            };
          } else {
            json = { data: resp.data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }

  delete(data, type): string {
    const url = `${API_ENDPOINTS.COMMON_MASTER}?CommonCode=153&Id=${data.id}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }
}
