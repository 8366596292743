<mat-toolbar   class="cashier-header" >
    <div class="brand-sidebar">
        <div class="logo-wrapper">
            <a  class="brand-logo darken-1">
                <img src="./assets/images/logo/revugain_new_logo.png">
                <!-- <span class="logo-text hide-on-med-and-down">Revugain</span> -->
            </a>
        </div>
    </div>
    <div class="cashier-top-links">
        <a (click)="goTo('customer')">Customer</a>
        <a *ngIf="isCashierReportEnable" (click)="goTo('cashierReport')" class="mr-10">Cashier Report</a>
    </div>
    <app-fullscreen></app-fullscreen>
    <app-toolbar-notification></app-toolbar-notification>
    <app-user-menu [currentUser]="toolbarHelpers?.currentUser"></app-user-menu>
</mat-toolbar>
