import { ICustomersService } from './../../../../../iservices/iCrmServices/iCustomers';
import { IAlertService } from './../../../../../iservices/iAlert';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.scss']
})
export class OtpVerifyComponent implements OnInit {
  otp: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<OtpVerifyComponent>,
    @Inject('ICustomersService') private customersService: ICustomersService,
    @Inject('IAlertService') private alertService: IAlertService,
  ) {
  }

  ngOnInit() {
  }

  verifyOtp = () => {
    this.customersService.verifyOtpForExport(this.otp).subscribe((res) => {
      if (res.code === 1000) {
        this.alertService.successMsg('Otp Verified Successfully', false);
        this.dialogRef.close(res.code);
      } else {
        this.alertService.errorMsg(res.description, false);
      }
    }, (error) => {
      console.log(error);
    });
  }

}
