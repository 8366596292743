import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {CanvasService} from '../image-editor/canvas/canvas.service';
import {HistoryToolService} from '../image-editor/history/history-tool.service';
import {fromEvent} from 'rxjs';
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {EditorControlsService} from './toolbar-controls/editor-controls.service';
import { BreakpointsService } from '../editor-tools/core/ui/breakpoints.service';
import {FloatingPanelsService} from './toolbar-controls/floating-panels.service';
import {CanvasKeybindsService} from '../image-editor/canvas/canvas-keybinds.service';
import {ActiveObjectService} from '../image-editor/canvas/active-object.service';
import {ActiveDrawerService} from './toolbar-controls/drawers/active-drawer.service';
import {EditorUiService} from './editor-ui.service';
import {CanvasStateService} from '../image-editor/canvas/canvas-state.service';
import { Settings } from '../editor-tools/core/config/settings.service';
import { MergeToolService } from '../image-editor/tools/merge/merge-tool.service';

@Component({
    selector: 'image-editor',
    templateUrl: './image-editor.component.html',
    styleUrls: ['./image-editor.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImageEditorComponent implements OnInit {
    @ViewChild('canvasWrapper') canvasWrapper: ElementRef;
    @ViewChild('canvasMaskWrapper') canvasMaskWrapper: ElementRef;
    @ViewChild('editorControls', {read: ElementRef}) editorControls: ElementRef;
    openPanel: boolean;
    openDrawer = 'templates';
    openSettings: boolean;
    dirty: boolean;
    openedDrawer: any = {};

    items = [
        { name: 'Templates', icon: 'photo-library', action: 'templates' },
        { name: 'Elements', icon: 'image', action: 'elements' },
        { name: 'filter', icon: 'filter', action: 'filter' },
        { name: 'text', icon: 'text-box-custom', action: 'text' },
        { name: 'shapes', icon: 'polygon-custom', action: 'shapes' },
        { name: 'resize', icon: 'resize-custom', action: 'resize' },
        { name: 'crop', icon: 'crop-custom', action: 'crop' },
        { name: 'draw', icon: 'pencil-custom', action: 'draw' },
        { type: 'separator' },
        { name: 'merge', icon: 'merge-custom', action: 'merge' },
    ];

    /**
     * ImageEditorComponent Constructor.
     */
    constructor(
        public canvas: CanvasService,
        private history: HistoryToolService,
        public controls: EditorControlsService,
        public breakpoints: BreakpointsService,
        private floatingPanels: FloatingPanelsService,
        private canvasKeybinds: CanvasKeybindsService,
        private el: ElementRef,
        private activeObject: ActiveObjectService,
        private activeDrawer: ActiveDrawerService,
        public editorUi: EditorUiService,
        private state: CanvasStateService,
        public config: Settings,
        public mergeTool: MergeToolService
    ) {
        this.dirty = false;
        this.openSettings = false;
        this.openPanel = false;
    }

    ngOnInit() {
        this.state.wrapperEl = this.canvasWrapper.nativeElement;
        this.state.maskWrapperEl = this.canvasMaskWrapper.nativeElement;
        this.canvas.init().subscribe(() => {
            this.canvasKeybinds.init();
            this.fitCanvasToScreenOnResize();
            this.openObjectSettingsOnDoubleClick();
            this.closePanelsOnObjectDelete();
            this.handleObjectSelection();
            this.updateHistoryOnObjectModification();
            this.canvasMaskWrapper.nativeElement.classList.remove('not-loaded');
        });
        this.executeNavItemAction({ index: 0 });
    }

    apply(): void {
        if (!this.dirty) {return; }
        this.addChangesToHistory();
        this.close();
    }

    protected addChangesToHistory() {
        const formattedName = this.openedDrawer.name.replace(/([A-Z])/g, ' $1');
        this.history.add('applied: ' + formattedName, this.getIconName(this.openedDrawer.name));
    }

    getIconName(toolName: string): string | null {
        const itemName = this.items.find(item => item.name === toolName);

        const formattedName = toolName.replace(/([A-Z])/g, '-$1').toLowerCase();

        return itemName ? itemName.icon : formattedName + '-custom';
    }

    executeNavItemAction(e): void {
        if (this.items[e.index].action === 'merge') {
            return this.merge();
        }
        this.openPanel = true;
        this.openDrawer = this.items[e.index].action;
        this.openedDrawer = this.items[e.index];
    }

    private merge() {
        this.mergeTool.apply().then(() => {
            this.history.add('objects: merged', this.controls.getIconName('merge'));
        });
    }


    close(): void {
        this.dirty = false;
        this.activeObject.deselect();
        this.controls.closeAllPanels();
        /* if (this.openDrawer) {
            this.openDrawer = null;
        }
         if (!this.openSettings) {
            this.openSettings = true;
        } else {
            this.openPanel = false;
        } */

    }

    setDirty(e): void {
        this.dirty = e;
    }

    private closePanelsOnObjectDelete() {
        this.canvas.fabric().on('object:delete', () => this.controls.closeAllPanels());
    }

    /**
     *  Open object settings panel on object double click.
     */
    private openObjectSettingsOnDoubleClick() {
        this.canvas.fabric().on('mouse:dblclick', () => {
            if (!this.activeObject.getId() || this.activeDrawer.dirty()) {
                return;
            }
            this.controls.openPanel('objectSettings');
        });
    }

    /**
     * Replace current history item, so object position is
     * updated after object is scaled, moved or rotated.
     */
    private updateHistoryOnObjectModification() {
        this.canvas.fabric().on('object:modified', event => {
            // this.dirty = true;
            if (!event.e || this.dirty) { return; }
            this.history.replaceCurrent();
        });
    }

    private handleObjectSelection() {
        this.canvas.fabric().on('selection:created', e => {this.dirty = true; this.onObjectSelection(e); });
        this.canvas.fabric().on('selection:updated', e => { console.log('updated'); this.onObjectSelection(e); });

        this.canvas.fabric().on('selection:cleared', event => {
            if (!event.e || this.dirty) { return; }
            /* this.controls.closeCurrentPanel(); */
            this.openPanel = false;
            this.openSettings = false;
        });
    }

    public onObjectSelection(event) {
        // only open drawer panel if selection event originated from
        // user clicking or tapping object on the canvas and not from
        // selecting object programmatically in the app
        if (!event.e || this.dirty) { return; }
        /* this.controls.openObjectDrawer(event.target.name); */
       // console.log("open");
        this.openPanel = true;
        this.openSettings = true;
    }

    private fitCanvasToScreenOnResize() {
        fromEvent(window, 'resize')
            .pipe(debounceTime(200), distinctUntilChanged())
            .subscribe((e) => {
                this.canvas.zoom.fitToScreen();
            });
    }
}
