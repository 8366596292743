import { STORAGE_KEYS } from './../../../services/storage-keys-constants';
import { SharedService } from './../../../services/shared.service';
import { IAlertService } from './../../../iservices/iAlert';
import { Component, OnInit, Input, HostListener, ElementRef, Inject } from '@angular/core';
import { NotificationService } from '../../master/masterComponents/notification/notification.service';

@Component({
  selector: 'app-toolbar-notification',
  templateUrl: './toolbar-notification.component.html',
  styleUrls: ['./toolbar-notification.component.scss']
})
export class ToolbarNotificationComponent implements OnInit {
  cssPrefix = 'toolbar-notification';
  isOpen = false;
  notifications: Array<any> = [];

  constructor(private elementRef: ElementRef,
    private notificationService: NotificationService,
    @Inject('IAlertService') private alertService: IAlertService,
    private localStorageService: SharedService,
    ) { }

  ngOnInit() {
    const token = this.localStorageService.getDataFromStorage(STORAGE_KEYS.SESSION);
    if (token && token !== 'null') {
      this.getNotificationList();
    }
  }

  getNotificationList() {
    this.notificationService.getNotificationPopList().subscribe((response) => {
      if (response.code === 1000) {
        this.notifications = response.data;
      } else {
        this.notifications = [];
      }
    });
  }

}
