import { Component, ElementRef, EventEmitter, Output, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { EditorControlsService } from '../../editor-controls.service';
import { ActiveObjectService } from '../../../../image-editor/canvas/active-object.service';
import { ISocialMediaService } from '../../../../../../iservices/ISocialMediaServices/iSocialMedia';
import { CanvasService } from '../../../../image-editor/canvas/canvas.service';
import { ImportToolService } from '../../../../image-editor/tools/import-tool.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { HistoryToolService } from '../../../../image-editor/history/history-tool.service';
import * as _ from 'lodash';
import { ImageCategoryComponent } from '../image-category/image-category.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'app-templates-drawer',
    templateUrl: './templates-drawer.component.html',
    styleUrls: ['./templates-drawer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TemplatesComponent {
    templateImageList: Array<any> = [];
    templatesList = [];
    title: string;
    @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

    @Output() isDirty = new EventEmitter();

    /**
     * Name of this drawer.
     */
    public name = 'templates';

    /**
     * TextDrawerComponent Constructor.
     */
    constructor(
        @Inject('ISocialMediaService') private editorService: ISocialMediaService,
        public controls: EditorControlsService,
        public activeObject: ActiveObjectService,
        private canvas: CanvasService,
        private importTool: ImportToolService,
        public history: HistoryToolService,
        public dialog: MatDialog,
    ) {
    }

    /**
    * Ask user to upload a new background image and override current one.
    */
    public openBackgroundImage() {
        this.importTool.openUploadDialog({ type: 'image', backgroundImage: true }).then(() => {
            this.history.add('changed: background Image', 'photo-library');
        });
    }

    getTemplates(): void {
        this.editorService.getTemplates(this.title).subscribe(res => {
            this.templateImageList = res.data;
            this.templatesList = _.take(res.data, 10);
        }, error => {
            console.log(error);
        });
    }

    getImage(imgUrl): void {
        this.canvas.loadMainImage(imgUrl).then(obj => {
            this.importTool.addPropertyData();
        });
    }

    public executeOpenButtonAction() {
        this.matMenuTrigger.toggleMenu();
    }

    onScrollDown = () => {
        const data = this.templateImageList.slice(this.templatesList.length, this.templatesList.length + 10);
        this.templatesList = [...this.templatesList, ...data];
    }

    searchDetail() { }

    openCategoryDialog() {
        let dialogConfig = new MatDialogConfig();
        dialogConfig = {
            disableClose: true,
            autoFocus: true,
            width: '800px',
            height: '600px',
            panelClass: 'mat-dialog-box',
        };
        const dialogRef = this.dialog.open(ImageCategoryComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.title = res;
                this.getTemplates();
            }
        });
    }
}
