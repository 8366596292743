import { EmailerService } from './../email-campaigning/emailer.service';
import { ICommonService } from './../../iservices/iCommon';
import { ValidationMessage } from './../../utils/locale';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
@Component({
  selector: 'app-add-email-category',
  templateUrl: './add-email-category.component.html',
  styleUrls: ['./add-email-category.component.scss']
})
export class AddEmailCategoryComponent implements OnInit {
fileName: any;
templateType: any;
templateCategory: any;
jsonData: any;
templateTypeData: Array<any> = [];
categoryData: Array<any> = [];
  editId: any;
  constructor(
    public emailService: EmailerService,
    private dialogRef: MatDialogRef<AddEmailCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.getTemplateType();
    this.getTemplateCategory();
    if (!_.isEmpty(this.data)) {
      this.editId = this.data.id;
      this.fileName = this.data.name;
      this.templateType = this.data.type;
      this.templateCategory = this.data.contentCategoryId;
      this.jsonData = this.data.rawData;
    }
  }

  getTemplateType() {
    this.emailService.getTemplateType().subscribe((res) => {
      if (res.code === 1000 && !_.isEmpty(res) && !_.isEmpty(res.data)) {
        this.templateTypeData = [...res.data];
      } else {
        this.templateTypeData = [];
      }
    });
  }

  getTemplateCategory() {
    this.emailService.getTemplateCategory().subscribe((res) => {
      if (res.code === 1000 && !_.isEmpty(res) && !_.isEmpty(res.data)) {
        this.categoryData = [...res.data];
      } else {
        this.categoryData = [];
      }
    });
  }

  submit() {
    this.dialogRef.close(this);
  }

}
