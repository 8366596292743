import { Component, OnInit, DoCheck, Input } from '@angular/core';


@Component({
  selector: 'app-dashboard-review-card',
  templateUrl: './dashboard-review-card.component.html',
  styleUrls: ['./dashboard-review-card.component.scss']
})
export class DashboardReviewCardComponent implements OnInit, DoCheck {
  @Input() dashboardDetails: any;
  dashboardData: any;
  constructor() { }

  ngDoCheck() {
    if (this.dashboardDetails !== this.dashboardData) {
      this.dashboardData = this.dashboardDetails;
    }
  }

  ngOnInit() {
  }

}
