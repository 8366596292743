import { SharedService } from './../../services/shared.service';
import { Component, OnInit, Inject } from '@angular/core';
import { IAlertService } from '../../iservices/iAlert';
import { IUserService } from '../../iservices/iUser';
import { ILoaderService } from '../../iservices/iLoader';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  propertyIds: string;
  dashboardDetails: any;
  constructor(
    @Inject('IUserService') private userService: IUserService,
    @Inject('IAlertService') private alertService: IAlertService,
    @Inject('ILoaderService') private loaderService: ILoaderService,
    private sharedService: SharedService
  ) {
    this.propertyIds = this.sharedService.getSelectedProperties(true);
  }

  /**
  * @method ngOnInit()
  * @desc used to Initialize Page.
  */

  ngOnInit() {
    this.getDashboardDetails();
  }

  /**
 * @method getDashboardDetails()
 * @desc used to get dashboard details using its id.
 */
  getDashboardDetails(): void {
    const query = {
      FilterPropertyId: this.propertyIds ? this.propertyIds : ''
    };
    this.userService.getDashboardDetails(query).
      subscribe(
      resp => {
        if (resp) {
          this.dashboardDetails = resp;
          resp = resp['data'];
        }
      });
  }

}
