import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';

import { ICurrentOfferService } from '../../iservices/iPromotionServices/iCurrentOffer';

@Injectable({
  providedIn: 'root'
})
export class CurrentOfferService implements ICurrentOfferService {
  private apiObject: IApiBaseObject;
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService) {

  }
  /*
    * @method getCurrentOfferDetails()
    * @desc used to get CurrentOffer list .
    * @return json array data.
  */
  getCurrentOfferDetails(): any {
    let json;
    const url =  API_ENDPOINTS.CURRENT_OFFER_GET;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          if (resp['data']) {
            json = { data: resp['data'], 'itemCount': resp['data'][0].totalRows };
          } else {
            json = { data: [], 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
    * @method addCurrentOffer()
    * @desc used to add CurrentOffer Type.
    * @param permData :array - CurrentOffer data.
    * @return json array data.
  */
  addCurrentOffer(permData): any {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.CURRENT_OFFER_POST, permData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
    * @method getCurrentOfferDetailsById()
    * @desc used to get promotions details by id.
    * @param id :string - promotions id.
    * @return json array data.
  */
  getCurrentOfferDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.CURRENT_OFFER_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          return resp;
        }
      }));
  }

}
