<h1 mat-dialog-title>Verify Otp Sent to Your Mobile No.</h1>
<form
  #verifyOtpFormModal="ngForm"
  (ngSubmit)="verifyOtpFormModal.form.valid && verifyOtp()"
  novalidate
>
  <div mat-dialog-content>
    <div class="mat-input-group">
      <mat-form-field>
        <input
          matInput
          #otpInputModal="ngModel"
          type="text"
          name="otpInput"
          [(ngModel)]="otp"
          placeholder="Enter Otp"
          required
        />
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button type="submit" mat-raised-button color="primary">
      <i class="far fa-trash-alt"></i>
      {{ "common.submit" | translate }}
    </button>
    <button mat-dialog-close mat-icon-button class="close-btn">
      <i class="material-icons">cancel</i>
    </button>
  </div>
</form>
