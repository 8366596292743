<div class="mat-page-container">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutGap="15PX"
    class="mat-page-top-header"
  >
    <div fxFlex="1 0 auto">
      <h3>Customer History</h3>
    </div>
    <div fxFlex="100">
      <div class="search-box-fix-width"></div>
    </div>
  </div>
  <app-search
    [searchOptions]="searchOptions"
    (searchEvent)="searchDetails($event)"
  ></app-search>
  <div class="cus-head-tab-between">
    <h4></h4>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="2">
      <button
        fxFlex="5"
        mat-stroked-button
        color="primary"
        (click)="openPasswordVerifyModal()"
      >
        Export

      </button>
      <button
        fxFlex="5"
        mat-stroked-button
        color="primary"
        (click)="printData()"
      >
        Print
      </button>
    </div>
  </div>
  <app-table [tableSettings]="tableData"></app-table>
</div>
