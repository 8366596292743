import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { ISmsEmailApiService } from '../../iservices/iPropertyMngServices/iSmsEmailApi';
import { MatDialog } from '@angular/material/dialog';
// tslint:disable-next-line:max-line-length
import {AddSmsEmailApiComponent} from '../../component/property-management/property-managementComponents/sms-email-api/add-sms-email-api/add-sms-email-api.component';
import {
  ApiParameterComponent
} from '../../component/property-management/property-managementComponents/sms-email-api/api-parameter/api-parameter.component';
// tslint:disable-next-line:max-line-length
import {AddApiParameterComponent} from '../../component/property-management/property-managementComponents/sms-email-api/api-parameter/add-api-parameter/add-api-parameter.component';
@Injectable({
  providedIn: 'root'
})
export class SmsEmailApiService implements ISmsEmailApiService {
  private apiObject: IApiBaseObject;
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService,
    public dialog: MatDialog) {

  }
  /*
    * @method getSmsEmailApiDetails()
    * @desc used to get social media SmsEmailApi list .
    * @return json array data.
  */
  getSmsEmailApiDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.PROPERTY_SMS_EMAIL_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('smsEmailApiDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
    * @method addSmsEmailApi()
    * @desc used to add SmsEmailApi Type.
    * @param permData :array - SmsEmailApi data.
    * @return json array data.
  */
  addSmsEmailApi(permData): any {
    const requestData = this.modelBindingService.smsEmailApiEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.PROPERTY_SMS_EMAIL_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }


  /*
 * @method openAddDialog()
 * @desc used to get add dialog data.
 */

  // openAddDialog(id, type: string): any {
  //   this.categoryId = id;
  //   if (type === 'popup') {
  //     this.dialog.open(AddQuotationComponent, {
  //       width: '450px',
  //       panelClass: 'mat-dialog-box',
  //       data: {}
  //     });
  //   }
  //   if (type === 'notpopup') {
  //     this.dialog.open(QuotationComponent, {
  //       width: '850px',
  //       panelClass: 'mat-dialog-box',
  //       data: {}
  //     });
  //   }
  // }
  /*
   * @method getQuoteId()
   * @desc used to get quote id.
   */

  // getQuoteId(): any {
  //   return this.categoryId;
  // }
  /*
    * @method getSmsEmailApiDetailsById()
    * @desc used to get social media SmsEmailApi details by id.
    * @param id :string - social media SmsEmailApi id.
    * @return json array data.
  */
  getSmsEmailApiDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.PROPERTY_SMS_EMAIL_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService.smsEmailApiDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }
  /*
   * @method delete()
   * @param id :dialogType - dialogType .
   * @return string.
  */
  delete(id, dialogType): any {
    let endPoint;
    if (dialogType === 'apiparam') {
      endPoint = API_ENDPOINTS.PROPERTY_APIParameter_DELETE;
    }
    if (dialogType === 'smsemailapi') {
      endPoint = API_ENDPOINTS.PROPERTY_SMS_EMAIL_DELETE;
    }
    const url = this.modelBindingService.replaceUrlForDelete(
      endPoint, id);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }

  /*
    * @method getApiParamDetails()
    * @desc used to get ApiParam list .
    * @return json array data.
  */
  getApiParamDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.PROPERTY_APIParameter_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('apiParamDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
   * @method addApiParam()
   * @desc used to add ApiParam Type.
   * @param permData :array - ApiParam data.
   * @return json array data.
 */
  addApiParam(permData): any {
    const requestData = this.modelBindingService.apiParamEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.PROPERTY_APIParameter_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
   * @method getApiParamDetailsById()
   * @desc used to get social media ApiParam details by id.
   * @param id :string - social media ApiParam id.
   * @return json array data.
   */
  getApiParamDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.PROPERTY_APIParameter_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService.apiParamDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }

}

