<h1 class="mat-dialog-title">Category</h1>
<div mat-dialog-content fxLayout="column">
  
  <div fxFlex>
    <mat-grid-list cols="6" rowHeight="80px">
      <a
        *ngFor="let item of categoryList"
        (click)="dialogRef.close(item.categoryName)"
      >
        <mat-grid-tile class="grid-tile-bg-color" colspan="1" rowspan="1">
          {{ item.categoryName }} - {{ item.noofImage }}
        </mat-grid-tile>
      </a>
    </mat-grid-list>
  </div>
  <div fxFlex>
      <app-pagination
        [totalItems]="totalItemCount"
        [pageSizeArrayFromComponent]="pageSizeArray"
        [searchData]="searchData"
        (goPrev)="goPrev($event)"
        (goNext)="goNext($event)"
        (goPage)="goToPage($event)"
        (setPageSize)="setPageSize($event)"
      >
      </app-pagination>
    </div>
</div>
<div mat-dialog-actions>
  <button
    mat-dialog-close
    class="close-btn mat-icon-button"
    (click)="dialogRef.close()"
  >
    <i class="material-icons">cancel</i>
  </button>
</div>
