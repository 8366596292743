import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import { STORAGE_KEYS } from './storage-keys-constants';
import { ISharedService } from './../iservices/iShared';
import { CONSTANT } from './../utils/constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable()
export class SharedService implements ISharedService {
  apiUrl=environment.url;
  constructor(private localStorageService: LocalStorageService,private http: HttpClient) {
  }

  /**
   * @method setDataInStorage()
   * @desc used to set data in local storage using localStorageService.
   * @param key :string .
   * @param value :string .
   */
  setDataInStorage(key, value): any {
    this.localStorageService.setLocalStorageData(key, value);
  }


  /**
   * @method getDataFromStorage()
   * @desc used to get data from local storage using localStorageService.
   * @param key :string .
   */
  getDataFromStorage(key): any {
    const data = this.localStorageService.getLocalStorageData(key);
    return data;

  }

  /**
   * @method isUserAuthenticated()
   * @desc used to check whether user has an authentication or not by using seesion(token).
   * @param key :string .
   */
  isUserAuthenticated(): any {
    const data = this.getDataFromStorage(STORAGE_KEYS.SESSION);
    if (data !== 'null') {
      return true;
    } else {
      return false;
    }
  }


  getSelectedProperties(type?) {
    const role = this.getDataFromStorage(STORAGE_KEYS.ROLE);
    const isMainProperty = this.getDataFromStorage('isMainProperty');
    const noOfProperty = this.getDataFromStorage('noOfProperty');
    const dataString = JSON.parse(localStorage.getItem('Selected_PROPERTIES'));
    if ((role === 'sa' || role === 'admin') && (noOfProperty > 1) && (isMainProperty === 'true') && dataString) {
      const dataArray = dataString.split(',');
      if (dataArray[0] === 0) {
        dataArray.splice(0, 1);
      }
      if (type) {
        return dataArray;
      } else {
        return dataArray.toString();
      }
    } else {
      return '';
    }
  }

  getImagePathFromFile(file){
    const fd: FormData = new FormData();
    fd.append('files ', file);
    return this.http.post(`${this.apiUrl}/SaveImage`,fd);
  }
  getImagePathFromURL(base64){
    //ImageDetail
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-San-Jwt': this.getDataFromStorage(STORAGE_KEYS.SESSION)
    });
    return this.http.post(`${this.apiUrl}/ImageDetail`,[{
      "Id":"0",
      "ParentTypeId":"0",
      "ParentId":"0",
      "Extension":".png",
      "FilePath":"",
      "IsBaseImage":1,
      "Name":"mypdf.png",
      ImageBase64:base64
    }],{headers});
  }
}
