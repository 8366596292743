import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationMessage } from '../../../utils/locale';
import { IAlertService } from '../../../iservices/iAlert';
import { IUserService } from '../../../iservices/iUser';
import { ILoaderService } from '../../../iservices/iLoader';
import { COMMONFORMVALIDATIONRULE } from '../../../utils/validations/commonFormRules';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  isSubmitted = false;
  serverError;
  errorMessage = '';
  messageClass = '';
  forgotForm: FormGroup;
  user = {
    email: ''
  };
  obj = Object.getOwnPropertyNames;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    @Inject('IUserService') private userService: IUserService,
    @Inject('IAlertService') private alertService: IAlertService,
    @Inject('ILoaderService') private loaderService: ILoaderService,
    private validationMessage: ValidationMessage
  ) { }

  /**
   * @method ngOnInit()
   * @desc used to Initialize Page.
   */
  ngOnInit(): void {
    this.buildForm();
  }

  /**
   * @method buildForm()
   * @desc used to build form.
   */

  buildForm(): void {
    this.forgotForm = this.formBuilder.group({
      email: ['', COMMONFORMVALIDATIONRULE.email]
    });
  }

  /**
   * @method submitForgot()
   * @desc used to submit forget password form details.
   */
  submitForgot(): void {
    // this.isSubmitted = true;
    // this.loaderService.display(true);
    // this.userService.forgotPassword(this.forgotForm.value).subscribe(
    //   res => {
    //     this.loaderService.display(false);
    //     this.router.navigate(['login']);
    //     this.alertService.success('RESETACCOUNT', true);
    //   },
    //   error => {
    //     if (error.status === 404) {
    //       this.alertService.success('RESETACCOUNT', true);
    //     }
    //     if (error.status === 422) {
    //       error.error.fieldErrors.forEach(element => {
    //         this.forgotForm.controls[element.field].setErrors({
    //           serverError: true
    //         });
    //         this.serverError = element.message;
    //       });
    //     }
    //     this.loaderService.display(false);

    //   }
    // );
  }

}
