import { TotalVisitedDetailComponent } from './../../total-visited-detail/total-visited-detail.component';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-detail-dialog',
  templateUrl: './detail-dialog.component.html',
  styleUrls: ['./detail-dialog.component.scss']
})
export class DetailDialogComponent {
  rowId;
  selectObj: any = [{ value: '', displayName: 'All' }];
  tableData = {
    tableHeader: [
      'Sr. No.',
      'Sent On',
      'Valid Till',
      'Total Delivered',
      'Total Visit',
      'Total Revenue'
    ],
    serviceDetail: {
      service: this.data.reportType === 'AutoPilot' ? 'ReportsService' : 'PromotionsService',
      method: 'getDetailTab',
      isParameter: true,
      parameterList: { page: '', size: '', id: '' }
    },
    mapping: [
      { type: 'index', dataValue: '' },
      { type: 'dateVal', dataValue: 'promoDetails' },
      { type: 'dateVal', dataValue: 'validTill' },
      { type: 'dialogWithPerm', dataValue: 'totalDelivered', subType: 'anchorAction', width: '850px',
       panelClass: 'mat-dialog-box', dataValue1: 'id', dataValue2: 'scheduleId', modelType: 'promotionDelivered',
        loadComponent: TotalVisitedDetailComponent },
      { type: 'dialogWithPerm', dataValue: 'totalVisits', subType: 'anchorAction', width: '850px',
      panelClass: 'mat-dialog-box', dataValue1: 'id', dataValue2: 'scheduleId', modelType: 'Visited',
       loadComponent: TotalVisitedDetailComponent },
      { type: 'dialogWithPerm', dataValue: 'totalRevenue', subType: 'anchorAction', width: '850px',
      panelClass: 'mat-dialog-box', dataValue1: 'id', dataValue2: 'scheduleId', modelType: 'Revenue',
       loadComponent: TotalVisitedDetailComponent }
    ],
    options: {
      pageSizeArray: [10, 20, 30, 50, 100],
    },
    searchData: {},
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log(this.data);
    this.tableData.serviceDetail.parameterList.id = this.data.id;
  }

}

