import { ICommonService } from './../../iservices/iCommon';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { IReviewsService } from '../../iservices/iReviewsServices/IReviews';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ReviewsService implements IReviewsService {
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  private apiObject: IApiBaseObject;
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService,
    private http: HttpClient,
    @Inject('ICommonService') private commonService: ICommonService,
    private router: Router
  ) {

  }
  /*
    * @method getReviewsDetails()
    * @desc used to get Reviews list .
    * @return json array data.
  */
  getReviewsDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.CUSTOMER_RESPONSE_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('reviewDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
    /*
    * @method getDepartmentCombo()
    * @desc used to get combo list .
    * @return json array data.
  */
  getDepartmentCombo(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.BLIND_DEPARTMENT_COMBO, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('propertyDepartmentDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
    * @method addReviews()
    * @desc used to add Reviews Type.
    * @param permData :array - Reviews data.
    * @return json array data.
  */
  addReviews(permData): any {
    // const requestData = this.modelBindingService.houseEncoder(permData);
    // this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.PROPERTY_ADD, requestData,
    //   true, false);
    // return this.apiService.postApi(this.apiObject).pipe(
    //   map((res) => {
    //     return res;
    //   },
    //     (error) => {
    //       return error;
    //     }
    //   ));
  }

  getZomatoReviewList = (res_id) => {
    const url = `https://developers.zomato.com/api/v2.1/reviews?res_id=${res_id}`;
    return this.http.get(url, {
      headers: { 'user-key': 'dc817edb5692269dc1a92425c4e0346c' },
      responseType: 'json'
    });
  }

  getGoogleAuthToken = () => {
    const query = {
      client_id: '130767744518-61g9qsoumrtke3qdb9pf72uh26qdoa0s.apps.googleusercontent.com',
      response_type: 'code',
      // scope :
      redirect_uri: this.router.url,
      access_type: 'offline'
    };
    const queryStr = this.commonService.getQueryStringFromObject(query);
    const url = `https://accounts.google.com/o/oauth2/v2/auth?${queryStr}`;
    return this.http.get(url);
  }

  getAllQuestionsRating() {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.CustomerDetailsWithReviewNext,
      {}, true, false);
    return this.apiService.getApi(this.apiObject);
  }

  getFeedbackDetails() {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.FEEDBACK,
      {}, true, false);
    return this.apiService.getApi(this.apiObject);
  }
}

