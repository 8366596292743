import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appOpenCloseMenu]'
})
export class OpenCloseMenuDirective {
  class = false;
  constructor(private el: ElementRef,
    private renderer: Renderer2) {

  }
  @HostListener('click') onClick() {
    console.log(this.el);
    // for (const i of this.el.nativeElement.classList)  {
    //   if (i === 'demo') {
    //     // dsdf
    //   }
    // }
    this.class = !this.class;
   console.log(this.class);
   if(this.class)
    this.renderer.addClass(this.el.nativeElement, 'demo');
    else
    this.renderer.removeClass(this.el.nativeElement, 'demo');
    // if (!this.class) {
    //   this.renderer2.removeClass(this.el.nativeElement, 'demo');
    // }

    // this.renderer.setElementClass(this.el.nativeElement, '', !this.class);
    // this.renderer.setElementClass(this.el.nativeElement, 'demo2', this.class);
    // this.renderer.setElementClass(this.el.nativeElement, '', !this.class);
  }
}
