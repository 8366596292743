import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ValidationMessage {
  constructor(private translate: TranslateService) {
  }
  /**
   * @method displayErrorMessage(param)
   * @desc return validation/error messages using errorCode
   * @param errorCode :string - ERRORCODE
   */
  displayErrorMessage(errorCode: string): any {
    let newErrorArray;

    this.translate.get(errorCode).subscribe(
      (value) => {

        newErrorArray = value;
      }
    );
    return newErrorArray;
  }
}
