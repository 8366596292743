
<div mat-dialog-content class="change-password-dialogbox">
       <div class="change-pass-heading">
        <img src="./assets/images/logo/revugain_new_logo.png" alt="" class="img_logo">
        <h4 class=""> {{ 'profile.changePassword' | translate }}</h4>
      </div>
      <form [formGroup]="changePassForm">
        <div class="mat-input-group" *ngIf="data?.from !== 'admin'">
          <mat-form-field class="">
            <input matInput type="password" autocomplete="password" placeholder="{{ 'profile.prevPassword' | translate }}" #oldPassword
              formControlName="oldPassword">
          </mat-form-field>
          <div *ngIf="changePassForm.controls['oldPassword'].errors && changePassForm.controls['oldPassword'].dirty" class="error-msg has-error">
            <div [ngSwitch]="obj(changePassForm.controls['oldPassword'].errors)[0]">
              <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('profileValidation.PASSWORD_REQUIRED') }} </mat-error>
              <mat-error *ngSwitchCase="'minlength'" class="help-block">{{ validationMessage.displayErrorMessage('profileValidation.PASSWORD_MINLENGTH') }}</mat-error>
              <mat-error *ngSwitchCase="'serverError'" class="help-block">{{ validationMessage.displayErrorMessage(serverErrorType) }}</mat-error>
            </div>
          </div>
        </div>

        <div class="mat-input-group">
          <mat-form-field class="">
            <input matInput type="password" autocomplete="newPassword" placeholder="{{ 'profile.newPassword' | translate }}" #newPassword
              formControlName="newPassword" maxlength="128 ">
          </mat-form-field>
          <div *ngIf="changePassForm.controls['newPassword'].errors && changePassForm.controls['newPassword'].dirty" class="error-msg has-error">
            <div [ngSwitch]="obj(changePassForm.controls['newPassword'].errors)[0]">
              <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('profileValidation.NEW_PASSWORD_REQUIRED') }}</mat-error>
              <mat-error *ngSwitchCase="'pattern'" class="help-block">{{ validationMessage.displayErrorMessage('profileValidation.NEW_PASSWORD_PATTERN') }}</mat-error>
              <mat-error *ngSwitchCase="'minlength'" class="help-block">{{ validationMessage.displayErrorMessage('profileValidation.NEW_PASSWORD_MINLENGTH') }}</mat-error>
            </div>
          </div>
        </div>
        <div class="mat-input-group">
          <mat-form-field class="">
            <input matInput type="password" autocomplete="confirmPassword" placeholder="{{ 'profile.confirmPassword' | translate }}"
              #confirmPassword formControlName="confirmPassword" maxlength="128 ">
          </mat-form-field>
          <div *ngIf="changePassForm.controls['confirmPassword'].errors && changePassForm.controls['confirmPassword'].dirty" class="error-msg has-error">
            <div [ngSwitch]="obj(changePassForm.controls['confirmPassword'].errors)[0]">
              <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('profileValidation.CONFIRM_PASSWORD_REQUIRED') }}</mat-error>
              <mat-error *ngSwitchCase="'minlength'" class="help-block">{{ validationMessage.displayErrorMessage('profileValidation.CONFIRM_PASSWORD_MINLENGTH') }}</mat-error>
              <mat-error *ngSwitchCase="'notEquivalent'" class="help-block">{{ validationMessage.displayErrorMessage('profileValidation.PASSWORD_NOT_EQUIVALENT') }}</mat-error>
            </div>
          </div>
        </div>
      </form>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="submitForm()" [disabled]="changePassForm.invalid">
    <i class="far fa-trash-alt"></i> {{ 'common.submit' | translate }}</button>
  <button mat-dialog-close mat-icon-button class="close-btn">
    <i class="material-icons">cancel</i>
  </button>
</div>
</div>
