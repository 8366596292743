<app-sidemenu *ngIf="showMenuComponent && headerType !=='cashier'" class="sidebar"></app-sidemenu>
<!-- <span *ngIf="showLoader" class="loading"></span> -->
<div *ngIf="progressBar">
    <div id="loader-wrapper">
            <div id="loader"></div>
    </div>
</div>
<div *ngIf="headerType !== 'cashier'">
    <div class="page-container" *ngIf="showMenuComponent; else withoutMenu;">
        <app-header></app-header>
        <app-alert></app-alert>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <ng-template #withoutMenu>
        <app-alert></app-alert>
        <router-outlet></router-outlet>
    </ng-template>
</div>
<!-- Putting header conditionally -->
<div *ngIf="headerType === 'cashier'" class="cashier-layout">
    <div *ngIf="showMenuComponent; else withoutMenu;">
        <app-cashier-header></app-cashier-header>
        <app-alert></app-alert>
        <div class="cashier-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <ng-template #withoutMenu>
        <app-alert></app-alert>
        <router-outlet></router-outlet>
    </ng-template>
</div>