<div class="content">
    <form (ngSubmit)="apply()" ngNativeValidate class="resize-form-cont">
        <div class="resie-item">
            <label for="width" trans>Width</label>
            <input type="number" id="width" name="width" [(ngModel)]="width" [min]="1" required>
        </div>

        <div class="resie-item">
            <label for="height" trans>Height</label>
            <input type="number" id="height" name="height" [(ngModel)]="height" [min]="1" required>
        </div>

        <button type="submit" class="hidden"></button>
    </form>

    <div class="input-container checkbox-container">
        <mat-checkbox class="aspect-ratio-checkbox" [(ngModel)]="maintainAspectRatio" trans>Maintain Aspect Ratio</mat-checkbox>
        <mat-checkbox  class="percentages-checkbox" [(ngModel)]="usePercentages" (ngModelChange)="togglePercentages()" trans>Use Percentages</mat-checkbox>
    </div>
</div>