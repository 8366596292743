<ng-container *ngIf="selectedFilter">
    <div class="content" [ngClass]="selectedFilter.name">
        <ng-container *ngFor="let config of selectedFilter.options | mapToIterable">
            <ng-container *ngIf="config.value.type === 'colorPicker'">
                <color-widget [compact]="true" [formControl]="colorFormControl" (ngModelChange)="applyFilterValue('color', $event)"></color-widget>
            </ng-container>
            <div class="input-container" *ngIf="config.value.type === 'select'">
                <label [for]="config.key" trans>{{config.key}}</label>
                <select [id]="config.key" (change)="applyFilterValue(config.key, $event.target.value)">
                    <option [value]="value" *ngFor="let value of config.value.available" trans>{{value}}</option>
                </select>
            </div>
            <div class="slider-container" *ngIf="config.value.type === 'slider'">
                <div class="slider-label" trans>{{config.key}}</div>
                <mat-slider
                        [value]="config.value.current"
                        [min]="config.value.min || 0"
                        [max]="config.value.max || 100"
                        [step]="config.value.step || 1"
                        (input)="applyFilterValue(config.key, $event.value)"
                ></mat-slider>
            </div>
        </ng-container>
    </div>
</ng-container>