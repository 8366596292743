import { ICommonService } from './../../../../iservices/iCommon';
import { API_ENDPOINTS } from './../../../../services/api-endpoints-constants';
import { IApiBaseObject } from './../../../../iModels/api-base-object.model';
import { IApiService } from './../../../../iservices/iApi';
import { ModelBindingService } from './../../../../services/binding.model.service';
import { Injectable, Inject } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  impId;
  private apiObject: IApiBaseObject;
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  crmCustomerDetails = [];
  importedCustomers = new Subject();
  constructor( @Inject('IApiService') private apiService: IApiService,
  @Inject('ICommonService') private commonService: ICommonService,
    private modelBindingService: ModelBindingService) {
  }

  getNotificationList = (data) => {
    let json;
    data.Id = data.Id ? data.Id : '';
    data.Title = data.Title ? data.Title : '';
    // tslint:disable-next-line:max-line-length
    const url = `${API_ENDPOINTS.Notification}?Id=${data.Id}&Title=${data.Title}&page=${data.page}&size=${data.size}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp: any) => {
        if (resp.code === 1000) {
          if (resp.data.length > 0) {
            json = { data: resp.data, 'itemCount': resp.data[0].totalRows ? resp.data[0].totalRows : resp.data.length };
          } else {
            json = { data: [], 'itemCount': 0 };
          }
          return json;
        } else {
          return json = { data: [], 'itemCount': 0 };
        }
      }
      ));
  }

  postNotificationData = (requestData) => {
    requestData.NotificationOn = this.formatDate(requestData.NotificationOn);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.Notification, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject);
  }

  delete(data, type): string {
    const url = `${API_ENDPOINTS.Notification}?Id=${data.id}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }

  formatDate(date): string {
    return this.commonService.formatDate(date);
   }


   getNotificationPopList() {
    // ?Title=''&page=''&size=''
    const url = `${API_ENDPOINTS.UserWiseNotification}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject);
   }
}
