import { ISocialMediaMasterService } from './../../../../../../iservices/iMasterServices/iSocialMediaMaster';
import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'lodash';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-category',
  templateUrl: './image-category.component.html',
  styleUrls: ['./image-category.component.scss']
})
export class ImageCategoryComponent implements OnInit {
  categoryList: Array<any> = [];
  totalItemCount: number;
  pageSizeArray: Array<any> = [10, 20, 30, 50, 100, 'Select All'];
  searchData: any = {};
  page = 1;
  size = 30;
  constructor(
    @Inject('ISocialMediaMasterService') private socialMediaMasterService: ISocialMediaMasterService,
    public dialogRef: MatDialogRef<ImageCategoryComponent>
  ) {
    this.getCategoryList();
  }

  ngOnInit() {
  }

  goPrev(res) {
    this.page = res.page;
    this.size = res.size;
    this.getCategoryList();
  }

  goNext(res) {
    this.page = res.page;
    this.size = res.size;
    this.getCategoryList();
  }

  goToPage(res) {
    this.page = res.page;
    this.size = res.size;
    this.getCategoryList();
  }

  setPageSize(res) {
    this.page = res.page;
    this.size = res.size;
    this.getCategoryList();
  }
  getCategoryList() {
    const data = {
      page: Number(this.page),
      size: Number(this.size)
    };
    this.socialMediaMasterService.getCategoryDetails(data).subscribe(
      (res) => {
        if (!_.isEmpty(res.data)) {
          this.categoryList = JSON.parse(JSON.stringify(res.data));
          this.totalItemCount = res.data[0].totalRows;
        } else {
          this.categoryList = [];
          this.totalItemCount = 0;
        }
      });
  }
}
