import { SharedService } from './shared.service';
import { Injectable } from '@angular/core';
import { RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { STORAGE_KEYS } from './storage-keys-constants';
import { IApiService } from './../iservices/iApi';
import { IApiBaseObject } from './../iModels/api-base-object.model';
import { ModelBindingService } from './binding.model.service';
import { ModelDecoderBindingService } from './bindingDecoder.model.service';
import { API_ENDPOINTS } from './api-endpoints-constants';
import { Router } from '@angular/router';
import { Headers } from '@angular/http';
@Injectable()
/**
 * @desc this class is used to get, post, update api data.
 */
export class ApiService implements IApiService {

  apiUrl = environment.url;
  constructor(private http: HttpClient,
    private sharedService: SharedService,
    private routerService: Router,
    public modelBindingService: ModelBindingService,
    public modelDecoderBindingService: ModelDecoderBindingService
  ) {
  }

  /**
   * @method postApi()
   * @desc postApi is common method for post API
   * @param endpoint :string - end point url of api calling.
   * @param data :json - request payload of api.
   * @param tokenRequiredFlag :boolean - define token is mandatory or not.
   */

  postApi(apiObject: IApiBaseObject): any {
    let headers: any;
    if (apiObject.multipart) {
      headers = this.getHeaders(apiObject.tokenRequiredFlag, apiObject.multipart);
    } else {
      headers = this.getHeaders(apiObject.tokenRequiredFlag);
    }
    return this.http.post(`${this.apiUrl}/${apiObject.endpoint}`,
      apiObject.apiData, headers).pipe(map((res) => {
        return res;
      }));
  }

  /**
   * @method getApi()
   * @desc getApi is common method for get API
   * @param endpoint :string - end point url of api calling.
   * @param tokenRequiredFlag :boolean - define token is mandatory or not.
   * @param returnWithoutMap :boolean - a boolean to return with or without map.
   */

  getApi(apiObject: IApiBaseObject): any {
    let headers: any;
    headers = this.getHeaders(apiObject.tokenRequiredFlag);
    const request = this.http.get(`${this.apiUrl}/${apiObject.endpoint}`, headers);
    return request
      .pipe(map((res) => {
        return res;
      }));
    // .catchError(error =>
    //  this.handleError(error, this.modelBindingService, apiObject)
    // );
  }


  /**
   * @method putApi()
   * @desc putApi is common method for put API
   * @param endpoint :string - end point url of api calling.
   * @param data :json - request payload of api.
   * @param tokenRequiredFlag :boolean - define token is mandatory or not.
   */
  putApi(apiObject: IApiBaseObject): any {
    let headers: any;
    if (apiObject.multipart) {
      headers = this.getHeaders(apiObject.tokenRequiredFlag, apiObject.multipart);
    } else {
      headers = this.getHeaders(apiObject.tokenRequiredFlag);
    }
    return this.http.put(`${this.apiUrl}/${apiObject.endpoint}`, apiObject.apiData, headers).pipe(
      map((res) => {
        return this.modelDecoderBindingService.apiResponseSuccessDecoder(res);
      }));
    // .catch(error =>
    //   this.handleError(error, this.modelBindingService, apiObject));
  }

  /**
   * @method deleteApi()
   * @desc deleteApi is common method for delete API
   * @param endpoint :string - end point url of api calling.
   * @param tokenRequiredFlag :boolean - define token is mandatory or not.
   * @param returnWithoutMap :boolean - a boolean to return with or without map.
   */
  deleteApi(apiObject: IApiBaseObject): any {
    let headers;
    let requestOptions;
    // const headers = this.getHeaders(apiObject.tokenRequiredFlag);
    headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-San-Jwt': this.sharedService.getDataFromStorage(STORAGE_KEYS.SESSION)
    });
    requestOptions = new RequestOptions({ headers: headers, body: apiObject.apiData });
    const request = this.http.delete(`${this.apiUrl}/${apiObject.endpoint}`, requestOptions);
    return request
      .pipe(map((res) => {
        return res;
      }));
    // .catchError(error =>
    //   this.handleError(error, this.modelBindingService, apiObject)));
  }

  /**
   * @method handleError()
   * @desc common method to handle error
   * @param error: object - error response payload.
   * @return response payload of error in json formate
   */
  handleError(error: any, modelBindingService: any,
    modelDecoderBindingService: any, apiObject: IApiBaseObject): any {
    let errorData = modelDecoderBindingService.apiResponseErrorDecoder(error);
    let errorCode = 'ERRORDEFAULTCODE';
    if (errorData.code === 401 && !error.url.includes(API_ENDPOINTS.LOGIN)) {
      this.sharedService.setDataInStorage(STORAGE_KEYS.SESSION, null);
      this.sharedService.setDataInStorage(STORAGE_KEYS.CHAIN_SESSION, null);
      this.sharedService.setDataInStorage(STORAGE_KEYS.PERMISSION_SESSION, null);
      this.routerService.navigate(['/login']);
      errorCode = 'USERSESSIONTIMEOUT';
    } else if (errorData.code === 400) {
      errorCode = 'ERROR400';
    } else if (errorData.code === 403) {
      errorCode = 'ERROR403';
    } else if (errorData.code === 500) {
      errorCode = 'ERRORDEFAULTCODE';
    } else if (errorData.code === 503) {
      errorCode = 'ERRORSERVICEUNAVAILABLE';
    } else if (errorData.code === 404) {
      errorCode = 'ERROR404';
    } else if (errorData.code === 422) {
      errorData = modelBindingService.errorDecoder(errorData);
    }
    // if (!apiObject.alertHide && errorData.code !== 422) {
    //   this.alertService.error(errorCode, true);
    // }
    return (
      errorData
      || 'Something went wrong');
  }

  /**
   * @method getHeaders()
   * @desc get api headers data  by using this method
   * @param tokenRequiredFlag :boolean - On the basis of this flag
   * token is required or not in header .
   * @return request header with define formate
   */
  private getHeaders(tokenRequiredFlag?: boolean, multipart?: boolean): any {
    let headers;
    if (!this.sharedService.getDataFromStorage(STORAGE_KEYS.SESSION)) {
      this.routerService.navigate(['login']);
      return;
    }
    if (multipart) {
      headers = new HttpHeaders({
        'X-San-Jwt': this.sharedService.getDataFromStorage(STORAGE_KEYS.SESSION)
      });
    } else if (tokenRequiredFlag) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-San-Jwt': this.sharedService.getDataFromStorage(STORAGE_KEYS.SESSION)
      });
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    const requestOptions = new RequestOptions({ headers: headers });
    return requestOptions;
  }
}

