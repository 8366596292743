<div class="editor-search">
  <input
    (keydown.enter)="searchDetail()"
    [(ngModel)]="title"
    placeholder="Search Template Category"
    value=""
  />
  <button (click)="getTemplates()">
    <i class="material-icons"> search</i>
  </button>
</div>
<div class="or-option">
  <span>Or</span><span><a class="category-anchor" href="javascript:void(0)" (click)="openCategoryDialog()">	&nbsp;	&nbsp;Category</a></span>
</div>
<button mat-button (click)="openBackgroundImage()" class="open-option-btn">
  <svg-icon name="photo-library"></svg-icon>
  <span class="name" trans>Open</span>
</button>
<div
  *ngIf="templatesList"
  class="search-results templete-img-items content"
  infiniteScroll
  infiniteScrollDistance="2"
  infiniteScrollUpDistance="1.5"
  infiniteScrollThrottle="200"
  [scrollWindow]="false"
  (scrolled)="onScrollDown()"
>
  <img
    title="{{ templates.title }}"
    (click)="getImage(templates.imagePath)"
    *ngFor="let templates of templatesList"
    src="{{ templates.imagePath }}"
  />
</div>
