import { validateEmail } from './../../../../utils/validations/email.validator';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationMessage } from '../../../../utils/locale';
import { IAlertService } from '../../../../iservices/iAlert';
import { IUserService } from '../../../../iservices/iUser';
import { IPropertyService } from '../../../../iservices/iPropertyMngServices/iProperty';
import { ILoaderService } from '../../../../iservices/iLoader';
import { COMMONFORMVALIDATIONRULE } from '../../../../utils/validations/commonFormRules';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  isSubmitted = false;
  userForm: FormGroup;
  rowId;
  actionType;
  propertyType;
  userType;
  userDepartmentData;
  obj = Object.getOwnPropertyNames;
  param = {
    page: 1,
    searchData: {},
    size: 0
  };
  /** MultiSelect initialization */
  /** control for the MatSelect filter keyword multi-selection */
  itemMultiFilterCtrlForProperty: FormControl = new FormControl();
  itemMultiFilterCtrlForUserDepartment: FormControl = new FormControl();
  itemMultiFilterCtrlForUserType: FormControl = new FormControl();
  /** list of items filtered by search keyword for multi-selection */
  filteredItemsMultiForProperty = new ReplaySubject();
  filteredItemsMultiForUserDepartment = new ReplaySubject();
  filteredItemsMultiForUserType = new ReplaySubject();
  private _onDestroy = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private validationMessage: ValidationMessage,
    private dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('IUserService') private userService: IUserService,
    @Inject('IPropertyService') private propertyService: IPropertyService,
    @Inject('IAlertService') private alertService: IAlertService,
    @Inject('ILoaderService') private loaderService: ILoaderService) { }

  /**
    * @method ngOnInit()
    * @desc used to Initialize Page.
    */

  ngOnInit(): void {
    this.buildForm();
    this.rowId = this.data.rowId;
    this.getPropertyType();
    // listen for search field value changes
    this.itemMultiFilterCtrlForProperty.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItemsMultiForProperty();
      });
    this.getUserTypeCombo();
    this.itemMultiFilterCtrlForUserType.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItemsMultiForUserType();
      });
    if (this.rowId) {
      this.actionType = 'edit';
      this.userForm.get('propertyId').clearValidators();
      this.userForm.get('propertyDepartmentId').clearValidators();
      this.userForm.get('userTypeId').clearValidators();
      this.userForm.get('password').clearValidators();
      this.getCustomerTypeDetailsById();
    } else {
      this.actionType = 'add';
      this.rowId = '';
    }
    this.userForm.get('propertyId').updateValueAndValidity();
    this.userForm.get('propertyDepartmentId').updateValueAndValidity();
    this.userForm.get('userTypeId').updateValueAndValidity();
    this.userForm.get('password').updateValueAndValidity();
  }

  /**
   * @method buildForm()
   * @desc used to build form.
   */

  buildForm(): void {
    this.userForm = this.formBuilder.group({
      propertyId: ['', Validators.required],
      propertyDepartmentId: ['', Validators.required],
      userTypeId: ['', Validators.required],
      name: ['', Validators.required],
      loginId: ['', Validators.required],
      password: ['', COMMONFORMVALIDATIONRULE.passwordPattern
      ],
      // email: ['', COMMONFORMVALIDATIONRULE.email],
      email: ['', validateEmail],
      // mobileNo: ['', COMMONFORMVALIDATIONRULE.phone],
      mobileNo: ['', Validators.pattern('[0-9]{10}')],
      id: [''],
      isActive: [''],
      disableBillAmount: [false],
      disableBillNo: [false],
    });
  }
  /**
     * @method submitUserType()
     * @desc used to submit user type details.
     */

  submitUserType(): void {
    this.isSubmitted = true;
    this.loaderService.display(true);
    if (this.actionType === 'edit') { // edit case
      this.userForm.value.id = this.rowId;
      this.userForm.value.isActive = true;
    }
    if (this.actionType === 'add') { // add case
      delete this.userForm.value['id'];
      delete this.userForm.value['isActive'];
    }
    this.userForm.value['disableBillAmount'] = this.userForm.value['disableBillAmount'] === true ? 1 : 0;
    this.userForm.value['disableBillNo'] = this.userForm.value['disableBillNo'] === true ? 1 : 0;
    this.userService.addUser(this.userForm.value).subscribe(
      res => {
        if (res.code === 1000) {
          this.loaderService.display(false);
          this.dialogRef.close();
          if (this.actionType === 'edit') {
            this.alertService.success('USEREDITED', false);
          }
          if (this.actionType === 'add') {
            this.alertService.success('USERCREATED', false);
          }
          this.userService.tableUpdated.next(true);
        } else if (res.code === 1001) {
          this.alertService.info(res.description, false);
          this.loaderService.display(false);
        } else {
          this.alertService.error('ERRORDEFAULTCODE', false);
          this.loaderService.display(false);
        }
      },
      error => {
        this.loaderService.display(false);
      }
    );
  }
  /*
 * @method getPropertyType()
 * @desc used to get type name details.
 */
  getPropertyType(): void {
    this.propertyService.getPropertyDetails(this.param).subscribe(
      resp => {
        if (resp['data']) {
          this.propertyType = resp.data;
          this.filteredItemsMultiForProperty.next(this.propertyType.slice());
        }
      });
  }
  getPropId(id): void {
    const param = {
      page: 1,
      searchData: {
        propertyId: id
      },
      size: 0
    };
    this.userService.getUserDepartmentCombo(param).subscribe(
      resp => {
        if (resp) {
          this.userDepartmentData = resp.data;
          if (!this.userDepartmentData) {
            this.userDepartmentData = [];
          this.filteredItemsMultiForUserDepartment.next(this.userDepartmentData.slice());
          } else {
            this.filteredItemsMultiForUserDepartment.next(this.userDepartmentData.slice());
          }
        }
      });
    this.itemMultiFilterCtrlForUserDepartment.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItemsMultiForUserDepartment();
      });
  }

  /*
   * @method getUserTypeCombo()
   * @desc used to get user type.
   */
  getUserTypeCombo(): void {
    this.userService.getUserTypeCombo(this.param).subscribe(
      resp => {
        if (resp) {
          this.userType = resp.data;
          this.filteredItemsMultiForUserType.next(this.userType.slice());
        }
      });
  }
  /**
 * @method getDepartmentById()
 * @desc used to get customer details using its id.
 */
  getCustomerTypeDetailsById(): void {
    this.userService.getUserDetailsById(this.rowId).
      subscribe(
      resp => {
        if (resp['data']) {
          resp = resp['data'];
          console.log(resp);
          this.userForm.patchValue(resp);
        }
      });
  }
  /**
 * @method trackByFn()
 * @desc to track the loop for current state.
 * @return null
 */

  trackByFn(): any {
    return null;
  }

  /**
   * @method filterItemsMultiForProperty()
   * @desc filter property list.
   * @returns list of items filtered by search keyword for multi-selection.
   */
  filterItemsMultiForProperty(): any {
    if (!this.propertyType) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForProperty.value;
    if (!search) {
      this.filteredItemsMultiForProperty.next(this.propertyType.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForProperty.next(
      this.propertyType.filter(item =>
        item.propertyName.toLowerCase().indexOf(search) > -1)
    );
  }
  /**
 * @method filterItemsMultiForPropertyDepartment()
 * @desc filter property department list.
 * @returns list of items filtered by search keyword for multi-selection.
 */
  filterItemsMultiForUserDepartment(): any {
    if (!this.userDepartmentData) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForUserDepartment.value;
    if (!search) {
      this.filteredItemsMultiForUserDepartment.next(this.userDepartmentData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForUserDepartment.next(
      this.userDepartmentData.filter(item =>
        item.departmentName.toLowerCase().indexOf(search) > -1)
    );
  }
  /**
* @method filterItemsMultiForUserType()
* @desc filter user list.
* @returns list of items filtered by search keyword for multi-selection.
*/
  filterItemsMultiForUserType(): any {
    if (!this.userType) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForUserType.value;
    if (!search) {
      this.filteredItemsMultiForUserType.next(this.userType.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForUserDepartment.next(
      this.userType.filter(item =>
        item.name.toLowerCase().indexOf(search) > -1)
    );
  }
}

