import { AbstractControl, ValidatorFn } from '@angular/forms';

export const validateEmailPhone: ValidatorFn = (control: AbstractControl):
{ [key: string]: boolean } => {
  const EMAIL_REGEXP =
  // tslint:disable-next-line:max-line-length
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/i;
  const PHONE_REGEXP = /^[0-9]{10}$/;
  return EMAIL_REGEXP.test(control.value) || PHONE_REGEXP.test(control.value) ? null : {
    validateEmailPhone: true
  };
};

