import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { Alert, AlertType } from '../component/common/alert/alert';
import { ResponseMessage } from '../utils/responseMessage';
import { IAlertService } from './../iservices/iAlert';

@Injectable()
export class AlertService implements IAlertService {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;

    constructor(private router: Router,
        private responsemessage: ResponseMessage) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }
    /**
     * @method success()
     * @desc method is used for success alert box
     * @param message :String - Success message
     * @param keepAfterRouteChange :boolean- keep for a single route change
     */
    success(successCode: string, keepAfterRouteChange = false): void {
        const message = this.responsemessage.displaySuccessMessage(successCode);
        this.alert(AlertType.Success, message, keepAfterRouteChange);
    }

    /**
     * @method successMsg()
     * @desc method is used for success alert box
     * @param message :String - Success message
     * @param keepAfterRouteChange :boolean- keep for a single route change
     */
    successMsg(message: string, keepAfterRouteChange = false): void {
        this.alert(AlertType.Success, message, keepAfterRouteChange);
    }

    /**
     * @method error()
     * @desc method is used for error alert box
     * @param message :String - Error message
     * @param keepAfterRouteChange :boolean- keep for a single route change
     */
    error(errorCode: string, keepAfterRouteChange = false): void {
        const message = this.responsemessage.displayErrorMessage(errorCode);
        this.alert(AlertType.Error, message, keepAfterRouteChange);
    }

     /**
     * @method errorMsg()
     * @desc method is used for error alert box
     * @param message :String - Error message
     * @param keepAfterRouteChange :boolean- keep for a single route change
     */
    errorMsg(message: string, keepAfterRouteChange = false): void {
        this.alert(AlertType.Error, message, keepAfterRouteChange);
    }

    /**
     * @method info()
     * @desc method is used for Information alert box
     * @param message :String - Information message
     * @param keepAfterRouteChange :boolean- keep for a single route change
     */
    info(message: string, keepAfterRouteChange = false): void {
        this.alert(AlertType.Info, message, keepAfterRouteChange);
    }
    /**
     * @method warn()
     * @desc method is used for Warning alert box
     * @param message :String - Warning message
     * @param keepAfterRouteChange :boolean- keep for a single route change
     */
    warn(message: string, keepAfterRouteChange = false): void {
        this.alert(AlertType.Warning, message, keepAfterRouteChange);
    }
    private alert(type: AlertType, message: string, keepAfterRouteChange = false): void {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<Alert> { type: type, message: message });
        setTimeout(() => {
            this.subject.next(null);
        }, 3000);
    }
    /**
     * @method clear()
     * @desc method is used for clear alert box
     */
    private clear(): void {
        // clear alerts
        this.subject.next(null);
    }
}
