import { Component, DoCheck, ViewChild, ElementRef, HostListener, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements DoCheck, OnInit, OnChanges {

  // @Input() currentPage: number; // the current page
  @Input() totalItems: number; // how many total items there are in all pages
  @Input() pageSizeArrayFromComponent: Array<number>;
  @Input() searchData;
  @Output() goPrev = new EventEmitter<object>();
  @Output() goNext = new EventEmitter<object>();
  @Output() goPage = new EventEmitter<object>();
  @Output() setPageSize = new EventEmitter<object>();
  @ViewChild('list') list: ElementRef;
  pager = [];
  ceil = Math.ceil;
  startPage;
  endPage;
  pagesArray = [];
  totalPages;
  currentPage;
  size;
  prevSearchData;
  pageSizeArray = [10, 20, 30, 50, 100];
  emmitedObject = {
    page: '',
    size: ''
  };
  sortShow = false;
  public text: String;
  // tslint:disable-next-line:no-empty

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target) && this.sortShow) {
        if (event.target.classList.contains('pagination')) {
          this.sortShow = !this.sortShow;
        }
      } else {
      this.sortShow = false;
    }
  }
  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {
    this.currentPage = 1;
    this.size = 10;

    if (this.pageSizeArrayFromComponent.length) {
      this.size = this.pageSizeArrayFromComponent[0];
      this.pageSizeArray = this.pageSizeArrayFromComponent;
    }
    this.getPager();
  }

  ngDoCheck(): void {
    if (this.searchData && Object.keys(this.searchData).length &&
      JSON.stringify(this.prevSearchData) !== JSON.stringify(this.searchData)) {
      this.prevSearchData = this.searchData;
      this.currentPage = 1;
    }
  }
  /**
   * @method getPager()
   * @desc used to pages array.
   */
  getPager(pageSizeSelected?: number): void {
    if (pageSizeSelected) {
      this.size = pageSizeSelected;
      this.emmitedObject.page = '1';
      this.emmitedObject.size = this.size;
      this.setPageSize.emit(this.emmitedObject);
      this.currentPage = 1;
    }
    this.totalPages = Math.ceil(this.totalItems / this.size);
    this.pagesArray = [];
    if (this.totalPages === 0) {
      this.endPage = 1;
    } else if (this.totalPages <= 5) {
      this.startPage = 1;
      this.endPage = this.totalPages;
    } else {
      if (this.currentPage <= 3) {
        this.startPage = 1;
        this.endPage = 5;
      } else if (this.currentPage + 2 >= this.totalPages) {
        this.startPage = this.currentPage - (5 - (this.totalPages - this.currentPage));
        this.endPage = this.totalPages;
      } else {
        this.startPage = this.currentPage - 2;
        this.endPage = this.currentPage + 2;
      }
    }
    for (let i = this.startPage; i <= this.endPage; i++) {
      this.pagesArray.push(i);
    }
  }

  /**
   * @method getMin()
   * @desc used to get starting page.
   */

  getMin(): number {
    if (this.size === 'Select All') {
      return 1;
    }
    let min = ((this.size * this.currentPage) - this.size) + 1;
    if (10 > this.totalItems) {
      min = 1;
    }
    return min;
    // return ((this.size * this.currentPage) - this.size) + 1;
  }

  /**
   * @method getMax()
   * @desc used to get last page.
   */
  getMax(): number {
    if (this.size === 'Select All') {
      return this.totalItems;
    }
    let max = this.size * this.currentPage;
    if (max > this.totalItems) {
      max = this.totalItems;
    }
    return max;
  }

  /**
   * @method onPage()
   * @desc used to select current page.
   */
  onPage(n: number): void {
    this.currentPage = n;
    this.emmitedObject.page = this.currentPage;
    this.emmitedObject.size = this.size;

    // console.log(this.emmitedObject);
    this.goPage.emit(this.emmitedObject);
    this.getPager();
  }

  /**
   * @method onPrev()
   * @desc used to select previous page.
   */
  onPrev(): void {
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
    }
    this.emmitedObject.page = this.currentPage;
    this.emmitedObject.size = this.size;
    this.goPrev.emit(this.emmitedObject);
    this.getPager();
  }

  /**
   * @method onNext()
   * @desc used to select next page.
   */
  onNext(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage = this.currentPage + 1;
    }
    this.emmitedObject.page = this.currentPage;
    this.emmitedObject.size = this.size;
    this.goNext.emit(this.emmitedObject);
    this.getPager();
  }

  ngOnChanges(): void {
    this.getPager();
  }
  trackByFn(): any {
    return null;
  }
  openPagerDropdown(): void {
    this.sortShow = !this.sortShow;
  }
  hidePagerDropdown(): void {
    this.sortShow = false;
  }

}
