import { ICommonService } from './../../iservices/iCommon';
import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { IPropertyService } from '../../iservices/iPropertyMngServices/iProperty';
import { MatDialog } from '@angular/material/dialog';
import {
  AddPropertyComponent
} from '../../component/property-management/property-managementComponents/property/add-property/add-property.component';
@Injectable({
  providedIn: 'root'
})
export class PropertyService implements IPropertyService {
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  editMisscallData = new BehaviorSubject({});
  editNoOfDays = new BehaviorSubject({});
  private apiObject: IApiBaseObject;
  constructor( @Inject('IApiService') private apiService: IApiService,
  @Inject('ICommonService') private commonService: ICommonService,
    private modelBindingService: ModelBindingService,
    public dialog: MatDialog,
    private modelDecoderBindingService: ModelDecoderBindingService) {

  }
  /*
    * @method getPropertyDetails()
    * @desc used to get Property list .
    * @return json array data.
  */
  getPropertyDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.PROPERTY_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('propertyDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }

  getPropertyExportData() {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.PROPERTY_ADD, {}, true, false);
    return this.apiService.getApi(this.apiObject);
  }

  getQr(apiGetParameters, uri): void {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      uri, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('QrDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
    * @method addProperty()
    * @desc used to add Property Type.
    * @param permData :array - Property data.
    * @return json array data.
  */
  addProperty(permData): any {
    const requestData = this.modelBindingService.propertyEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.PROPERTY_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
    * @method getPropertyDetailsById()
    * @desc used to get Property details by id.
    * @param id :string - Property id.
    * @return json array data.
  */
  getPropertyDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.PROPERTY_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService.propertyDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }
  /*
   * @method delete()
   * @desc used to Property row data.
   * @param id :array - Property id .
   * @param id :dialogType - dialogType .
   * @return string.
  */
  delete(id): string {
    let url;
    const dialogData = this.commonService.getDialogData();
    if (dialogData && dialogData.dialogType === 'misscallConfig') {
      url = this.modelBindingService.replaceUrlForDelete(
        API_ENDPOINTS.MISSED_CALL_OFFER, id);
    } else if (dialogData && dialogData.dialogType === 'smsSetting') {
      url = this.modelBindingService.replaceUrlForDelete(
        API_ENDPOINTS.SMS_SETTING_CONFIG, id);
    } else {
      url = this.modelBindingService.replaceUrlForDelete(
        API_ENDPOINTS.PROPERTY_DELETE, id);
    }
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }

  getSmsTypeList = () => {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.GET_SMS_TYPE_LIST, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe((
      map((resp) => {
        if (resp) {
          return  resp['data'];
        }
      })
    ));
  }

  getMissedCallOffer = (data) => {
    const url = `${API_ENDPOINTS.MISSED_CALL_OFFER}?PropertyId=${data.propertyId}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp: any) => {
        if (resp && resp.data) {
         const json = { data: resp.data, 'itemCount': resp.data.length };
          return json;
        } else {
          return resp;
        }
      },
      (error) => {
        return error;
      }));
  }

  getNoOfDays = (data) => {
    const url = `${API_ENDPOINTS.AUTO_PILOT_DAYS}?PropertyId=${data.propertyId}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp: any) => {
        if (resp && resp.data) {
         const json = { data: resp.data, 'itemCount': resp.data.length };
          return json;
        } else {
          return resp;
        }
      },
      (error) => {
        return error;
      }));
  }

  getNoOfDaysDetails = (data) => {
    const url = `${API_ENDPOINTS.AUTO_PILOT_DAYS}?PropertyId=${data.propertyId}&Id=${data.id}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp: any) => {
        if (resp && resp.data) {
          return resp.data;
        }
      }));
  }

  postAutoPilotNoOfDays = (data) => {
    const url = `${API_ENDPOINTS.AUTO_PILOT_DAYS}?PropertyId=${data.propertyId}&Id=${data.id}&Days=${data.days}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {},
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  getMissedCallDetails = (id) => {
    const url = `${API_ENDPOINTS.MISSED_CALL_OFFER}?Id=${id}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp: any) => {
        if (resp && resp.data) {
          return resp.data;
        }
      }));
  }

  getSmsConfiguration = (data) => {
    const url = `${API_ENDPOINTS.SMS_SETTING_CONFIG}?PropertyId=${data.propertyId}&Id=${data.id}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp: any) => {
        if (resp && resp.data && resp.data.length > 0) {
          const json = { data: resp.data, 'itemCount': resp.data[0].totalRows };
           return json;
         }
      }));
  }

  postMissedCallOffer = (data) => {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.MISSED_CALL_OFFER, data,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  deleteMissedCallOffer = (id) => {
    const url = this.modelBindingService.replaceUrlForDelete(
      API_ENDPOINTS.MISSED_CALL_OFFER, id);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  postSmsConfiguration = (data) => {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.SMS_SETTING_CONFIG, data,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  postPropertyConfiguration = (data) => {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.PROPERTY_CONFIGURATION, data,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  getPropertyConfiguration = (id) => {
    const url = `${API_ENDPOINTS.PROPERTY_CONFIGURATION}?PropertyId=${id}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject);
  }

  validateQrOffer = (token) => {
    const url = `${API_ENDPOINTS.VERIFY_TOKEN}?UserToken=${token}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject);
  }

  getQrCurrentOfferDetails = (token) => {
    const url = `${API_ENDPOINTS.QrCurrentOfferDetails}?Ot=${token}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject);
  }

  sendBySms = (id) => {
    const url = `${API_ENDPOINTS.Property_SendLoginSMS}?Id=${id}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {},
      true, false);
    return this.apiService.postApi(this.apiObject);
  }

  sendByEmail = (id) => {
    const url = `${API_ENDPOINTS.Property_SendLoginEmail}?Id=${id}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {},
      true, false);
    return this.apiService.postApi(this.apiObject);
  }

  postEmailServerSetting(data) {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.EMAIL_SETTING_CONFIG, data,
      true, false);
    return this.apiService.postApi(this.apiObject);
  }

  getEmailServerSetting = (id) => {
    const url = `${API_ENDPOINTS.EMAIL_SETTING_CONFIG}?PropertyID=${id}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject);
  }
}
