import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { ISettingService } from '../../iservices/iSettingServices/iSetting';
import { MatDialog } from '@angular/material/dialog';
import {
  AddLoyaltyProgramComponent
} from '../../component/setting/settingComponents/loyalty-program/add-loyalty-program/add-loyalty-program.component';
import {
  AddLoyaltyOfferComponent
} from '../../component/setting/settingComponents/loyalty-offer/add-loyalty-offer/add-loyalty-offer.component';
@Injectable({
  providedIn: 'root'
})
export class SettingService implements ISettingService {
  private apiObject: IApiBaseObject;
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    public dialog: MatDialog,
    private modelDecoderBindingService: ModelDecoderBindingService) {

  }
  /*
    * @method getRoyaltyPointDetails()
    * @desc used to get RoyaltyPoint list .
    * @return json array data.
  */
  getRoyaltyPointDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.ROYALTY_POINT_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('loyaltyProgramDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
    * @method addRoyaltyPoint()
    * @desc used to add RoyaltyPoint Type.
    * @param permData :array - RoyaltyPoint data.
    * @return json array data.
  */
  addRoyaltyPoint(permData): any {
    const requestData = this.modelBindingService.loyaltyProgramEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.ROYALTY_POINT_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
   * @method delete()
   * @desc used to setting row data.
   * @param id :array - setting comp id .
   * @param id :dialogType - dialogType .
   * @return string.
  */
  delete(id, dialogType): any {
    let endPoint;
    if (dialogType === 'loyaltyprogram') {
      endPoint = API_ENDPOINTS.ROYALTY_POINT_DELETE;
    }
    if (dialogType === 'loyaltyoffer') {
      endPoint = API_ENDPOINTS.ROYALTY_OFFER_DELETE;
    }
    const url = this.modelBindingService.replaceUrlForDelete(
      endPoint, id);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }

  /*
    * @method getRoyaltyProgramById()
    * @desc used to get royalty program details by id.
    * @param id :string - royalty id.
    * @return json array data.
  */
  getRoyaltyProgramById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.ROYALTY_POINT_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService.loyaltyProgramDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }

   /*
    * @method getRoyaltyOfferDetails()
    * @desc used to get RoyaltyOffer list .
    * @return json array data.
  */
  getRoyaltyOfferDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.ROYALTY_OFFER_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('loyaltyOfferDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
    * @method addRoyaltyOffer()
    * @desc used to add RoyaltyOffer Type.
    * @param permData :array - RoyaltyOffer data.
    * @return json array data.
  */
  addRoyaltyOffer(permData): any {
    const requestData = this.modelBindingService.loyaltyOfferEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.ROYALTY_OFFER_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }
    /*
    * @method getRoyaltyOfferById()
    * @desc used to get royalty Offer details by id.
    * @param id :string - royalty offer id.
    * @return json array data.
  */
  getRoyaltyOfferById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.ROYALTY_OFFER_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService.loyaltyOfferDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }

}
