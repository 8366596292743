import { ICustomersService } from '../../../../../iservices/iCrmServices/iCustomers';
import { IAlertService } from '../../../../../iservices/iAlert';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-verify-password',
  templateUrl: './verify-password.component.html',
  styleUrls: ['./verify-password.component.scss']
})
export class VerifyPasswordComponent implements OnInit {
  password: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<VerifyPasswordComponent>,
    @Inject('ICustomersService') private customersService: ICustomersService,
    @Inject('IAlertService') private alertService: IAlertService,
  ) {
  }

  ngOnInit() {
  }

  verifyPassword = () => {
    this.customersService.verifyPassword(this.password).subscribe((res) => {
      if (res.code === 1000) {
        this.alertService.successMsg('Password Verfied Enter Otp sent to your Mobile', false);
        this.dialogRef.close(res.code);
      } else {
        this.alertService.errorMsg(res.description, false);
      }
    }, (error) => {
      console.log(error);
    });
  }

}
