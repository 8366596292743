<div class="mat-page-container">
  <!--header-->
  <div fxLayout="row" fxLayout.xs="column"  fxLayoutGap="15PX"  class="mat-page-top-header">
      <div  fxFlex="1 0 auto" fxFlexAlign="center"><h3>{{ 'banner.addBanner' | translate }}</h3></div>
      <div fxFlex="100">
        <div class="search-box-fix-width">
        </div>
      </div>
      <div fxFlex="1 0 auto"></div>
  </div>
 <!--header-->
  <div class="white-bg pad-20 add-shadow">
        <form [formGroup]="bannerForm">
      <div class="mat-input-group">
            <mat-form-field>
              <input matInput type="text"  placeholder="{{ 'banner.title' | translate }}" #title formControlName="title">
            </mat-form-field>
            <div *ngIf="bannerForm.controls['title'].errors && bannerForm.controls['title'].dirty" class="error-msg has-error">
              <div [ngSwitch]="obj(bannerForm.controls['title'].errors)[0]">
                <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('bannerValidation.TITLE_REQUIRED') }}</mat-error>
                <mat-error *ngSwitchCase="'ValidateChars'" class="help-block">{{ validationMessage.displayErrorMessage('commonValidation.SPECIAL_CHARS') }}</mat-error>
              </div>
            </div>
          </div>
      <div class="mat-input-group">
        <label class="d-block mb-5">Multiple Image Upload <br> (Multiple Time)
                </label>
        <app-upload-profile-picture (valueChange)='getImage($event)'></app-upload-profile-picture>
        <!-- <div style="color: #900;background-color: #fee;">
          {{fileError}}
        </div> -->
        <div *ngIf="bannerForm.controls['imageDetailsList'].errors && isSubmitted" class="error-msg has-error">
          <div [ngSwitch]="obj(bannerForm.controls['imageDetailsList'].errors)[0]">
            <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('commonValidation.IMAGE_REQUIRED') }}</mat-error>
          </div>
        </div>
      </div>
      </form>
    </div>
    <div mat-dialog-actions>
            <button mat-raised-button color="primary" (click)="submitImages()"><i class="far fa-trash-alt"></i> {{ 'common.submit' | translate }}</button>
            <button mat-dialog-close mat-icon-button class="close-btn">
                <i class="material-icons">cancel</i>
              </button>
        </div>
</div>




