<h1 mat-dialog-title>{{ 'quotation.addQuotation' | translate }}</h1>
<div mat-dialog-content>
  <form [formGroup]="quoteForm">
    <div class="mat-input-group radio-input">
      <mat-radio-group formControlName="Type" #Type>
        <mat-radio-button value="Sms">Sms</mat-radio-button>
        <mat-radio-button value="Whatsapp">Whatsapp</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="mat-input-group">
      <mat-form-field>
        <textarea matInput formControlName="ApproveTemplate" #ApproveTemplate placeholder="{{ 'quotation.templateContent' | translate }} "></textarea>
      </mat-form-field>
    </div>
    <div class="mat-input-group">
      <mat-form-field>
        <textarea matInput style="min-height: 100px;" (keyup)="countChar($event)" type="text"
          placeholder="{{ 'quotation.quotation' | translate }}" #quatation formControlName="quatation">
              </textarea>
      </mat-form-field>
      <div>
        <span *ngIf="count?.char">Char Count: {{ count?.char }}</span>
        <span *ngIf="count?.message" style="float: right;">Message Count: {{ count?.message }}</span>
      </div>
      <div *ngIf="quoteForm.controls['quatation'].errors && quoteForm.controls['quatation'].dirty"
        class="error-msg has-error">
        <div [ngSwitch]="obj(quoteForm.controls['quatation'].errors)[0]">
          <mat-error *ngSwitchCase="'required'" class="help-block">{{
            validationMessage.displayErrorMessage('quotationValidation.QUOTATION_REQUIRED') }}</mat-error>
          <mat-error *ngSwitchCase="'ValidateChars'" class="help-block">{{
            validationMessage.displayErrorMessage('commonValidation.SPECIAL_CHARS') }}</mat-error>
        </div>
      </div>
    </div>
    <div class="mat-input-group">
      <mat-form-field class="mobile-no-field">
        <input matInput type="text" placeholder="{{ 'autoPilot.mobileNo' | translate }}" #MobileNo formControlName="MobileNo">
        <button mat-raised-button (click)="onTextMsgClick()" title="Text Message" matSuffix [disabled]="!quoteForm.controls['MobileNo'].value || quoteForm.controls['MobileNo'].invalid" color="primary">
          Message
       </button>
      </mat-form-field>
      <div *ngIf="quoteForm.controls['MobileNo'].errors && quoteForm.controls['MobileNo'].dirty" class="error-msg has-error">
          <div [ngSwitch]="obj(quoteForm.controls['MobileNo'].errors)[0]">
              <mat-error *ngSwitchCase="'pattern'" class="help-block">{{ validationMessage.displayErrorMessage('commonValidation.PHONE_NO_PATTERN') }}</mat-error>
          </div>
      </div>
  </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="submitQuote()" [disabled]="quoteForm.invalid"><i
      class="far fa-trash-alt"></i> {{ 'common.submit' | translate }}</button>
  <button mat-dialog-close mat-icon-button class="close-btn">
    <i class="material-icons">cancel</i>
  </button>
</div>