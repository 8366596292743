import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddQuotationComponent } from '../../..//masterComponents/social-media-category/quotation/add-quotation/add-quotation.component';
import { CommonPermissionsGuard } from '../../../../../gaurds/flow.guard';
@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit {
  selectObj: any = [{ value: '', displayName: 'All' }];
  tableData = {
    tableHeader: [
      'Sr. No.',
      'Type',
      'Approve Template Conent',
      'Quotation',
      'Action'
    ],
    serviceDetail: {
      service: 'SocialMediaMasterService',
      method: 'getQuoteDetails',
      isParameter: true,
      parameterList: { categoryId: '', page: '', size: ''}
    },
    mapping: [
      { type: 'index', dataValue: '' },
      { type: 'default', dataValue: 'type' },
      { type: 'default', dataValue: 'approveTemplate' },
      { type: 'default', dataValue: 'quatation' },
      {
        type: 'dialog', urlValue: '../dialogPopup',
        urlArgument1: '', dialogType1: 'quote',
        dataValue: 'id', parentclass: '', class: 'delete-icon',
        urlValue1: 'addquotation',
        parent: 'Master', child: 'Social Media Category',
        urlArgument: 'id', dialogType: 'quote',
        loadComponent: AddQuotationComponent, width: '450px', panelClass: 'mat-dialog-box',
        class1: 'edit-icon'
      }
    ],
    options: {
      pageSizeArray: [10, 20, 30, 50, 100],
    },
    searchData: {},
  };
  constructor(
  private route: ActivatedRoute,
  public commonPermissionsGuard: CommonPermissionsGuard,
  public dialog: MatDialog) {
    this.route.params.subscribe(params => {
      this.tableData.serviceDetail.parameterList.categoryId = params['categoryId'];
    });
   }

  ngOnInit() {
  }

  createQuotation(): void {
    this.dialog.open(AddQuotationComponent, {
      width: '450px',
      panelClass: 'mat-dialog-box',
      data: {id:  this.tableData.serviceDetail.parameterList.categoryId}
    });
  }

}
