<div class="toolbar-notification-container">
  <button
    mat-icon-button
    (click)="isOpen = !isOpen"
    [ngClass]="[cssPrefix + '-btn']"
    [class.open]="isOpen"
  >
    <mat-icon>notifications_none</mat-icon>
    <span class="badge" *ngIf="notifications && notifications?.length !== 0">{{
      notifications?.length
    }}</span>
  </button>

  <div class="dropdown mat-elevation-z4" [class.open]="isOpen">
    <div class="card">
      <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title">
          <div class="name">Notifications</div>
        </div>
      </div>
      <div
        *ngIf="notifications?.length !== 0; then thenBlock; else elseBlock"
      ></div>
    </div>
  </div>
</div>

<ng-template #thenBlock>
  <perfect-scrollbar class="content">
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let notification of notifications; last as isLast"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
              <div class="name">{{ notification.title }}</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="name">{{ notification.message }}</div>
      </mat-expansion-panel>
    </mat-accordion>
  </perfect-scrollbar>
</ng-template>

<ng-template #elseBlock>
  <div class="no" fxLayout="row" fxLayoutAlign="center center">暂无通知</div>
</ng-template>
