import { EmailerService } from './../email-campaigning/emailer.service';
import { Component, OnInit, Inject, Renderer2,ViewChild } from '@angular/core';
import { EmailEditorComponent } from 'angular-email-editor';
import { IAlertService } from '../../iservices/iAlert';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILoaderService } from '../../iservices/iLoader';
import * as _ from 'lodash';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-email-template',
  templateUrl: './add-email-template.component.html',
  styleUrls: ['./add-email-template.component.scss']
})
export class AddEmailTemplateComponent implements OnInit {



  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;
  editorLoaded($event) {
    // load the design json here
    try {
      this.emailEditor.loadDesign(JSON.parse(this.data.data.rawData));
    } catch (error) {
      this.emailEditor.loadDesign(dummyJson);
    }
  }

  htmlContent: any = '';
  // public htmlContent: string;
  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'LowerCase', 'UpperCase', '|', 'Undo', 'Redo', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink', 'CreateTable',
      'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
  };
  editId: number;
  jsonData: any;
  htmlData: any;
  public insertImageSettings = {
    // saveUrl: ''
    saveFormat: 'Base64'
  };
  // editorConfig: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: false,
  //   height: '15rem',
  //   minHeight: '5rem',
  //   placeholder: 'Create Offer Here*',
  //   translate: 'no',
  //   uploadUrl: 'v1/images'
  // };
  constructor(
    @Inject('IAlertService') private alertService: IAlertService,
    @Inject('ILoaderService') private loaderService: ILoaderService,
    private dialogRef: MatDialogRef<AddEmailTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2,
    public emailerService: EmailerService
  ) { }

  ngOnInit() {
    if (!_.isEmpty(this.data)) {
      if (!_.isEmpty(this.data.data)) {
        this.editId = this.data.data.id;
      }
      if (!_.isEmpty(this.data.jsonData)) {
        this.htmlContent = this.data.jsonData;
      }
    }
  }

  rteCreated() {
    if (!_.isEmpty(this.data)) {
      if (!_.isEmpty(this.data.data)) {
        this.editId = this.data.editId;
      }
      if (!_.isEmpty(this.data.jsonData)) {
        setTimeout(() => {
          this.htmlContent = this.data.jsonData;
        }, 500);
      }
    }
  }

  preparePayload() {
    return {
      'Name': this.data.fileName,
      'Type': this.data.templateType,
      'RawData': this.htmlContent,
      'HtmlRawData': '',
      'ContentCategoryId': this.data.templateCategory,
      'Id': this.editId ? this.editId : 0
    };
  }

  postDesign() {
    /* const payload = this.preparePayload();
    this.emailerService.postEmailTemplate(payload).subscribe((res) => {
      if (res.code === 1000) {
        const modifiedhtml = `<div style="width: 600px;max-width: 600px;display: inline-block;overflow-wrap: break-word">${this.htmlContent}</div>`;
        const blob = new Blob([`${modifiedhtml}`], { type: 'text/html;charset=utf-8' });
        saveAs(blob, `${this.data.fileName}.html`);
        const data = {
          id: this.editId ? this.editId : Number(res.data),
          res: { ...payload }
        };
        this.dialogRef.close(data);
        this.emailerService.tableUpdated.next(true);
        this.alertService.successMsg('Emailer Added Successfully', false);
      } else {
        this.alertService.errorMsg(res.description, false);
      }
      this.loaderService.display(false);
    }); */

    this.emailEditor.exportHtml((data:any) => {
      this.loaderService.display(true);

      const blob = new Blob([`${data.html}`], { type: 'text/html;charset=utf-8' });
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend =()=> {
          var base64data:any = reader.result;
          let mainPath=base64data.split(',')[1]
          console.log(base64data);
          const payload = {
            'Name': this.data.fileName,
            'Type': this.data.templateType,
            "ContentCategoryId":  this.data.data.contentCategoryId,
            "Id": this.editId,
            'BaseString': mainPath,
            'Extension': 'html',
            HtmlRawData: data.html,
            RawData:JSON.stringify(data.design)//JSON.stringify(data.body).replace(/"/g, "\\'")//.replace(/(["'])/g, "\\$1")//.replace(/"/g, "'")
          }


          this.emailerService.postEmailTemplate(payload).subscribe((res) => {
            if (res.code === 1000) {
              const data = {
                id: this.editId ? this.editId : Number(res.data),
                res: { ...payload }
              };
              this.dialogRef.close(data);
              this.emailerService.tableUpdated.next(true);
              this.alertService.successMsg('Emailer Added Successfully', false);
            } else {
              this.alertService.errorMsg(res.description, false);
            }
            this.loaderService.display(false);
          });
      }

    });

  }

  onFileChanges = (event: any) => {
    _.forEach(event.target.files, (file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        this.htmlContent = `${this.htmlContent}<img style="width: 150px;" src="${e.target.result}"/>`;
      };
    });
  }
}


const dummyJson={
  "body": {
    "rows": [{
      "cells": [1],
      "columns": [{
        "contents": [{
          "type": "image",
          "values": {
            "maxWidth": "100%",
            "src": {
              "url": "https://a.mailmunch.co/user_data/landing_pages/1500310730675-logosample_03.png",
              "width": 266,
              "height": 62
            },
            "draggable": true,
            "containerPadding": "10px 10px 20px",
            "deletable": true,
            "selectable": true,
            "action": {
              "url": "",
              "target": ""
            },
            "altText": "Image",
            "fullWidth": false,
            "textAlign": "center"
          }
        }]
      }],
      "values": {
        "backgroundColor": "rgba(255,255,255,0)",
        "backgroundImage": {
          "url": "",
          "fullWidth": true,
          "repeat": false,
          "center": false,
          "cover": false
        },
        "padding": "10px",
        "columnsBackgroundColor": "rgba(255,255,255,0)",
        "selectable": true,
        "draggable": true,
        "deletable": true
      }
    }, {
      "cells": [1],
      "columns": [{
        "contents": [{
          "type": "text",
          "values": {
            "containerPadding": "10px 10px 5px",
            "selectable": true,
            "draggable": true,
            "deletable": true,
            "color": "#000",
            "textAlign": "center",
            "lineHeight": "120%",
            "text": "<div><span style=\"color: #f10693; font-family: Pacifico, cursive; font-size: 14px; line-height: 16.8px;\"><strong><span style=\"font-size: 80px; line-height: 96px;\">Relax &amp; Plan</span></strong></span></div>"
          }
        }, {
          "type": "text",
          "values": {
            "containerPadding": "10px",
            "selectable": true,
            "draggable": true,
            "deletable": true,
            "color": "#000",
            "textAlign": "center",
            "lineHeight": "120%",
            "text": "<div><span style=\"color: #6fbb7b; font-family: Pacifico, cursive; font-size: 58px; text-align: center; line-height: 69.6px;\">&nbsp;Your Summer Break</span></div>"
          }
        }, {
          "type": "text",
          "values": {
            "containerPadding": "20px 10px 9px",
            "selectable": true,
            "draggable": true,
            "deletable": true,
            "color": "#000",
            "textAlign": "center",
            "lineHeight": "140%",
            "text": "<div><span style=\"font-size: 24px; color: #505050; line-height: 33.6px;\">Time to plan a vacation for your kids?</span></div>"
          }
        }, {
          "type": "text",
          "values": {
            "containerPadding": "5px 10px 10px",
            "selectable": true,
            "draggable": true,
            "deletable": true,
            "color": "#000",
            "textAlign": "center",
            "lineHeight": "160%",
            "text": "<div><span style=\"font-size: 18px; line-height: 28.8px;\"><span style=\"color: #505050; font-size: 18px; line-height: 28.8px;\">Check-out our summer break offers for&nbsp;</span><span style=\"color: #505050; font-size: 18px; line-height: 28.8px;\">children who are creative, full of energy,&nbsp;</span><span style=\"color: #505050; font-size: 18px; line-height: 28.8px;\">and can&rsquo;t sit still for a minute.</span></span></div>"
          }
        }, {
          "type": "divider",
          "values": {
            "containerPadding": "20px",
            "selectable": true,
            "draggable": true,
            "deletable": true,
            "width": "100%",
            "border": {
              "borderTopWidth": "1px",
              "borderTopStyle": "solid",
              "borderTopColor": "#CCC"
            },
            "textAlign": "center"
          }
        }]
      }],
      "values": {
        "backgroundColor": "rgba(255,255,255,0)",
        "backgroundImage": {
          "url": "",
          "fullWidth": true,
          "repeat": false,
          "center": false,
          "cover": false
        },
        "padding": "10px",
        "columnsBackgroundColor": "rgba(255,255,255,0)",
        "selectable": true,
        "draggable": true,
        "deletable": true
      }
    }],
    "values": {
      "backgroundColor": "#ffffff",
      "backgroundImage": {
        "url": "",
        "fullWidth": true,
        "repeat": false,
        "center": true,
        "cover": false
      },
      "contentWidth": "600px",
      "fontFamily": {
        "label": "Montserrat",
        "value": "'Montserrat',sans-serif",
        "type": "google",
        "weights": "400,700"
      }
    }
  }
}
