import { AppComponent } from './../app.component';
import { Routes } from '@angular/router';
import { LoginComponent } from '../component/auth/login/login.component';
import { AuthComponent } from '../component/auth/auth.component';
import { DialogPopupComponent } from '../component/common/dialog-popup/dialog-popup.component';
import { ForgotComponent } from '../component/auth/forgot/forgot.component';
import { DashboardComponent } from '../component/Dashboard/dashboard.component';
import {
  CommonFlowGuard, DashboardFlowGuard, ForgotFlowGuard, CashierGuard
} from '../gaurds/flow.guard';
import { ProfileComponent } from '../component/auth/profile/profile.component';
import { RegisterComponent } from '../component/auth/register/register.component';
import { DashboardCusttomerDetailsComponent } from '../component/Dashboard/dashboard-customer-details/dashboard-customer-details.component';
export const routes: Routes = [
  {
    path: '', component: AuthComponent,
    children: [
      { path: 'app', component: AppComponent, pathMatch: 'full' },
      {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Login' }
      },
      {
        path: 'propertySelect',
        loadChildren: () => import('../component/property-select-container/property-select-container.module').then(module=>module.PropertySelectContainerModule),
        data: { title: 'Select Property' }
      },
      {
        path: 'forgot',
        component: ForgotComponent,
        canActivate: [ForgotFlowGuard],
        data: { title: 'Forgot' }
      },
      {
        path: 'register',
        component: RegisterComponent,
        data: { title: 'Register' }
      },
      {
        path: 'user',
        component: ProfileComponent,
        canActivate: [CommonFlowGuard],
        data: { title: 'User' }
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [CashierGuard],
        data: { title: 'Dashboard' }
      },
      {
        path: 'customerdashboardetail',
        component: DashboardCusttomerDetailsComponent,
        canActivate: [CashierGuard],
        data: { title: 'View Customer Info' }
      },
      {
        path: 'customer-info',
        loadChildren: () => import('../component/customer-info/customer-info.module').then(module => module.CustomerInfoModule),
        data: { title: 'Customer Info' }
      },
    ]
  },
  {
    path: 'master',
    loadChildren: () => import('../component/master/master.module').then(module=>module.MasterModule),
    canActivate: [CommonFlowGuard],
    data: { title: 'Master' }
  },
  {
    path: 'reviews',
    loadChildren: () => import('../component/reviews/reviews.module').then(module => module.ReviewsModule),
    canActivate: [CommonFlowGuard],
    data: { title: 'Reviews' }
  },
  {
    path: 'crm',
    loadChildren: () => import('../component/crm/crm.module').then(module => module.CrmModule),
    canActivate: [CommonFlowGuard],
    data: { title: 'CRM' }
  },
  {
    path: 'survey',
    loadChildren: () => import('../component/survey/survey.module').then(module => module.SurveyModule),
    canActivate: [CommonFlowGuard],
    data: { title: 'Survey' }
  },
  {
    path: 'socialmedia',
    loadChildren: () => import('../component/social-media/social-media.module').then(module => module.SocialMediaModule),
    canActivate: [CommonFlowGuard],
    data: { title: 'Social Media' }
  },
  {
    path: 'promotion',
    loadChildren: () => import('../component/promotion/promotion.module').then(module => module.PromotionModule),
    canActivate: [CommonFlowGuard],
    data: { title: 'Promotion' }
  },
  {
    path: 'propertymanagement',
    loadChildren: () => import('../component/property-management/property-management.module').then(module => module.PropertyManagementModule),
    canActivate: [CommonFlowGuard],
    data: { title: 'Property Management' }
  },
  {
    path: 'reports',
    loadChildren: () => import('../component/reports/reports.module').then(module => module.ReportsModule),
    data: { title: 'Reports' }
  },
  {
    path: 'setting',
    loadChildren: () => import('../component/setting/setting.module').then(module => module.SettingModule),
    canActivate: [CommonFlowGuard],
    data: { title: 'Setting' }
  },
  {
    path: 'cashierreport',
    loadChildren: () => import('../component/cashier-report/cashier-report.module').then(module => module.CashierReportModule),
    canActivate: [CommonFlowGuard],
    data: { title: 'Cashier Report' }
  },
  {
    path: 'cashierreports',
    loadChildren: () => import('../component/cashier-report/cashier-customer/cashier-customer.module').then(module => module.CashierCustomerModule),
    canActivate: [CommonFlowGuard],
    data: { title: 'Cashier Report' }
  },
  {
    path: 'Rev',
    loadChildren: () => import('../component/qsr-review/qsr-review.module').then(module => module.QsrReviewModule),
    data: { title: 'Review' }
  },
  {
    path: 'Review',
    loadChildren: () => import('../component/qsr-review/qsr-review.module').then(module => module.QsrReviewModule),
    data: { title: 'Review' }
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('../component/unsubscription/unsubscription.module').then(module => module.UnsubscriptionModule),
    data: { title: 'Unsubscription' }
  },
  {
    path: 'referral',
    loadChildren: () => import('../component/referrel/referrel.module').then(module => module.ReferrelModule),
    data: { title: 'Referral' }
  },
  {
    path: 'CurrentOffer',
    loadChildren: () => import('../component/qr-offer/qr-offer.module').then(module => module.QrOfferModule),
    data: { title: 'QrOffer' }
  },
  {
    path: 'dialogPopup',
    component: DialogPopupComponent,
    data: { title: 'DialogPopup' }
  },
  {
    path: 'shorturlimage/:path',
    loadChildren: () => import('../component/short-image-url/short-image-url.module').then(module => module.ShortImageUrlModule),
    data: { title: 'Image' }
  }
];
