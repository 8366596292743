import { Component, OnInit } from '@angular/core';
import screenfull from 'screenfull';

@Component({
selector: 'app-fullscreen',
templateUrl: './fullscreen.component.html',
styleUrls: ['./fullscreen.component.scss']
})
export class FullscreenComponent implements OnInit {
isFullscreen = false;
constructor() { }

ngOnInit() {
}

toggleFullscreen() {
 if (screenfull.isEnabled) {
 screenfull.toggle();
 this.isFullscreen = !this.isFullscreen;
 }
}

}
