import {Component, ElementRef, EventEmitter, Output, ViewEncapsulation} from '@angular/core';
import {EditorControlsService} from '../../editor-controls.service';
import {GoogleFontsPanelComponent} from '../../google-fonts-panel/google-fonts-panel.component';
import { OverlayPanel } from '../../../../editor-tools/core/ui/overlay-panel/overlay-panel.service';
import {BaseDrawer} from '../base-drawer';
import {TextToolService} from '../../../../image-editor/tools/text/text-tool.service';
import {HistoryToolService} from '../../../../image-editor/history/history-tool.service';
import {ActiveObjectService} from '../../../../image-editor/canvas/active-object.service';
import { CanvasService } from '../../../../image-editor/canvas/canvas.service';
import { CanvasStateService } from '../../../../image-editor/canvas/canvas-state.service';
@Component({
    selector: 'text-drawer',
    templateUrl: './text-drawer.component.html',
    styleUrls: ['./text-drawer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {'class': 'controls-drawer'},
})
export class TextDrawerComponent extends BaseDrawer {
    fontSize: number;
    fontSizes = [8 , 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72, 144,
        288, 500];

    @Output() isDirty = new EventEmitter();

    /**
     * Name of this drawer.
     */
    public name = 'text';

    /**
     * TextDrawerComponent Constructor.
     */
    constructor(
        public textTool: TextToolService,
        private overlayPanel: OverlayPanel,
        protected history: HistoryToolService,
        public controls: EditorControlsService,
        public activeObject: ActiveObjectService,
        public canvas: CanvasService,
        private canvasState: CanvasStateService
    ) {
        super(history, controls);
    }

    /**
     * Add new text object to canvas.
     */
    public addText() {
        this.dirty = true;
        this.isDirty.emit(true);
        this.textTool.add('Double click here', this.fontSize);
    }

    public toggleValue(name: string) {
        this.dirty = true;
        const control = this.activeObject.getControl(name);

        if (control.value) {
            control.setValue(false);
        } else {
            control.setValue(true);
        }
    }

    public toggleItalicStyle() {
        this.dirty = true;
        const control = this.activeObject.getControl('text.fontStyle');

        if (control.value === 'italic') {
            control.setValue('normal');
        } else {
            control.setValue('italic');
        }
    }

    public toggleBoldStyle() {
        this.dirty = true;
        const control = this.activeObject.getControl('text.fontStyle');

        if (control.value === 'bold') {
            control.setValue('normal');
        } else {
            control.setValue('bold');
        }
    }

    setTextSize() {
        const param: any = 'fontSize';
        const obj = this.canvasState.fabric.getActiveObject();
        if (obj) {
            obj.set(param, this.fontSize);
            this.canvas.render();
        }
    }

    public openGoogleFontsPanel(origin: HTMLElement) {
        this.overlayPanel.open(GoogleFontsPanelComponent, {position: 'bottom', origin: new ElementRef(origin)})
            .valueChanged().subscribe(font => {
                this.textTool.setFont(font);
            });
    }
}
