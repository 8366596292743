import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DashboardCusttomerDetailsComponent } from '../dashboard-customer-details/dashboard-customer-details.component';

@Component({
  selector: 'app-dashboard-review-details',
  templateUrl: './dashboard-review-details.component.html',
  styleUrls: ['./dashboard-review-details.component.scss']
})
export class DashboardReviewDetailsComponent implements OnInit, DoCheck  {
  @Input() dashboardDetails: any;
  dashboardData: any;
 
  constructor(
    public dialog: MatDialog,
  ) { }
  ngDoCheck() {
    if (this.dashboardDetails !== this.dashboardData) {
      this.dashboardData = this.dashboardDetails;
    }
  }
  ngOnInit() {
  }
  openUpdateNotificationModel = (customer) => {
    let dialogConfig = new MatDialogConfig();
    dialogConfig = {
      disableClose: true,
      autoFocus: true,
      width: '450px',
      panelClass: 'mat-dialog-box',
      data: customer.customerBirthdayList.data
    };
    this.dialog.open(DashboardCusttomerDetailsComponent, dialogConfig);
  }

}
