import { FacebookLoginProvider, SocialAuthService, SocialLoginModule,SocialAuthServiceConfig } from 'angularx-social-login';
import { SocialLoginService } from './services/social-login.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AuthComponent } from './component/auth/auth.component';
import { LoginComponent } from './component/auth/login/login.component';
import { routes } from './routes/route';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ValidationMessage } from './utils/locale';
import { UserService } from './services/user.service';
import { ApiService } from './services/api.service';
import { SharedService } from './services/shared.service';
import { ResponseMessage } from './utils/responseMessage';
import { LocalStorageService } from './services/local-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { ModelBindingService } from './services/binding.model.service';
import { ModelDecoderBindingService } from './services/bindingDecoder.model.service';
import { AlertService } from './services/alert.service';
import { LoaderService } from './services/loader.service';
import { AlertComponent } from './component/common/alert/alert.component';
import { NgbActiveModal, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './component/Dashboard/dashboard.component';
import { ForgotComponent } from './component/auth/forgot/forgot.component';
import {
  CommonFlowGuard, DashboardFlowGuard, ForgotFlowGuard, RegisterFlowGuard, CashierGuard,
  CommonPermissionsGuard
} from './gaurds/flow.guard';
import { ProfileComponent } from './component/auth/profile/profile.component';
import { RegisterComponent } from './component/auth/register/register.component';
import { DashboardReviewCardComponent } from './component/Dashboard/dashboard-review-card/dashboard-review-card.component';
import { DashboardReviewDetailsComponent } from './component/Dashboard/dashboard-review-details/dashboard-review-details.component';
import { DashboardCusttomerDetailsComponent } from './component/Dashboard/dashboard-customer-details/dashboard-customer-details.component';
import { ChangePasswordComponent } from './component/auth/change-password/change-password.component';
import { AddUserComponent } from './component/auth/profile/add-user/add-user.component';
import { NgxLinkedinModule } from 'ngx-linkedin';


const config = [
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('1044186112425729')
  }
];

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LoginComponent,
    AlertComponent,
    DashboardComponent,
    ForgotComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardReviewCardComponent,
    DashboardReviewDetailsComponent,
    DashboardCusttomerDetailsComponent,
    ChangePasswordComponent,
    AddUserComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forRoot(routes),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    SocialLoginModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule,
    NgxLinkedinModule.forRoot({
      clientId: ':81tlgtvkm1yzb1:'
  })
  ],
  entryComponents: [ChangePasswordComponent, AddUserComponent
  ],
  providers: [
    ValidationMessage,
    ResponseMessage,
    UserService,
    ApiService,
    SharedService,
    LocalStorageService,
    CookieService,
    ModelBindingService,
    ModelDecoderBindingService,
    AlertService,
    LoaderService,
    { provide: 'IApiService', useClass: ApiService },
    { provide: 'ISharedService', useClass: SharedService },
    { provide: 'IUserService', useClass: UserService },
    { provide: 'IAlertService', useClass: AlertService },
    { provide: 'ILoaderService', useClass: LoaderService },
    { provide: 'ISocialLoginService', useClass: SocialLoginService },
    {
      provide: {} as SocialAuthServiceConfig,
      useFactory: provideConfig
    },
    NgbModal,
    NgbActiveModal,
    CommonFlowGuard, DashboardFlowGuard, ForgotFlowGuard, RegisterFlowGuard, CashierGuard,
    CommonPermissionsGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
