import { Validators } from '@angular/forms';
import { validateEmail } from './email.validator';
import { validateEmailPhone } from './emailphone.validator';
import { validateChars } from './commonChars.validator';
export const COMMONFORMVALIDATIONRULE = {
  firstName: [Validators.required, Validators.pattern('[a-zA-Z0-9.]*'), Validators.maxLength(20)],
  middleName: [Validators.pattern('[a-zA-Z0-9.]*'), Validators.maxLength(20)],
  lastName: [Validators.required, Validators.pattern('[a-zA-Z0-9.]*'), Validators.maxLength(20)],
  username: [Validators.required,
  Validators.pattern('[a-zA-Z0-9.]*'),
  Validators.maxLength(40), Validators.minLength(4)],
  commonChar: [ Validators.required],
  email: [Validators.required, Validators.maxLength(40), validateEmail],
  status: [Validators.required],
  phone: [Validators.required, Validators.pattern('[0-9]{10}')],
  emailNdPhone: [Validators.required, validateEmailPhone],
  password: [Validators.required, Validators.minLength(8)],
  passwordPattern: [Validators.required, Validators.minLength(8),
  Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{7,}')],
  confirmPassword: [Validators.required],
  zipCode: [Validators.required, Validators.pattern('[0-9]{5}')],
  pincode: [Validators.required,
  Validators.pattern('[0-9]{6}'), Validators.minLength(6), Validators.maxLength(6)],
  addressLine1: [Validators.required, Validators.maxLength(50)],
  addressLine2: [Validators.maxLength(50)],
  commonFields: [Validators.minLength(1), Validators.maxLength(20), Validators.required],
  commonFields1: [Validators.minLength(2), Validators.maxLength(50), Validators.required],
  incomeValidate: [Validators.required, Validators.pattern('([0-9]+(\.[0-9]+)?)')],
  numberValidate: [Validators.required, Validators.pattern('^[0-9]*$')],
   // ^[^-\s][a-zA-Z0-9.\s-]+$
};
