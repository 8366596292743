import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { IAutoPilotService } from '../../iservices/iPromotionServices/iAutoPilot';
import {
  AddAutoPilotComponent
} from '../../component/promotion/promotionComponents/auto-pilot/add-auto-pilot/add-auto-pilot.component';


@Injectable({
  providedIn: 'root'
})
export class AutoPilotService implements IAutoPilotService {
  // id;
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  private apiObject: IApiBaseObject;
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService,
    public dialog: MatDialog) {

  }
  /*
    * @method getAutoPilotDetails()
    * @desc used to get AutoPilot list .
    * @return json array data.
  */
  getAutoPilotDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.AUTO_PILOT_DETAILS, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('autoPilotDetailsDecoder', resp);
          if (resp['data'].data) {
            json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
          } else {
            json = { data: resp['data'].data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
  /*
    * @method addAutoPilot()
    * @desc used to add Customer Type.
    * @param permData :array - House data.
    * @return json array data.
  */
  addAutoPilot(permData): any {
    const requestData = this.modelBindingService.autoPilotEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.AUTO_PILOT_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
    * @method getAutoPilotDays()
    * @desc used to get AutoPilot days .
    * @return json array data.
  */
  getAutoPilotDays(): any {
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.AUTO_PILOT_DAYS, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        resp['data'] = this.modelDecoderBindingService
          .mappingDataReturn('autoPilotDaysDecoder', resp);
        return resp['data'];
      },
        (error) => {
          return error;
        }
      ));
  }
  /*
  * @method searchAutoPilot()
  * @desc used to search details.
  * @param id :id - autopilot data.
  * @return json array data.
   */
  searchAutoPilot(id, days): any {
    let url;
    url = API_ENDPOINTS.AUTO_PILOT_EXISTING + '?AutoPilot_PropertyId=' + id + '&whentoSendRewards=' + days;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
  * @method searchAutoPilot()
  * @desc used to search details.
  * @param id :id - autopilot data.
  * @return json array data.
   */
  postToggleValue(id, value): any {
    const url = `${API_ENDPOINTS.AUTO_PILOT_SETTING}?Id=${id}&IsSettingOn=${value}`;
    this.apiObject = this.modelBindingService.setApiCallObject(url, {},
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

}


