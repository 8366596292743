import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationMessage } from '../../../../../../utils/locale';
import { IAlertService } from '../../../../../../iservices/iAlert';
import { ISocialMediaMasterService } from '../../../../../../iservices/iMasterServices/iSocialMediaMaster';
import { COMMONFORMVALIDATIONRULE } from '../../../../../../utils/validations/commonFormRules';
import { ILoaderService } from '../../../../../../iservices/iLoader';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-add-quotation',
  templateUrl: './add-quotation.component.html',
  styleUrls: ['./add-quotation.component.scss']
})
export class AddQuotationComponent implements OnInit {
  count: any = {};
  isSubmitted = false;
  serverErrorMsg;
  quoteForm: FormGroup;
  serverError;
  obj = Object.getOwnPropertyNames;
  today;
  time;
  rowId;
  actionType;
  categoryId;
  constructor(
    private formBuilder: FormBuilder,
    private validationMessage: ValidationMessage,
    private dialogRef: MatDialogRef<AddQuotationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('ISocialMediaMasterService') private socialMediaMasterService: ISocialMediaMasterService,
    @Inject('IAlertService') private alertService: IAlertService,
    @Inject('ILoaderService') private loaderService: ILoaderService
  ) {
  }

  /**
    * @method ngOnInit()
    * @desc used to Initialize Page.
    */

  ngOnInit(): void {
    this.buildForm();
    this.categoryId = this.data.id;
    this.rowId = this.data.rowId;
    if (this.rowId) {
      this.actionType = 'edit';
      this.getQuoteDetailsById();
    } else {
      this.actionType = 'add';
      this.rowId = '';
    }
  }

  onTextMsgClick(): void{
    this.submitQuote('textmsg')
  }

  /**
   * @method buildForm()
   * @desc used to build form.
   */

  buildForm(): void {
    this.quoteForm = this.formBuilder.group({
      quatation: ['', COMMONFORMVALIDATIONRULE.commonChar],
      categoryId: [null],
      id: [''],
      Type: [''],
      isActive: [''],
      ApproveTemplate: [''],
      MobileNo: ['', Validators.pattern('[0-9]{10}')],
    });
  }
  /**
     * @method submitQuote()
     * @desc used to submit quote details.
     */

  submitQuote(from?): void {
    this.isSubmitted = true;
    this.loaderService.display(true);
    if(from !== 'textmsg'){
      this.quoteForm.value["MobileNo"] = "";
    }
    if (this.actionType === 'edit') { // edit case
      this.quoteForm.value.id = this.rowId;
      this.quoteForm.value.isActive = true;
      let payload = {...this.quoteForm.value}
      payload["IsSMS"] = payload.Type === "Sms" ? 1 : 0;
      payload["ISWhatsup"] = payload.Type === "Whatsapp" ? 1 : 0;
      this.socialMediaMasterService.addQuote(payload).subscribe(
        res => {
          if (res.code === 1000) {
            this.loaderService.display(false);
            this.dialogRef.close();
            this.alertService.success('QUOTEEDITED', false);
            this.socialMediaMasterService.tableUpdated.next(true);
          } else if (res.code === 1001) {
            this.alertService.info(res.description, false);
            this.loaderService.display(false);
          } else {
            this.alertService.error('ERRORDEFAULTCODE', false);
            this.loaderService.display(false);
          }
        },
        error => {
          this.loaderService.display(false);
        }
      );
    }
    if (this.actionType === 'add') { // add case
      this.quoteForm.value.categoryId = this.categoryId;
      delete this.quoteForm.value['id'];
      delete this.quoteForm.value['isActive'];
      let payload = {...this.quoteForm.value}
      payload["IsSMS"] = payload.Type === "Sms" ? 1 : 0;
      payload["ISWhatsup"] = payload.Type === "Whatsapp" ? 1 : 0;
      this.socialMediaMasterService.addQuote(payload).subscribe(
        res => {
          if (res.code === 1000) {
            this.loaderService.display(false);
            this.dialogRef.close();
            this.alertService.success('QUOTECREATED', false);
            this.socialMediaMasterService.tableUpdated.next(true);
          } else if (res.code === 1001) {
            this.alertService.info(res.description, false);
            this.loaderService.display(false);
          } else {
            this.alertService.error('ERRORDEFAULTCODE', false);
            this.loaderService.display(false);
          }
        },
        error => {
          this.loaderService.display(false);
        }
      );
    }
  }
  /**
   * @method getQuoteDetailsById()
   * @desc used to get quote details using its id.
   */
  getQuoteDetailsById(): void {
    this.socialMediaMasterService.getQuoteDetailsById(this.rowId).
      subscribe(
      resp => {
        if (resp['data']) {
          resp = resp['data'];
          this.quoteForm.patchValue({
            quatation: resp.quatation,
            categoryId: resp.categoryId,
            Type: resp.type,
            ApproveTemplate: resp.approveTemplate,
          });
        }
      });
  }
  countChar = (event) => {
    this.count.char =this.quoteForm.value.quatation.length;
    if (this.count.char) {
      this.count.message = Math.trunc(this.count.char / 160);
    }
  }
}
