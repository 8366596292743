


<div class="text-center delete-pop">
    <h3 mat-dialog-title class="text-center"> Are you sure you want to delete ? </h3>
    <!-- <div mat-dialog-content>
      <p>Are you sure you want to delete gopalpura?</p>
    </div> -->
    <div mat-dialog-actions class="justify-cont-center">
      <button mat-raised-button color="delete" (click)="remove()" [mat-dialog-close]="true"><i class="far fa-trash-alt"></i> Delete</button>
      <button mat-raised-button color="cancel"   mat-dialog-close ><i class="far fa-window-close"></i> Cancel</button>
    </div>
</div>

