<h1 mat-dialog-title>
  {{ 'user.addUser' | translate }}</h1>
<div mat-dialog-content>
  <form [formGroup]="userForm">
    <div *ngIf="actionType !=='edit'" fxLayout="row" fxLayout.xs="column" fxLayoutGap="15PX">
      <div class="mat-input-group" fxFlex="50">
        <mat-form-field>
          <mat-select formControlName="propertyId" #propertyId name="propertyId" placeholder="{{ 'user.property' | translate }} " (selectionChange)="getPropId($event.value)">
            <app-mat-select-search [formControl]="itemMultiFilterCtrlForProperty"></app-mat-select-search>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let type of filteredItemsMultiForProperty | async; trackBy:trackByFn;" [value]="type.id" (ng-checked)="true">
              {{ type.propertyName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="userForm.controls['propertyId'].errors && userForm.controls['propertyId'].dirty" class="error-msg has-error">
          <div [ngSwitch]="obj(userForm.controls['propertyId'].errors)[0]">
            <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('userValidation.PROPERTY_REQUIRED') }}</mat-error>
          </div>
        </div>
      </div>
      <div class="mat-input-group" fxFlex="50">
        <mat-form-field>
          <mat-select formControlName="userTypeId" #userTypeId name="userTypeId" placeholder="{{ 'user.userType' | translate }} ">
            <app-mat-select-search [formControl]="itemMultiFilterCtrlForUserType"></app-mat-select-search>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let user of filteredItemsMultiForUserType | async; trackBy:trackByFn;" (ng-checked)="true" [value]="user.id">
              {{ user.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="userForm.controls['userTypeId'].errors && userForm.controls['userTypeId'].dirty" class="error-msg has-error">
          <div [ngSwitch]="obj(userForm.controls['userTypeId'].errors)[0]">
            <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('userValidation.USER_TYPE_REQUIRED') }}</mat-error>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15PX">
      <div *ngIf="actionType !=='edit'" class="mat-input-group" fxFlex="50">
        <mat-form-field>
          <mat-select formControlName="propertyDepartmentId" #propertyDepartmentId name="propertyDepartmentId" placeholder="{{ 'user.department' | translate }} ">
            <app-mat-select-search [formControl]="itemMultiFilterCtrlForUserDepartment"></app-mat-select-search>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let deptname of filteredItemsMultiForUserDepartment | async; trackBy:trackByFn;" (ng-checked)="true"
              [value]="deptname.id">
              {{ deptname.departmentName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="userForm.controls['propertyDepartmentId'].errors && userForm.controls['propertyDepartmentId'].dirty" class="error-msg has-error">
          <div [ngSwitch]="obj(userForm.controls['propertyDepartmentId'].errors)[0]">
            <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('userValidation.DEPARTMENT_REQUIRED') }}</mat-error>
          </div>
        </div>
      </div>
      <div class="mat-input-group" fxFlex="50">
        <mat-form-field>
          <input matInput type="text" placeholder="{{ 'user.userName' | translate }}" #name formControlName="name">
        </mat-form-field>
        <div *ngIf="userForm.controls['name'].errors && userForm.controls['name'].dirty" class="error-msg has-error">
          <div [ngSwitch]="obj(userForm.controls['name'].errors)[0]">
            <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('usersValidation.USER_NAME_REQUIRED') }}</mat-error>
            <!-- <mat-error *ngSwitchCase="'pattern'" class="help-block">{{ validationMessage.displayErrorMessage('usersValidation.USER_NAME_PATTERN') }}</mat-error>
            <mat-error *ngSwitchCase="'maxlength'" class="help-block">{{ validationMessage.displayErrorMessage('usersValidation.USER_NAME_MAXLENGTH') }}</mat-error>
            <mat-error *ngSwitchCase="'minlength'" class="help-block">{{ validationMessage.displayErrorMessage('usersValidation.USER_NAME_MINLENGTH') }}</mat-error> -->
          </div>
        </div>
      </div>
</div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15PX">
          <div class="mat-input-group" fxFlex="50">
              <mat-form-field>
                <input matInput type="text" class="form-control" placeholder="{{ 'user.loginId' | translate }}" #loginId formControlName="loginId">
              </mat-form-field>
              <div *ngIf="userForm.controls['loginId'].errors && userForm.controls['loginId'].dirty" class="error-msg has-error">
                <div [ngSwitch]="obj(userForm.controls['loginId'].errors)[0]">
                  <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('userValidation.LOGIN_ID_REQUIRED') }}</mat-error>
                  <!-- <mat-error *ngSwitchCase="'pattern'" class="help-block">{{ validationMessage.displayErrorMessage('usersValidation.USER_NAME_PATTERN') }}</mat-error>
                  <mat-error *ngSwitchCase="'maxlength'" class="help-block">{{ validationMessage.displayErrorMessage('usersValidation.USER_NAME_MAXLENGTH') }}</mat-error>
                  <mat-error *ngSwitchCase="'minlength'" class="help-block">{{ validationMessage.displayErrorMessage('usersValidation.USER_NAME_MINLENGTH') }}</mat-error> -->
                </div>
              </div>
            </div>
        <div *ngIf="actionType !=='edit'" class="mat-input-group" fxFlex="50">
          <mat-form-field class="">
            <input matInput type="password" placeholder="{{ 'user.password' | translate }}" #password
              formControlName="password" maxlength="128 ">
          </mat-form-field>
          <div *ngIf="userForm.controls['password'].errors && userForm.controls['password'].dirty" class="error-msg has-error">
            <div [ngSwitch]="obj(userForm.controls['password'].errors)[0]">
              <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('profileValidation.NEW_PASSWORD_REQUIRED') }}</mat-error>
              <mat-error *ngSwitchCase="'pattern'" class="help-block">{{ validationMessage.displayErrorMessage('profileValidation.NEW_PASSWORD_PATTERN') }}</mat-error>
              <mat-error *ngSwitchCase="'minlength'" class="help-block">{{ validationMessage.displayErrorMessage('profileValidation.NEW_PASSWORD_MINLENGTH') }}</mat-error>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15PX">
        <div class="mat-input-group" fxFlex="50">
          <mat-form-field>
            <input matInput class="" #email formControlName="email" name="email" type="text" value="" placeholder="{{ 'user.email' | translate }}"
              maxlength="40">
          </mat-form-field>
          <div *ngIf="userForm.controls['email'].errors && userForm.controls['email'].dirty" class="error-msg has-error">
            <div [ngSwitch]="obj(userForm.controls['email'].errors)[0]">
              <!-- <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('commonValidation.EMAIL_REQUIRED') }}</mat-error> -->
              <mat-error *ngSwitchCase="'validateEmail'" class="help-block">{{ validationMessage.displayErrorMessage('commonValidation.EMAIL_VALID') }}</mat-error>
              <mat-error *ngSwitchCase="'maxlength'" class="help-block">{{ validationMessage.displayErrorMessage('commonValidation.EMAIL_LENGTH') }}</mat-error>
            </div>
          </div>
        </div>
        <div class="mat-input-group" fxFlex="50">
          <mat-form-field>
            <input matInput type="text" placeholder="{{ 'user.phoneNumber' | translate }}" #mobileNo formControlName="mobileNo"
              maxlength="10">
          </mat-form-field>
          <div *ngIf="userForm.controls['mobileNo'].errors && userForm.controls['mobileNo'].dirty" class="error-msg has-error">
            <div [ngSwitch]="obj(userForm.controls['mobileNo'].errors)[0]">
              <!-- <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('commonValidation.PHONE_NO_REQUIRED') }}</mat-error> -->
              <mat-error *ngSwitchCase="'pattern'" class="help-block">{{ validationMessage.displayErrorMessage('commonValidation.PHONE_NO_PATTERN') }}</mat-error>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15PX">
        <div class="mat-input-group" fxFlex="50">
          <mat-checkbox formControlName="disableBillAmount">Disable Bill Amount</mat-checkbox>
        </div>
        <div class="mat-input-group" fxFlex="50">
          <mat-checkbox formControlName="disableBillNo">Disable Bill No</mat-checkbox>
        </div>
      </div>
  </form>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="submitUserType()" [disabled]="userForm.invalid">
      <i class="far fa-trash-alt"></i> {{ 'common.submit' | translate }}</button>
    <button mat-dialog-close mat-icon-button class="close-btn">
      <i class="material-icons">cancel</i>
    </button>
  </div>