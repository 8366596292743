<h1 mat-dialog-title>Verify Password To Export</h1>
<form
  #verifyPasswordFormModal="ngForm"
  (ngSubmit)="verifyPasswordFormModal.form.valid && verifyPassword()"
  novalidate
>
  <div mat-dialog-content>
    <div class="mat-input-group">
      <mat-form-field>
        <input
          matInput
          #passwordInputModal="ngModel"
          type="password"
          name="passwordInput"
          [(ngModel)]="password"
          placeholder="Enter Password"
          required
        />
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button type="submit" mat-raised-button color="primary">
      <i class="far fa-trash-alt"></i>
      {{ "common.submit" | translate }}
    </button>
    <button mat-dialog-close mat-icon-button class="close-btn">
      <i class="material-icons">cancel</i>
    </button>
  </div>
</form>
