<div fxLayout="row wrap" fxLayout.xs="column" class="mt-10">
  <div fxFlex fxFlex.sm="50">
      <!--service card-->
      <mat-card class="service-data-detail">
          <mat-card-header class="lt-purple">
              <div mat-card-avatar>
                      <i class="material-icons">question_answer</i>
              </div>
              <mat-card-title>REVIEW DETAIL</mat-card-title>
          </mat-card-header>
          <mat-card-content>
              <mat-list>
                  <mat-list-item>
                      <div class="data-det-title">Total Review</div>
                      <div class="data-det-count">+{{dashboardData?dashboardData.reviewDetail.totalReviewPositive: 0}}&nbsp;&nbsp;  -{{dashboardData?dashboardData.reviewDetail.totalReviewNegative: 0}}</div>
                  </mat-list-item>
                
                  <mat-list-item>
                      <div class="data-det-title">Today Review</div>
                      <div class="data-det-count">+{{dashboardData?dashboardData.reviewDetail.todayReviewPositive: 0}}&nbsp;&nbsp;  -{{dashboardData?dashboardData.reviewDetail.todayReviewNegative: 0}}</div>
                  </mat-list-item>
                 
                  <mat-list-item>
                      <div class="data-det-title">Yesterday Review</div>
                      <div class="data-det-count">+{{dashboardData?dashboardData.reviewDetail.yesterdayReviewPositive: 0}}&nbsp;&nbsp;  -{{dashboardData?dashboardData.reviewDetail.yesterdayReviewNegative: 0}}</div>
                  </mat-list-item>
                 
                  <mat-list-item>
                          <div class="data-det-title">For This Month</div>
                          <div class="data-det-count">+{{dashboardData?dashboardData.reviewDetail.monthReviewPositive: 0}}&nbsp;&nbsp;  -{{dashboardData?dashboardData.reviewDetail.monthReviewNegative: 0}}</div>
                      </mat-list-item>
              </mat-list>
          </mat-card-content>
      </mat-card>
      <!--service card end-->
      <!--service card-->
      <mat-card class="service-data-detail">
              <mat-card-header class="lt-grey">
                  <div mat-card-avatar>
                      <i class="material-icons">supervised_user_circle</i>
                  </div>
                  <mat-card-title>Customer Detail</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                  <mat-list>
                      <mat-list-item>
                          <div class="data-det-title">Yesterday Customer</div>
                          <div class="data-det-count">{{dashboardData?dashboardData.customerDetail.yesterdayCustomer: 0}}</div>
                      </mat-list-item>
                   <!--    <mat-divider></mat-divider> -->
                      <mat-list-item>
                          <div class="data-det-title">Today Customer</div>
                          <div class="data-det-count">{{dashboardData?dashboardData.customerDetail.todayCustomer: 0}}</div>
                      </mat-list-item>
                  <!--     <mat-divider></mat-divider> -->
                      <mat-list-item>
                          <div class="data-det-title">For This Month</div>
                          <div class="data-det-count">{{dashboardData?dashboardData.customerDetail.monthCustomer: 0}}</div>
                      </mat-list-item>
                  </mat-list>
              </mat-card-content>
          </mat-card>
          <!--service card end-->
           <!--service card-->
      <mat-card class="service-data-detail">
              <mat-card-header class="lt-sky">
                  <div mat-card-avatar>
                      <i class="material-icons">assessment </i>
                  </div>
                  <mat-card-title>Balance Statics</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                  <mat-list>
                      <mat-list-item>
                          <div class="data-det-title">Promotion SMS</div>
                          <div class="data-det-count">{{dashboardData?dashboardData.smsBalance.promotionSMS: 0}}</div>
                      </mat-list-item>
                    <!--   <mat-divider></mat-divider> -->
                      <mat-list-item>
                          <div class="data-det-title">Promotion Email</div>
                          <div class="data-det-count">{{dashboardData?dashboardData.smsBalance.promotionEmail: 0}}</div>
                      </mat-list-item>
                     <!--  <mat-divider></mat-divider> -->
                      <mat-list-item>
                          <div class="data-det-title">Expire Account Date</div>
                          <div class="data-det-count">{{dashboardData?dashboardData.smsBalance.expireAccountDate: 0}}</div>
                      </mat-list-item>
                  </mat-list>
              </mat-card-content>
      </mat-card>
          <!--service card end-->

          <mat-card class="service-data-detail">
            <mat-card-header class="lt-sky">
                <div mat-card-avatar>
                    <i class="material-icons">supervisor_account</i>
                </div>
                <mat-card-title>Anniversary</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-list *ngIf="dashboardData">
                    <mat-list-item *ngFor = "let customer of dashboardData.customerAnniversoryList.data">
                        <div class="data-det-title">{{customer.customerName}}</div>  
                        <div>{{customer?.contactNo}}</div>
                        <div>&nbsp;&nbsp;{{customer.doa}}</div>  
                    </mat-list-item>
                    <mat-divider></mat-divider>

                </mat-list>
            </mat-card-content>
    </mat-card>
  </div>
  <div fxFlex fxFlex.sm="50">
      <!--service card-->
          <mat-card class="service-data-detail">
                  <mat-card-header class="lt-grey">
                      <div mat-card-avatar>
                          <i class="material-icons">local_post_office</i>
                      </div>
                      <mat-card-title>POST DETAIL</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                      <mat-list>
                          <mat-list-item>
                              <div class="data-det-title">Total Post</div>
                              <div class="data-det-count">{{dashboardData?dashboardData.smsEmailPostSend.totalPostSend: 0}}</div>
                          </mat-list-item>
                        <!--   <mat-divider></mat-divider> -->
                          <mat-list-item>
                              <div class="data-det-title">Today Post</div>
                              <div class="data-det-count">{{dashboardData?dashboardData.smsEmailPostSend.todayPostSend: 0}}</div>
                          </mat-list-item>
                         <!--  <mat-divider></mat-divider> -->
                          <mat-list-item>
                              <div class="data-det-title">Yesterday Post</div>
                              <div class="data-det-count">{{dashboardData?dashboardData.smsEmailPostSend.yesterdayPostSend: 0}}</div>
                          </mat-list-item>
                         <!--  <mat-divider></mat-divider> -->
                          <mat-list-item>
                                  <div class="data-det-title">This Month Post</div>
                                  <div class="data-det-count">{{dashboardData?dashboardData.smsEmailPostSend.thisMonthPostSend: 0}}</div>
                              </mat-list-item>
                      </mat-list>
                  </mat-card-content>
          </mat-card>
      <!--service card end-->
      <!--service card-->
      <mat-card class="service-data-detail">
              <mat-card-header class="lt-sky">
                  <div mat-card-avatar>
                      <i class="material-icons">local_post_office</i>
                  </div>
                  <mat-card-title>SMS SEND</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                  <mat-list>
                      <mat-list-item>
                          <div class="data-det-title">Total SMS Send</div>
                          <div class="data-det-count">{{dashboardData?dashboardData.smsEmailPostSend.totalSMSSend: 0}}</div>
                      </mat-list-item>
                      <!-- <mat-divider></mat-divider> -->
                      <mat-list-item>
                          <div class="data-det-title">Today Send</div>
                          <div class="data-det-count">{{dashboardData?dashboardData.smsEmailPostSend.todaySMSSend: 0}}</div>
                      </mat-list-item>
                    <!--   <mat-divider></mat-divider> -->
                      <mat-list-item>
                          <div class="data-det-title">Yesterday Send</div>
                          <div class="data-det-count">{{dashboardData?dashboardData.smsEmailPostSend.yesterdaySMSSend: 0}}</div>
                      </mat-list-item>
                      <!-- <mat-divider></mat-divider> -->
                      <mat-list-item>
                              <div class="data-det-title">This Month Post</div>
                              <div class="data-det-count">{{dashboardData?dashboardData.smsEmailPostSend.thisMonthSMSSend: 0}}</div>
                          </mat-list-item>
                  </mat-list>
              </mat-card-content>
      </mat-card>
  <!--service card end-->
   <!--service card-->
   <mat-card class="service-data-detail">
          <mat-card-header class="lt-grey">
              <div mat-card-avatar>
                      <i class="material-icons">alternate_email</i>
              </div>
              <mat-card-title>EMAIL SEND</mat-card-title>
          </mat-card-header>
          <mat-card-content>
              <mat-list>
                  <mat-list-item>
                      <div class="data-det-title">Total Email Send</div>
                      <div class="data-det-count">{{dashboardData?dashboardData.smsEmailPostSend.totalEmailSend: 0}}</div>
                  </mat-list-item>
                 <!--  <mat-divider></mat-divider> -->
                  <mat-list-item>
                      <div class="data-det-title">Today Send</div>
                      <div class="data-det-count">{{dashboardData?dashboardData.smsEmailPostSend.todayEmailSend: 0}}</div>
                  </mat-list-item>
                 <!--  <mat-divider></mat-divider> -->
                  <mat-list-item>
                      <div class="data-det-title">Yesterday Send</div>
                      <div class="data-det-count">{{dashboardData?dashboardData.smsEmailPostSend.yesterdayEmailSend: 0}}</div>
                  </mat-list-item>
                <!--   <mat-divider></mat-divider> -->
                  <mat-list-item>
                          <div class="data-det-title">This Month Post</div>
                          <div class="data-det-count">{{dashboardData?dashboardData.smsEmailPostSend.thisMonthEmailSend: 0}}</div>
                      </mat-list-item>
              </mat-list>
          </mat-card-content>
  </mat-card>

  <mat-card class="service-data-detail">
    <mat-card-header class="lt-sky">
        <div mat-card-avatar>
            <i class="material-icons">supervisor_account</i>
        </div>
        <mat-card-title>Birthday</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-list *ngIf="dashboardData">
            <mat-list-item *ngFor = "let customer of dashboardData.customerBirthdayList.data">
                <div class="data-det-title">{{customer.customerName}}</div>
                <div> {{customer?.contactNo}}</div>
                <div>&nbsp;&nbsp; {{customer.dob}}</div>                 
               
            </mat-list-item>
            <mat-divider></mat-divider>
            
        </mat-list>
        
    </mat-card-content>
    <mat-card-actions class="viewdetail" >
        <mat-icon title="update promotional block" [routerLink]="['/customerdashboardetail',]">
            open_in_full</mat-icon>
        </mat-card-actions>
</mat-card>
<!--service card end-->
  </div>
  <div fxFlex fxFlex.sm="50">
      <!--service card-->
          <mat-card class="service-data-detail">
                  <mat-card-header class="lt-sky">
                      <div mat-card-avatar>
                          <i class="material-icons">rate_review</i>
                      </div>
                      <mat-card-title>LATEST REVIEW</mat-card-title>
                  </mat-card-header>
                  <mat-card-content >
                      <mat-list *ngIf="dashboardData">
                          <mat-list-item *ngFor = "let list of dashboardData.latestReviewList.data">
                              <div class="data-det-title">{{list.customerName}}</div>
                              <div class="data-det-count">{{list.avgRating}}</div>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                      </mat-list>
                  </mat-card-content>
          </mat-card>
      <!--service card-->
      <!--service card-->
      <mat-card class="service-data-detail">
              <mat-card-header class="lt-sky">
                  <div mat-card-avatar>
                      <i class="material-icons">supervisor_account</i>
                  </div>
                  <mat-card-title>Customers &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; <span style="font-size:15px;">last visit</span></mat-card-title>
              </mat-card-header>
              <mat-card-content>
                  <mat-list *ngIf="dashboardData">
                      <mat-list-item *ngFor = "let customer of dashboardData.customerList.data">
                          <div class="data-det-title">{{customer.customername}}</div>  
                          <div>{{customer.lastVisitedOn}}</div>  
                      </mat-list-item>
                      <mat-divider></mat-divider>

                  </mat-list>
              </mat-card-content>
      </mat-card>
  <!--service card-->
  </div>
</div>