export const STORAGE_KEYS = {
    SESSION: '_revugain_session',
    REMEMBER_ME: 'remember_me_check',
    USER_NAME: 'user_name',
    ASK_POPUP_SMS: 'askPopup_SMS',
    PASSWORD: 'password',
    CODE: 'code',
    ROLE: 'userType',
    CHAIN_SESSION: '_session_chaindist',
    PERMISSION_SESSION: '_session_permissionsdist',
    SOCIAL_MEDIA_PERMISSION: '_social_media_permission',
    DISTRIBUTOR_ID: 'distId',
    DISTCENTER_ID: 'distCenterId',
    CURRENT_USER_DATA: 'currentUserData',
    TOTAL_COUNT: 'item-count',
    TWILIO_SESSION: '_chat_session',
    ORG_ID: '_orgId'
};

export const RESPONSE_KEYS = {
    DATAKEY: 'data',
    TOKENKEY: 'token',
    JELLYFISH_ID: 'id',
    TOTAL_COUNT: 'x-total-count',
    STATUS: 'status',
    MESSAGE: 'message',
    ERRORKEY: 'errors',
    USERFROMDIFFERENTPORTAL: 40123
};
