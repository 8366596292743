<div class="header">
    <div class="name" trans>Google Fonts</div>
    <button class="close-button no-style" (click)="overlayPanelRef.close()">
        <svg-icon name="close"></svg-icon>
    </button>
</div>

<form class="many-inputs" [formGroup]="fonts.paginator.filters">
    <div class="input-container search">
        <input type="search" placeholder="Search..." formControlName="search" trans-placeholder>
    </div>

    <div class="input-container category">
        <select formControlName="category">
            <option [value]="item.key" *ngFor="let item of fontCategories | mapToIterable" trans>{{item.value}}</option>
        </select>
    </div>
</form>

<div class="fonts">
    <button class="no-style font" *ngFor="let font of fonts.paginator.current$ | async" [style.fontFamily]="font.family" (click)="applyFont(font)">{{font.family}}</button>
</div>

<div class="pagination">
    <button class="no-style previous-page" (click)="fonts.paginator.previous()" matTooltip="Previous Page">
        <svg-icon name="keyboard-arrow-left"></svg-icon>
    </button>

    <button class="no-style next-page" (click)="fonts.paginator.next()" matTooltip="Next Page">
        <svg-icon name="keyboard-arrow-right"></svg-icon>
    </button>
</div>
