import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-export-files',
  templateUrl: './export-files.component.html',
  styleUrls: ['./export-files.component.scss']
})
export class ExportFilesComponent implements OnInit {
  @Output() clickEvent = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  /**
   * @description method to get search details and give as output.
   */
  exportItems(param): void {
    const value = true;
    this.clickEvent.emit({param, value});
  }

}
