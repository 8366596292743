import { Injectable, Inject} from '@angular/core';
import {FileValidator} from '../../editor-tools/core/files/file-validator';
import {HistoryToolService} from '../history/history-tool.service';
import {CanvasService} from '../canvas/canvas.service';
import {Toast} from '../../editor-tools/core/ui/toast.service';
import {Uploads} from '../../editor-tools/core/files/uploads.service';
import {Settings} from '../../editor-tools/core/config/settings.service';
import {FrameToolService} from './frame/frame-tool.service';
import {CropZoneService} from './crop/crop-zone.service';
import {Image} from 'fabric';
import { TextToolService } from './text/text-tool.service';
import { ActiveObjectService } from '../canvas/active-object.service';
import { ISocialMediaService } from '../../../../iservices/ISocialMediaServices/iSocialMedia';
import { fabric } from 'fabric';
import { Object as FObject } from 'fabric';
@Injectable()
export class ImportToolService {

    /**
     * ExportToolService Constructor.
     */
    constructor(
        private validator: FileValidator,
        private history: HistoryToolService,
        private canvas: CanvasService,
        private toast: Toast,
        private uploads: Uploads,
        private config: Settings,
        private frame: FrameToolService,
        private cropzone: CropZoneService,
        public textTool: TextToolService,
        public objectService: ActiveObjectService,
        @Inject('ISocialMediaService') private editorService: ISocialMediaService
    ) {
        console.log(this.config.get('pixie.tools.import.validExtensions'));
        this.validator.rules.whitelist = this.config.get('pixie.tools.import.validExtensions');
    }

    /**
     * Open upload dialog, import selected file and open it in editor.
     */
    public openUploadDialog(options: {type?: 'image'|'state', backgroundImage?: boolean} = {type: 'image'}): Promise<any> {
        const accept = this.getUploadAcceptString(options.type);
        return new Promise(resolve => {
            this.uploads.openUploadDialog({accept}).then(files => {
                this.validateAndGetData(files[0]).then(file => {
                    if (options.backgroundImage && file.extension !== 'json') {
                        this.openBackgroundImage(file.data).then(obj => {
                            this.addPropertyData();
                            resolve(obj); });
                    } else {
                        this.openFile(file.data, file.extension).then(obj => resolve(obj));
                    }
                });
            });
        });
    }

    addPropertyData = () => {
        this.editorService.getPropertyData().subscribe(res => {
            const width = this.canvas.fabric().getWidth();
            const height = this.canvas.fabric().getHeight();
            // const center = this.canvas.fabric().getCenter();
            // const boundary = this.canvas.fabric().calcViewportBoundaries();
            if (res.data[0].address) {
                this.textTool.add(res.data[0].address, 20,
                    { top: (116 * height) / 100, left: (30 * width) / 100 });
                this.objectService.deselect();
            }
            if (res.data[0].email) {
                this.textTool.add(res.data[0].email, 20,
                    { top: (112 * height) / 100, left: (40 * width) / 100 });
            }
            if (res.data[0].contactNo) {
                this.textTool.add(res.data[0].contactNo, 20,
                     { top: (108 * height) / 100, left: (50 * width) / 100 });
            }
            if (res.data[0].imagePath) {
                fabric.Image.fromURL(res.data[0].imagePath,
                    img => {
                        this.addAndPositionSticker(img, 'sticker',
                            { top: 0, left: (105 * width) / 100 });
                    }, { crossOrigin: 'Annoymous' });
            }
        });
    }

    private addAndPositionSticker(sticker: FObject, name: string = 'shape', position?) {
        sticker.name = name;
        const img = new fabric.Image();
        this.canvas.fabric().add(sticker);
        this.canvas.fabric().setActiveObject(sticker);
        // sticker.scaleToWidth(this.canvas.fabric().getWidth() / 4);
        // sticker.viewportCenter();
        // sticker.setCoords();
        sticker.set('top', position.top);
        sticker.set('left', position.left);
        this.canvas.render();
        this.objectService.deselect();
    }

    /**
     * Open upload dialog, import file and return files data.
     */
    public importAndGetData(): Promise<string> {
        return new Promise(resolve => {
            this.uploads.openUploadDialog().then(files => {
                this.validateAndGetData(files[0]).then(file => resolve(file.data));
            });
        });
    }

    /**
     * File specified file and if it passes, return files data.
     */
    public validateAndGetData(file: File): Promise<{ data: string, extension: string }> {
        const errors = this.validator.validateFile(file),
            extension = FileValidator.getFileNameAndExtension(file.name).extension;

        return new Promise((resolve, reject) => {
            if (errors) {
                reject();
                return this.toast.open('Unsupported file type.');
            }

            this.readFile(file, extension).then(data => resolve({data, extension}));
        });
    }

    /**
     * Load specified pixie state data into editor.
     */
    public openStateFile(data: string): Promise<any> {
        return this.resetEditor().then(() => {
            return this.history.addFromJson(data);
        });
    }

    public resetEditor(): Promise<any> {
        this.canvas.fabric().clear();
        this.frame.remove();
        this.cropzone.remove();
        this.history.clear();
        return new Promise(resolve => setTimeout(() => resolve(null)));
    }

    /**
     * Open specified data or image element in editor.
     */
    public openFile(data: string|HTMLImageElement, extension: string = 'png'): Promise<Image|void> {
        if (data instanceof HTMLImageElement) {data = data.src; }

        if (extension === 'json') {
            return this.openStateFile(data);
        } else {
            return this.canvas.openImage(data);
        }
    }

    /**
     * Open specified data or image as background image.
     */
    public openBackgroundImage(data: string|HTMLImageElement): Promise<Image> {
        if (data instanceof HTMLImageElement) {data = data.src; }

        const mainImage = this.canvas.getMainImage();

        if (mainImage) {
            return this.canvas.changeMainImage(data);
        } else {
            return this.canvas.loadMainImage(data);
        }
    }

    /**
     * Read specified file and get its data.
     */
    private readFile(file: File, extension: string): any {
        const reader = new FileReader();

        return new Promise(resolve => {
            reader.addEventListener('load', () => {
                resolve(reader.result);
            });

            if (extension === 'json') {
                reader.readAsText(file);
            } else {
                reader.readAsDataURL(file);
            }
        });
    }

    private getUploadAcceptString(type: 'image'|'state'|'all' = 'all'): string {
        switch (type) {
            case 'image':
                return 'image/*';
            case 'state':
                return '.json,application/json';
            case 'all':
            default:
                return 'image/*,.json,application/json';
        }
    }
}
