import { API_ENDPOINTS } from './../../../../services/api-endpoints-constants';
import { ModelDecoderBindingService } from './../../../../services/bindingDecoder.model.service';
import { ModelBindingService } from './../../../../services/binding.model.service';
import { ICommonService } from './../../../../iservices/iCommon';
import { IApiService } from './../../../../iservices/iApi';
import { IApiBaseObject } from './../../../../iModels/api-base-object.model';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RepeatGuestService {
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  private apiObject: IApiBaseObject;
  constructor( @Inject('IApiService') private apiService: IApiService,
  @Inject('ICommonService') private commonService: ICommonService,
    private modelBindingService: ModelBindingService,
    public dialog: MatDialog,
    private modelDecoderBindingService: ModelDecoderBindingService) {
  }

  getRepeatGuestSummary(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.ReportRepeatedGuestSummary, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp: any) => {
        if (resp) {
          if (!_.isEmpty(resp.data)) {
            json = { data: resp.data, 'itemCount': resp.data[0].totalRows };
          } else {
            json = { data: resp.data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }

  getRepeatGuestDetailSummary(apiGetParameters) {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.ReportRepeatedGuestDetails, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp: any) => {
        if (resp) {
          if (!_.isEmpty(resp.data)) {
            json = { data: resp.data, 'itemCount': resp.data[0].totalRows };
          } else {
            json = { data: resp.data, 'itemCount': 0 };
          }
          return json;
        }
      }
      ));
  }
}
