import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { IDepartmentQueService } from '../../iservices/iMasterServices/iDepartmentQue';
import { MatDialog } from '@angular/material/dialog';
import {
  AddDepartmentQueMasterComponent
} from '../../component/master/masterComponents/department-que-master/add-department-que-master/add-department-que-master.component';

@Injectable({
  providedIn: 'root'
})
export class DepartmentQueMasterService implements IDepartmentQueService {
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  private apiObject: IApiBaseObject;
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService,
    public dialog: MatDialog) {

  }

  /*
    * @method getDepartmentQueDetails()
    * @desc used to get Department question list .
    * @return json array data.
  */
  getDepartmentQueDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.DEPARTMENT_QUESTION_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService
            .mappingDataReturn('departmentQueDecoder', resp);
            if (resp['data'].data) {
              json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
            } else {
              json = { data: resp['data'].data, 'itemCount': 0 };
            }
            return json;
        }
      }
      ));
  }
  /*
    * @method addDepartment()
    * @desc used to add Department Type.
    * @param permData :array - Department data.
    * @return json array data.
  */
  addDepartmentQue(permData): any {
    const requestData = this.modelBindingService.departmentQueEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.DEPARTMENT_QUESTION_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
  * @method getDepartmentQueDetailsById()
  * @desc used to get department  details by id.
  * @param id :string - department  id.
  * @return json array data.
*/
  getDepartmentQueDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.DEPARTMENT_QUESTION_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService.departmentQueDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }
  /*
   * @method delete()
   * @desc used to department  row data.
   * @param id :array - department  id .
   * @return string.
  */
  delete(id): string {
    const url = this.modelBindingService.replaceUrlForDelete(
      API_ENDPOINTS.DEPARTMENT_QUESTION_DELETE, id);
      this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }
}
