<div class="pagination-wrap" [ngClass] = "{'pagination' : sortShow}">
  <div class="toolbar" [ngClass] = "{'pagination' : sortShow}">
    <div class="sorter">
      <div class="sort-by-wrap">
        <span class="sort-by-active" (click)="openPagerDropdown()">{{ size }}
          <i class="material-icons">arrow_drop_down</i>
        </span>
        <mat-list #list *ngIf="sortShow" class="short-value">
            <mat-list-item *ngFor="let size of pageSizeArray; trackBy:trackByFn;" (click)="hidePagerDropdown()">
                <a href="javascript:void(0)" (click)="getPager(size)">{{ size }}</a>
            </mat-list-item>
            </mat-list>
      </div>
      <span class="record-count" [ngClass] = "{'pagination' : sortShow}">{{ 'commonTablePagination.recordCount' | translate:{max: getMax(),min: getMin(),totalItems: totalItems} }}</span>
    </div>
    <div class="pager">
      <mat-list class="pager-ul-list">
          <mat-list-item>
            <a (click)="onPage(1)" [ngClass]="{ 'disabled': currentPage == startPage, 'active': currentPage !== startPage }">
              <i [ngClass] = "{'pagination' : sortShow}" class="icon-arrow-double-left"></i>
            </a>
          </mat-list-item>
          <mat-list-item>
            <a (click)="onPrev()" [ngClass]="{ 'disabled': currentPage == startPage, 'active': currentPage !== startPage }">
                <i [ngClass] = "{'pagination' : sortShow}" class="material-icons">arrow_left</i>
            </a>
          </mat-list-item>
          <mat-list-item>
            <mat-list>
              <mat-list-item  class="page-number">
                <a *ngFor="let page of pagesArray; trackBy:trackByFn;" (click)="onPage(page)" [ngClass]="{active:currentPage === page, 'pagination' : sortShow}" class="active">{{ page }}</a>
              </mat-list-item>
            </mat-list>
          </mat-list-item>
          <mat-list-item>
              <a (click)="onNext()" [ngClass]="{ 'disabled': currentPage == endPage, 'active': currentPage !== endPage }">
                  <i [ngClass] = "{'pagination' : sortShow}" class="material-icons">arrow_right</i>
                </a>
          </mat-list-item>
          <mat-list-item>
              <a (click)="onPage(ceil(totalItems/size))" [ngClass]="{ 'disabled': currentPage == endPage, 'active': currentPage !== endPage }">
                <i [ngClass] = "{'pagination' : sortShow}" class="icon-arrow-double-right"></i>
              </a>
          </mat-list-item>
        </mat-list>
    </div>

  </div>
</div>