import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ResponseMessage {
  constructor(private translate: TranslateService) {
  }
  /**
   * @method displayErrorMessage(param)
   * @desc RETURN ERROR MESSAGES USING ERRORCODE
   * @param errorCode :string - ERRORCODE
   */
  displayErrorMessage(errorCode: string): any {
    // const newErrorArray = responsemessages.ERRORCODE[errorCode] ?
    //   responsemessages.ERRORCODE[errorCode] :
    //   responsemessages.ERRORCODE['ERRORDEFAULTCODE'];
    let newErrorArray;
    this.translate.get('errorCode.' + errorCode).subscribe(
      (value) => {
        newErrorArray = value;
      }
    );
    if (newErrorArray === 'errorCode.' + errorCode) {
      this.translate.get('errorCode.' + 'ERRORDEFAULTCODE').subscribe(
        (value) => {
          newErrorArray = value;
        }
      );
    }
    return newErrorArray;
  }
  /**
   * @method displaySuccessMessage(param)
   * @desc RETURN SUCCESS MESSAGES USING SUCCESSCODE
   * @param successCode :string - SUCCESSCODE
   */
  displaySuccessMessage(successCode: string): any {
    // const newSuccesArray = responsemessages.SUCCESSCODE[successCode] ?
    //   responsemessages.SUCCESSCODE[successCode] :
    //   responsemessages.SUCCESSCODE['SUCCESSDEFAULTCODE'];
    let newSuccesArray;
    this.translate.get('successCode.' + successCode).subscribe(
      (value) => {
        newSuccesArray = value;
      }
    );
    if (newSuccesArray === 'successCode.' + successCode) {
      this.translate.get('successCode.' + 'SUCCESSDEFAULTCODE').subscribe(
        (value) => {
          newSuccesArray = value;
        }
      );
    }
    return newSuccesArray;
  }
}
