<app-pagination
  *ngIf="responseDataTable.length && showPagination"
  [totalItems]="totalItemCount"
  [pageSizeArrayFromComponent]="tableSettings.options.pageSizeArray"
  [searchData]="tableSettings.searchData"
  (goPrev)="goPrev($event)"
  (goNext)="goNext($event)"
  (goPage)="goToPage($event)"
  (setPageSize)="setPageSize($event)"
>
</app-pagination>
<div class="table-responsive" [id]="tableSettings?.id">
  <table class="table mat-default-table" >
    <thead>
      <tr>
        <th
          *ngFor="
            let header of tableSettings.tableHeader;
            let h = index;
            trackBy: trackByFn
          "
        >
          <a
            *ngIf="header"
            class="anchor-color"
            (click)="
              filterDataByOrder(
                tableSettings?.mapping[h]?.allowOrderByFilter,
                filtered[h],
                h
              )
            "
            >{{ header }}
            <i
              *ngIf="tableSettings?.mapping[h]?.allowOrderByFilter && !filtered[h]"
              class="material-icons icon-color-violet"
            >
              keyboard_arrow_up
            </i>
            <i
            *ngIf="tableSettings?.mapping[h]?.allowOrderByFilter && filtered[h]"
            class="material-icons icon-color-violet"
          >
            keyboard_arrow_down
          </i>
            </a
          >
          <div *ngIf="!header">
            <mat-checkbox
              [(ngModel)]="isAllChecked"
              [checked]="isAllChecked"
              (change)="masterToggel()"
            ></mat-checkbox>
          </div>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="!responseDataTable.length">
      <tr>
        <td [colSpan]="tableSettings.tableHeader.length">
          {{ "commonTable.noRecord" | translate }}
        </td>
      </tr>
    </tbody>
    <tbody ng-if="responseDataTable.length">
      <!-- {{responseDataTable|json}} -->
      <tr
        *ngFor="
          let rowData of responseDataTable;
          let row = index;
          trackBy: trackByFn
        "
      >
        <td
          *ngFor="let colData of rowData; let i = index; trackBy: trackByFn"
          [ngSwitch]="colData.type"
        >
          <a
            [routerLink]="colData.urlValue"
            *ngSwitchCase="'anchor'"
            class="{{ colData.class }}"
            (click)="setTitle(colData.dataValue)"
            >{{ colData.dataValue }}</a
          >
          <div *ngSwitchCase="'states'" class="states {{ colData.class }}">
            <div *ngIf="showMore != row">
              <ng-container
                *ngFor="
                  let state of colData.dataValue;
                  let input = index;
                  trackBy: trackByFn
                "
              >
                <span *ngIf="input < 2"
                  >{{ state.name
                  }}{{
                    colData.dataValue.length - 1 > input && 1 > input
                      ? ", "
                      : ""
                  }}</span
                >
              </ng-container>
              <a
                *ngIf="colData.dataValue.length > 2"
                class="read-more-less-click"
                (click)="showMore = row"
                >{{ colData.dataValue.length - 2 }} {{ "More" }}</a
              >
            </div>
          </div>
          <div *ngSwitchCase="'states'" class="{{ colData.class }}states">
            <div *ngIf="showMore === row">
              <ng-container
                *ngFor="
                  let state of colData.dataValue;
                  let input = index;
                  trackBy: trackByFn
                "
              >
                <span
                  >{{ state.name
                  }}{{ colData.dataValue.length - 1 > input ? ", " : "" }}</span
                >
              </ng-container>
              <a
                *ngIf="colData.dataValue.length > 2"
                class="read-more-less-click"
                (click)="showMore = null"
                >{{ "Read less" }}</a
              >
            </div>
          </div>

          <div
            *ngSwitchCase="'salesDivision'"
            class="states {{ colData.class }}"
          >
            <div *ngIf="showMore != row && colData.dataValue.length">
              <ng-container
                *ngFor="
                  let division of colData.dataValue;
                  let input = index;
                  trackBy: trackByFn
                "
              >
                <span *ngIf="input < 2"
                  >{{ division.divisionName
                  }}{{
                    colData.dataValue.length - 1 > input && 1 > input
                      ? ", "
                      : ""
                  }}</span
                >
              </ng-container>
              <a
                *ngIf="colData.dataValue.length > 2"
                class="read-more-less-click"
                (click)="showMore = row"
                >{{ colData.dataValue.length - 2 }} {{ "More" }}</a
              >
            </div>
          </div>
          <div
            *ngSwitchCase="'salesDivision'"
            class="{{ colData.class }}states"
          >
            <div *ngIf="showMore === row && colData.dataValue.length">
              <ng-container
                *ngFor="
                  let division of colData.dataValue;
                  let input = index;
                  trackBy: trackByFn
                "
              >
                <span
                  >{{ division.divisionName
                  }}{{ colData.dataValue.length - 1 > input ? ", " : "" }}</span
                >
              </ng-container>
              <a
                *ngIf="colData.dataValue.length > 2"
                class="read-more-less-click"
                (click)="showMore = null"
                >{{ "Read less" }}</a
              >
            </div>
          </div>
          <span
            *ngSwitchCase="'span'"
            class="{{ colData.class }}"
            [class.active]="colData.dataValue == true"
            [class.inactive]="colData.dataValue == false"
          >
            {{ colData.dataValue == true ? "Active" : "Inactive" }}
          </span>
          <!-- <a *ngSwitchCase="'editPage'" [routerLink]="colData.urlValue" class="view-icon">
                <span class="icon-eye_show"></span>
              </a> -->
          <span
            *ngSwitchCase="'orderStatus'"
            class="{{ colData.class }}"
            [class.warning]="colData.dataValue == 'SUBMITTED'"
            [class.shipped]="colData.dataValue == 'SHIPPED'"
            [class.complete]="colData.dataValue == 'COMPLETED'"
            [class.success]="colData.dataValue == 'APPROVED'"
            [class.danger]="colData.dataValue == 'CANCELLED'"
            >{{ colData.dataValue }}</span
          >

          <a
            *ngSwitchCase="'reps'"
            (click)="openReps(colData)"
            class="view-icon"
          >
            <span class="icon-eye_show"></span>
          </a>
          <!-- <a *ngSwitchCase="'editPage'" (click)="openModal(colData)" class="{{ colData.class }}">
                <span>Delete</span>
              </a> -->
          <a
            *ngSwitchCase="'editIcon'"
            [routerLink]="colData.urlValue"
            class="{{ colData.class }}"
          >
            <span>edit</span>
          </a>

          <span
            *ngSwitchCase="'anchor1'"
            [routerLink]="colData.urlValue"
            class="{{ colData.class }}"
            (click)="openAddDialog(colData.dataValue1, 'forId')"
            >{{ colData.dataValue }}</span
          >
          <button
            mat-stroked-button
            *ngSwitchCase="'button'"
            [routerLink]="colData.urlValue"
            ngClass="{{ colData.class }}"
          >
            {{ colData.buttonType }}
          </button>
          <button
            *ngSwitchCase="'quote'"
            mat-stroked-button
            color="secondary"
            (click)="openAddDialog(colData.dataValue, 'notpopup')"
            ngClass="{{ colData.class1 }}"
          >
            <i class="material-icons">remove_red_eye</i>
            {{ colData.buttonType1 }}
          </button>
          <button
            *ngSwitchCase="'quote'"
            mat-stroked-button
            color="primary"
            (click)="openAddDialog(colData.dataValue, 'popup')"
            ngClass="{{ colData.class }}"
          >
            <i class="material-icons">add_circle_outline </i>
            {{ colData.buttonType }}
          </button>
          <button
            *ngSwitchCase="'image'"
            mat-stroked-button
            color="secondary"
            (click)="openAddDialog(colData.dataValue, 'viewImage')"
            ngClass="{{ colData.class1 }}"
          >
            <i class="material-icons">remove_red_eye</i>
            {{ colData.buttonType1 }}
          </button>
          <button
            *ngSwitchCase="'image'"
            mat-stroked-button
            color="primary"
            (click)="openAddDialog(colData.dataValue, 'addImage')"
            ngClass="{{ colData.class }}"
          >
            <i class="material-icons">add_circle_outline </i>
            {{ colData.buttonType }}
          </button>
          <div *ngSwitchCase="'state'">{{ colData.dataValue }}</div>
          <div *ngSwitchCase="'checkbox'">
            <mat-checkbox
              [checked]="colData.dataValue|| null"
              (change)="checkboxVal($event, colData)"
            ></mat-checkbox>
            <!-- <input type="checkbox" [checked]="colData.dataValue" /> -->
          </div>
          <div *ngSwitchCase="'input'">
            <input type="text" [value]="colData.dataValue" />
          </div>
          <div *ngSwitchCase="'img'">
            <img
              style="height: 100px; width: 100px;"
              [src]="colData.dataValue"
            />
          </div>
          <div *ngSwitchCase="'boolean'">
            <div *ngIf="colData.dataValue === true; else elseBlock">Yes</div>
            <ng-template #elseBlock> No</ng-template>
          </div>
          <div *ngSwitchCase="'index'">
            {{ size * (page + 1) - (size + size) + (row + 1) }}
          </div>
          <div *ngSwitchCase="'priceType'">
            {{ colData.dataValue | number: "1.2-2" }}
          </div>
          <div *ngSwitchCase="'capitalize'">
            {{ colData.dataValue | uppercase }}
          </div>
          <a
            [routerLink]="colData.urlValue"
            class="{{ colData.class }}"
            (click)="setTitle(colData.dataValue)"
            *ngSwitchCase="'capitalizeWithAnchor'"
            >{{ colData.dataValue | uppercase }}</a
          >
          <div *ngSwitchCase="'qty'">{{ colData.dataValue }}</div>
          <!-- <div *ngSwitchCase="'date'">
            {{ dateFun(colData.dataValue)| date: 'MM/dd/yyyy' }}
          </div> -->
          <div *ngSwitchCase="'dateWithTime'">
            {{ colData.dataValue | date: "short" }}
          </div>
          <div *ngSwitchCase="'dateUnformated'">
            {{ dateFun(colData.dataValue) | date: "MM/dd/yyyy" }}
          </div>
          <div *ngSwitchCase="'dialog'" class="btn-no-wrap">
            <button
              *ngIf="colData.loadComponent2"
              (click)="openDialogDemo(colData, 'QR')"
              class="{{ colData.class2 }}"
            >
              <img
                src="./assets/images/qrcode.png"
                alt=""
                class="{{ colData.class2 }}"
              />
            </button>
            <button
              *ngIf="colData?.loadComponent3"
              (click)="openDialogDemo(colData, 'CONFIG')"
            >
              <i class="material-icons icon-color-violet icon-size">settings</i>
            </button>
            <button
              *ngIf="
                colData.class1 &&
                commonPermissionsGuard?.editPerms(colData.child, colData.parent)
              "
              (click)="openDialogDemo(colData)"
              class="{{ colData.class1 }}"
            >
              <i class="icon-edit"></i>
            </button>
            <button
              *ngIf="
                colData.class &&
                commonPermissionsGuard?.deletePerms(
                  colData.child,
                  colData.parent
                )
              "
              (click)="openDialog(colData.dataValue, colData.dialogType)"
              class="{{ colData.class }}"
            >
              <i class="icon-delete"></i>
            </button>
          </div>
          <div *ngSwitchCase="'dialogWithPerm'" class="btn-no-wrap">
            <button
              *ngIf="colData.buttonType === 'view'"
              (click)="openDialogDemo(colData)"
            >
              <i class="material-icons icon-color-violet icon-size">
                remove_red_eye
              </i>
            </button>
            <a
              *ngIf="colData.subType === 'anchorAction'"
              (click)="triggerAction(colData)"
              >{{ colData.dataValue }}</a
            >
            <button
              title="login direct"
              *ngIf="type === 'sa' && colData?.directLogin === 'true'"
              (click)="login(colData.dataValue)"
            >
              <i class="material-icons icon-color-violet icon-size"
                >account_circle</i
              >
            </button>
            <button
              title="change password"
              *ngIf="
                (type === 'sa' || type === 'admin') &&
                colData?.directLogin === 'true'
              "
              (click)="changePassword(colData.dataValue)"
            >
              <i class="material-icons icon-color-violet icon-size">build</i>
            </button>
            <button
              title="More"
              *ngIf="colData?.more === true"
            (click)="openDialogDemo(colData, 'more')"
            >
            <i class="material-icons icon-color-violet icon-size">open_in_new</i>
            </button>
            <button
              title="Extend Validity"
              *ngIf="type === 'sa' && colData?.extendValidity === true"
              (click)="openDialogDemo(colData)"
            >
            <i class="material-icons icon-color-violet icon-size">
              trending_up
              </i>
            </button>
            <button
              *ngIf="
                colData.class1 &&
                commonPermissionsGuard?.editPerms(colData.child, colData.parent)
              "
              (click)="openDialogDemo(colData)"
              [disabled]="!colData.dataValue1"
              class="{{ colData.class1 }}"
            >
              <i class="icon-edit"></i>
            </button>
            <button
              *ngIf="
                colData.class &&
                commonPermissionsGuard?.deletePerms(
                  colData.child,
                  colData.parent
                )
              "
              (click)="openDialog(colData.dataValue, colData.dialogType)"
              [disabled]="!colData.dataValue1"
              class="{{ colData.class }}"
            >
              <i class="icon-delete"></i>
            </button>
          </div>
          <div *ngSwitchCase="'html'" [innerHTML]="colData.dataValue"></div>
          <div *ngSwitchDefault>{{ colData.dataValue }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
