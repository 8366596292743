
<div class="mat-page-container">
  <!--header-->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15PX" class="mat-page-top-header">
    <div fxFlex="1 0 auto" fxFlexAlign="center">
      <h3>{{ 'quotation.title' | translate }}</h3>
    </div>
    <div fxFlex="100">
      <div class="search-box-fix-width">
      </div>
    </div>
    <div fxFlex="1 0 auto">
      <button mat-raised-button color="primary" *ngIf="commonPermissionsGuard?.addPerms('Social Media Category','Master')" (click)="createQuotation()">
        <i class="material-icons">add </i> {{ 'common.addNew' | translate }}</button>
    </div>
  </div>
  <!--header-->
  <app-table [tableSettings]="tableData"></app-table>
</div>