import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-total-referred',
  templateUrl: './total-referred.component.html',
  styleUrls: ['./total-referred.component.scss']
})
export class TotalReferredComponent implements OnInit {
  rowId;
  tableData = {};
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
      this.tableData = {
        tableHeader: [
          'Sr. No.',
          'Customer Name',
          'Contact No',
          'Email'
        ],
        serviceDetail: {
          service: 'PromotionsService',
          method: 'getDeleiveredCustomerDetail',
          isParameter: true,
          parameterList: { page: '', size: '', customerId : this.data.id }
        },
        mapping: [
          { type: 'index', dataValue: '' },
          { type: 'default', dataValue: 'customerName' },
          { type: 'default', dataValue: 'contactNo' },
          { type: 'default', dataValue: 'email' },
        ],
        options: {
          pageSizeArray: [10, 20, 30, 50, 100],
        },
        searchData: {},
      };
  }

  ngOnInit() {
  }

}
