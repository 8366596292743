<div class="authentication-page">
  <div fxLayout="row" class="Login_box" fxLayoutAlign="center" fxLayout.xs="column" fxLayoutGap="30px">
    <div fxFlex="100" fxFlex.gt-xs="50" fxFlexOrder.xs="2" class="social-container" >
        <div>
            <img src="./assets/images/logo/time-logo.png" alt="" class="img_logo" style="width: 230px;">
            <h4 class="heading">Sign in to your TIME-SQUARE account</h4>
            <p>
              Login With TIME-SQUARE grow your business grow your business
            </p>
            <ul class="social-login-list">
              <li>
                <i class="icon-facebook"></i>
              </li>
              <li>
                  <i class="icon-twitter"></i>
              </li>
              <li>
                  <i class="icon-google-p"></i>
              </li>
              <li>
                  <i class="icon-instagram"></i>
              </li>
              <li>
                  <i class="icon-youtube"></i>
              </li>

            </ul>
            <!-- <ul fxLayout fxLayoutGap="20px" fxLayoutAlign="start center" class="navigation-items">
              <li>
                <a class="btns" href="#">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a class="btns" href="#">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a class="btns" href="#">
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a class="btns" href="#">
                  <i class="fab fa-youtube"></i>
                </a>
              </li>
            </ul> -->
          </div>
    </div>
    <div fxFlex="100" fxFlex.gt-xs="50" fxFlexOrder.xs="1">
        <form [formGroup]="loginForm"  class="login-form">
            <mat-form-field>
              <input matInput type="text" (keydown.enter)="submitLogin()" class="form-control" placeholder="{{ 'login.username' | translate }}" #username formControlName="username">
            </mat-form-field>
            <div *ngIf="loginForm.controls['username'].errors && loginForm.controls['username'].dirty" class="error-msg has-error">
              <div [ngSwitch]="obj(loginForm.controls['username'].errors)[0]">
                <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('usersValidation.USER_NAME_REQUIRED') }}</mat-error>
                <mat-error *ngSwitchCase="'pattern'" class="help-block">{{ validationMessage.displayErrorMessage('usersValidation.USER_NAME_PATTERN') }}</mat-error>
                <mat-error *ngSwitchCase="'maxlength'" class="help-block">{{ validationMessage.displayErrorMessage('usersValidation.USER_NAME_MAXLENGTH') }}</mat-error>
                <mat-error *ngSwitchCase="'minlength'" class="help-block">{{ validationMessage.displayErrorMessage('usersValidation.USER_NAME_MINLENGTH') }}</mat-error>
              </div>
            </div>
            <div *ngIf="loginForm.controls['username'].errors && isSubmitted" class="error-msg has-error">
              <div [ngSwitch]="obj(loginForm.controls['username'].errors)[0]">
                <mat-error *ngSwitchCase="'serverError'" class="help-block">{{ serverErrorMsg }}</mat-error>
              </div>
            </div>
            <mat-form-field>
              <input matInput (keydown.enter)="submitLogin()" autocomplete="password" id="password" class="validate" placeholder="{{ 'login.password' | translate }}" #password
                type="password" formControlName="password" maxlength="128 "/>
            </mat-form-field>
            <div class="has-error" *ngIf="loginForm.controls['password'].errors && loginForm.controls['password'].dirty">
              <div [ngSwitch]="obj(loginForm.controls['password'].errors)[0]">
                <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('loginValidation.PASSWORD_REQUIRED') }}</mat-error>
                <mat-error *ngSwitchCase="'minlength'" class="help-block">{{ validationMessage.displayErrorMessage('loginValidation.PASSWORD_MINLENGTH') }}</mat-error>
              </div>
            </div>
            <div *ngIf="loginForm.controls['password'].errors && isSubmitted" class="error-msg has-error">
              <div [ngSwitch]="obj(loginForm.controls['password'].errors)[0]">
                <mat-error *ngSwitchCase="'serverError'" class="help-block">{{ serverErrorMsg }}</mat-error>
              </div>
            </div>
              <mat-form-field>
                <input matInput (keydown.enter)="submitLogin()" autocomplete="propertyCode" id="propertyCode" class="validate" placeholder="{{ 'login.code' | translate }}" #propertyCode
                   formControlName="propertyCode" maxlength="128 "/>
              </mat-form-field>
              <div class="has-error" *ngIf="loginForm.controls['propertyCode'].errors && loginForm.controls['propertyCode'].dirty">
                <div [ngSwitch]="obj(loginForm.controls['propertyCode'].errors)[0]">
                  <mat-error *ngSwitchCase="'required'" class="help-block">{{ validationMessage.displayErrorMessage('loginValidation.PROPERTY_CODE_REQUIRED') }}</mat-error>
                </div>
              </div>
              <div *ngIf="loginForm.controls['propertyCode'].errors && isSubmitted" class="error-msg has-error">
                <div [ngSwitch]="obj(loginForm.controls['propertyCode'].errors)[0]">
                  <mat-error *ngSwitchCase="'serverError'" class="help-block">{{ serverErrorMsg }}</mat-error>
                </div>
              </div>
            <mat-checkbox formControlName="rememberMe">{{ 'login.rememberMe' | translate }}</mat-checkbox>
        </form>
        <a routerLink="../forgot" class="forgot-password-link">{{ 'login.forgotPassword' | translate }}</a>
        <button mat-raised-button color="primary" (click)="submitLogin()" [disabled]="loginForm.invalid">{{ 'login.button' | translate }}</button>
      </div>
  </div>
</div>

