<mat-tab-group dynamicHeight class="rght-sidebar">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>today</mat-icon>
    </ng-template>
    <div class="">
      <mat-list>
        <h3 mat-subheader>Recent Activities</h3>
        <mat-list-item>
          <mat-icon mat-list-icon class="mat-circle-icon">alarm</mat-icon>
          <h4 mat-line>just now</h4>
          <p mat-line>Jim Doe Purchased new equipments for zonal office </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon mat-list-icon class="mat-circle-icon">card_travel</mat-icon>
          <h4 mat-line>just now</h4>
          <p mat-line>Jim Doe Purchased new equipments for zonal office </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon mat-list-icon class="mat-circle-icon">card_travel</mat-icon>
          <h4 mat-line>invert_colors</h4>
          <p mat-line>Jim Doe Purchased new equipments for zonal office </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon mat-list-icon class="mat-circle-icon">alarm</mat-icon>
          <h4 mat-line>just now</h4>
          <p mat-line>Jim Doe Purchased new equipments for zonal office </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon mat-list-icon class="mat-circle-icon">card_travel</mat-icon>
          <h4 mat-line>just now</h4>
          <p mat-line>Jim Doe Purchased new equipments for zonal office </p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon mat-list-icon class="mat-circle-icon">card_travel</mat-icon>
          <h4 mat-line>invert_colors</h4>
          <p mat-line>Jim Doe Purchased new equipments for zonal office </p>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>face</mat-icon>
    </ng-template>
    <div class="">
      <mat-list>
        <h3 mat-subheader>Recent Message</h3>
        <!-- <mat-list-item>
            <div class="msg-image">
              <img src="../../assets/images/avatar/avatar-1.png">
            </div>
            <div class="msg-info">
              <h4 mat-line>just now</h4>
              <p mat-line>Jim Doe Purchased new equipments for zonal office </p>
            </div>
            <div class="msg-time">
              5.00AM
            </div>
        </mat-list-item> -->
        <mat-list-item>
          <div class="msg-item">
            <div class="msg-image">
              <img src="../../assets/images/avatar/avatar-1.png">
            </div>
            <div class="msg-info">
              <h4 mat-line>just now</h4>
              <p mat-line>Jim Doe Purchased new equipments for zonal office </p>
            </div>
            <div class="msg-time">
              5.00AM
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>settings</mat-icon>
    </ng-template>
    <mat-list>
      <h3 mat-subheader>General Settings</h3>
      <mat-list-item>
        <div class="mat-setting-cont">
          <div class="mat-setting-heading">
            <h4>Notifications </h4>
            <section class="">
              <mat-slide-toggle class="" color="accent">
              </mat-slide-toggle>
            </section>
          </div>
          <p> Use checkboxes when looking for yes or no answers.</p>
        </div>
      </mat-list-item>
      <mat-list-item>
          <div class="mat-setting-cont">
            <div class="mat-setting-heading">
              <h4>Notifications </h4>
              <section class="">
                <mat-slide-toggle class="" color="accent">
                </mat-slide-toggle>
              </section>
            </div>
            <p> Use checkboxes when looking for yes or no answers.</p>
          </div>
        </mat-list-item>
        <mat-list-item>
            <div class="mat-setting-cont">
              <div class="mat-setting-heading">
                <h4>Notifications </h4>
                <section class="">
                  <mat-slide-toggle class="" color="accent">
                  </mat-slide-toggle>
                </section>
              </div>
              <p> Use checkboxes when looking for yes or no answers.</p>
            </div>
          </mat-list-item>
    </mat-list>
  </mat-tab>
</mat-tab-group>