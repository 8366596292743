<div class="authentication-page forgotpage">
    <div fxLayout="row" class="Login_box" fxLayoutAlign="center center" fxLayout.sm="column" fxLayout.xs="column">
      <div fxFlex="100">
            <img src="./assets/images/logo/revugain_new_logo.png" alt="" class="img_logo">
            <h4 class="heading">Forgot Password</h4>
            <form class="example-form">
                <mat-form-field>
                    <input matInput placeholder="Enter Your Registered Email Id">
                </mat-form-field>
                <button mat-raised-button color="primary">Submit</button>
            </form>
      </div>
</div>







