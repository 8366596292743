import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationMessage } from '../../../utils/locale';
import { IAlertService } from '../../../iservices/iAlert';
import { IUserService } from '../../../iservices/iUser';
import { ISharedService } from '../../../iservices/iShared';
import { ILoaderService } from '../../../iservices/iLoader';
import { STORAGE_KEYS } from '../../../services/storage-keys-constants';
import { COMMONFORMVALIDATIONRULE } from '../../../utils/validations/commonFormRules';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePassForm: FormGroup;
  serverErrorType;
  distributorId;
  obj = Object.getOwnPropertyNames;
  profileData: any;
  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject('IUserService') private userService: IUserService,
    @Inject('IAlertService') private alertService: IAlertService,
    @Inject('ISharedService') private sharedService: ISharedService,
    @Inject('ILoaderService') private loaderService: ILoaderService,
    private validationMessage: ValidationMessage,
  ) {
    this.buildForm();
    if (this.data.from === 'admin') {
      this.changePassForm.removeControl('oldPassword');
    }
  }

  /**
   * @method ngOnInit()
   * @desc used to initialize page.
   */
  ngOnInit(): void {
  }

  /**
   * @method buildForm()
   * @desc used to build form.
   */
  buildForm(): void {
    this.changePassForm = this.formBuilder.group({
      'oldPassword': ['', Validators.required],
      'newPassword': ['', COMMONFORMVALIDATIONRULE.passwordPattern
      ],
      'confirmPassword': [''],
    }, { validator: this.checkIfPasswordsMatch('newPassword', 'confirmPassword') });
  }
  /**
 * @method checkIfPasswordsMatch()
 * @desc used for confirming new password.
 * @param passwordKey: string - user input password.
 * @param passwordConfirmationKey: string - user input confirm password.
 * @returns any
 */
  checkIfPasswordsMatch(passwordKey, passwordConfirmationKey): any {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordConfirmationInput.value === '') {
        return passwordConfirmationInput.setErrors({ required: true });
      }
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }
  /**
 * @method submitForm()
 * @desc used to submit profile form details.
 */
  submitForm(): any {
    this.loaderService.display(true);
    if(this.data.from === 'admin') {
        this.changePasswordFromAdmin();
    } else {
      if (this.changePassForm.value.oldPassword !== '') {
        this.userService.changePassword(this.changePassForm.value).subscribe(
          res => {
            if (res.code === 1000) {
              // this.customerTypes = res.data;
              this.alertService.success('2017', true);
              this.loaderService.display(false);
              this.dialogRef.close();
              this.sharedService.setDataInStorage(STORAGE_KEYS.PASSWORD, this.changePassForm.value.newPassword);
            } else if (res.code === 2010) {
              this.loaderService.display(false);
              this.changePassForm.controls['oldPassword'].setErrors({
                serverError: true
              });
              this.serverErrorType = res.description;
            } else {
              this.alertService.error('ERRORDEFAULTCODE', false);
            }
          }, error => {
            this.alertService.error('ERRORDEFAULTCODE', false);
            this.loaderService.display(false);
          }
        );
      }
    }
  }

  changePasswordFromAdmin = () => {
    const data = {
      Id : this.data.userData,
      Password: this.changePassForm.value['newPassword']
    };
    this.userService.changePasswordFromAdmin(data).subscribe(
      res => {
        if (res.code === 1000) {
          this.alertService.success('2017', true);
          this.loaderService.display(false);
          this.dialogRef.close();
          this.sharedService.setDataInStorage(STORAGE_KEYS.PASSWORD, this.changePassForm.value.newPassword);
        } else if (res.code === 2010) {
          this.loaderService.display(false);
          this.changePassForm.controls['oldPassword'].setErrors({
            serverError: true
          });
          this.serverErrorType = res.description;
        } else {
          this.alertService.error('ERRORDEFAULTCODE', false);
        }
      }, error => {
        this.alertService.error('ERRORDEFAULTCODE', false);
        this.loaderService.display(false);
      }
    );
  }
}
