<div class="content">
<div class="shape-item-wrapper">
    <div class="shape-item-cont" *ngFor="let shape of shapes" (click)="addShape(shape); button.blur()" #button>
            <span class="shape-label" trans>{{shape.name}}</span>
        <svg *ngIf="shape.type === 'Path'" xmlns="http://www.w3.org/2000/svg" viewBox="0 -100 512 700">
            <path [attr.d]="shape.options.path" [attr.stroke-width]="shape.options.strokeWidth || 10"></path>
        </svg>
        <svg-icon [name]="shape.name+'-custom'" *ngIf="shape.type !== 'Path'"></svg-icon>
    </div>
</div>
</div>