import { Component, Inject, OnInit, DoCheck, OnDestroy } from '@angular/core';
import { ISocialMediaMasterService } from '../../../../../iservices/iMasterServices/iSocialMediaMaster';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { ActivatedRoute } from '@angular/router';
import { IAlertService } from '../../../../../iservices/iAlert';
import { Subscription } from 'rxjs';
import { ICommonService } from '../../../../../iservices/iCommon';
import { ILoaderService } from '../../../../../iservices/iLoader';
import { MatDialog } from '@angular/material/dialog';
import { AddBannerComponent } from '../../..//masterComponents/social-media-category/banner/add-banner/add-banner.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { CommonPermissionsGuard } from '../../../../../gaurds/flow.guard';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy, DoCheck {
  private deleteSubscription: Subscription;
  private tableSubscription: Subscription;
  showPagination = false;
  flag = false;
  param: {
    categoryId: any,
    page: any,
    size: any
  };
  size: any;
  page: any;
  totalPages;
  totalCount: number;
  pageSizeArray;
  categoryId: number;
  bannerImages: any = [];
  constructor( @Inject('ISocialMediaMasterService') private socialMediaMasterService: ISocialMediaMasterService,
    private _lighboxConfig: LightboxConfig, private _lightbox: Lightbox,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public commonPermissionsGuard: CommonPermissionsGuard,
    @Inject('IAlertService') private alertService: IAlertService,
    @Inject('ICommonService') private commonService: ICommonService,
    @Inject('ILoaderService') private loaderService: ILoaderService) {
    _lighboxConfig.fadeDuration = 1;
    this.pageSizeArray = [12, 24, 36, 48];
  }
  /**
    * @method ngOnInit()
    * @desc used to Initialize Page.
    */
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.categoryId = params['categoryId'];
      setTimeout(() => {
        this.getBannerImages();
      });
    });
    this.deleteSubscription = this.socialMediaMasterService.dataHasBeenDeleted.subscribe(beenDeleted => {
      // filter out non-truthy values
      if (beenDeleted) {
        setTimeout(() => {
          this.getBannerImages();
        });
      }
    });
    this.tableSubscription = this.socialMediaMasterService.tableUpdated.subscribe(updated => {
      if (updated) {
        setTimeout(() => {
          this.getBannerImages();
        });
      }
    });

    if (!this.size) {
      this.size = this.pageSizeArray['0'];
    }
    if (!this.page) {
      this.page = 1;
    }
  }
  /**
  * @method ngDoCheck()
  * @desc used to check if any chaheges  detected.
  */
  ngDoCheck(): void {

  }
  /**
   * @method ngOnDestroy()
   * @desc used to destory Observables detected.
   */
  ngOnDestroy() {
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
    if (this.tableSubscription) {
      this.tableSubscription.unsubscribe();
    }
  }
  /*
   * @method getBannerImages()
   * @desc used to get Images.
   */
  getBannerImages(): void {
    this.param = {
      page: this.page,
      size: this.size,
      categoryId: this.categoryId
    };
    this.loaderService.display(true);
    this.socialMediaMasterService.getBannerDetails(this.param).subscribe(
      resp => {
        this.loaderService.display(false);
        if (resp['data']) {
          this.bannerImages = resp['data'];
          this.totalCount = this.bannerImages[0].totalRows;
          this.totalPages = Math.ceil(this.totalCount / this.size);
          this.bannerImages.map(data => {
            data.src = data.imagePath;
          });
        }
      });
  }

  createBanners(): void {
    this.socialMediaMasterService.id = '';
    this.dialog.open(AddBannerComponent, {
      width: '450px',
      panelClass: 'mat-dialog-box',
      data: { id: this.param.categoryId }
    });
  }

  /*
   * @method removeImage()
   * @desc used to remove image.
   */

  removeImage(index: number) {
    const dialogType = 'removeImage';
    this.commonService.openDialog(index, this.socialMediaMasterService, dialogType);
  }
  /*
   * @method open()
   * @desc used to open lightbox.
   */
  open(index: number) {
    this._lightbox.open(this.bannerImages, index, { wrapAround: true, showImageNumberLabel: true });
  }
  /*
   * @method close()
   * @desc used to close lightbox.
   */
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  /**
  * @method goPrev()
  * @desc used to get data of previous page.
  * @param data: object- contains table related info like page number.
  */
  goPrev(data): void {
    if (data.page <= this.totalPages) {
      this.page = data.page;
    }
    this.getBannerImages();
  }

  /**
   * @method goNext()
   * @desc used to get data of next page.
   * @param data: object- contains table related info like page number.
   */
  goNext(data): void {

    if (data.page >= 1) {
      this.page = data.page;
    }
    this.getBannerImages();
  }

  /**
   * @method goToPage()
   * @desc used to get data of current page.
   * @param data: object- contains table related info like page number.
   */
  goToPage(data): void {
    if (data.page >= 1 && data.page <= this.totalPages) {
      this.page = data.page;
    }
    this.getBannerImages();
  }

  /**
   * @method setPageSize()
   * @desc used to set size to show number of items on page.
   * @param data: object- contains table related info like size.
   */
  setPageSize(data): void {
    this.page = 1;
    this.size = data.size;
    this.getBannerImages();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.bannerImages, event.previousIndex, event.currentIndex);
  }
}
