import { SharedService } from './../../../services/shared.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-cashier-header',
  templateUrl: './cashier-header.component.html',
  styleUrls: ['./cashier-header.component.scss']
})
export class CashierHeaderComponent implements OnInit {
  isCashierReportEnable: boolean;
  toolbarHelpers = {
    notifications: {},
    currentUser: {
      photoURL: 'assets/images/avatars/hari.jpg',
      currentUserName: 'Hari Krishna'
    }
  };
  constructor(public router: Router,
  private sharedService: SharedService,
  ) {
    this.isCashierReportEnable = JSON.parse(this.sharedService.getDataFromStorage('isCashierReportEnabled'));
   }

  ngOnInit() {
  }
  goTo(param: string): void {
    if (param === 'customer') {
      this.router.navigate(['/cashierreport/customer']);
    }
    if (param === 'cashierReport') {
      this.router.navigate(['/cashierreport/cashierreport']);
    }
  }
}
