import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../api-endpoints-constants';
import { IApiBaseObject } from '../../iModels/api-base-object.model';
import { IApiService } from '../../iservices/iApi';
import { ModelBindingService } from '../binding.model.service';
import { map } from 'rxjs/operators';
import { ModelDecoderBindingService } from '../bindingDecoder.model.service';
import { BehaviorSubject } from 'rxjs';
import { IReviewForService } from '../../iservices/iMasterServices/iReviewFor';
import { MatDialog } from '@angular/material/dialog';
import {
  AddReviewForComponent
} from '../../component/master/masterComponents/review-for/add-review-for/add-review-for.component';

@Injectable({
  providedIn: 'root'
})
export class ReviewForService implements IReviewForService {
  id;
  dataHasBeenDeleted = new BehaviorSubject(false);
  tableUpdated = new BehaviorSubject(false);
  private apiObject: IApiBaseObject;
  constructor( @Inject('IApiService') private apiService: IApiService,
    private modelBindingService: ModelBindingService,
    private modelDecoderBindingService: ModelDecoderBindingService,
    public dialog: MatDialog) {

  }
  /*
    * @method getReviewForDetails()
    * @desc used to get ReviewFor list .
    * @return json array data.
  */
  getReviewForDetails(apiGetParameters): any {
    let json;
    apiGetParameters.page = apiGetParameters.page ? apiGetParameters.page : 1;
    apiGetParameters.size = apiGetParameters.size === 0 || apiGetParameters.size ? apiGetParameters.size : 10;
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.REVIEW_FOR_Get_ALL, apiGetParameters);
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          if (resp) {
            resp['data'] = this.modelDecoderBindingService
              .mappingDataReturn('reviewForDecoder', resp);
            if (resp['data'].data) {
              json = { data: resp['data'].data, 'itemCount': resp['data'].data[0].totalRows };
            } else {
              json = { data: resp['data'].data, 'itemCount': 0 };
            }
            return json;
          }
        }
      }
      ));
  }
  /*
    * @method addReviewFor()
    * @desc used to add ReviewFor Type.
    * @param permData :array - ReviewFor data.
    * @return json array data.
  */
  addReviewFor(permData): any {
    const requestData = this.modelBindingService.reviewForEncoder(permData);
    this.apiObject = this.modelBindingService.setApiCallObject(API_ENDPOINTS.REVIEW_FOR_ADD, requestData,
      true, false);
    return this.apiService.postApi(this.apiObject).pipe(
      map((res) => {
        return res;
      },
        (error) => {
          return error;
        }
      ));
  }

  /*
  * @method getReviewForDetailsById()
  * @desc used to get review for details by id.
  * @param id :string - review id.
  * @return json array data.
*/
  getReviewForDetailsById(id): any {
    const url = this.modelBindingService.replaceUrl(
      API_ENDPOINTS.REVIEW_FOR_by_id, {
        id: id
      });
    this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.getApi(this.apiObject).pipe(
      map((resp) => {
        if (resp) {
          resp['data'] = this.modelDecoderBindingService.reviewForDecoder(resp['data'][0]);
          return resp;
        }
      }));
  }
  /*
   * @method delete()
   * @desc used to review for row data.
   * @param id :array - review id .
   * @return string.
  */
  delete(id): string {
    const url = this.modelBindingService.replaceUrlForDelete(
      API_ENDPOINTS.REVIEW_FOR_DELETE, id);
      this.apiObject = this.modelBindingService.setApiCallObject(url, {}, true, false);
    return this.apiService.deleteApi(this.apiObject).pipe(
      map((response) => {
        return response;
      },
        (error) => {
          return error;
        }));
  }
}
