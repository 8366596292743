<div class="content">
        <div class="crop-form-cont">
            <div class="crop-item">
            <label for="width" trans>Width</label>
            <input type="number" id="width" [(ngModel)]="cropzoneWidth" (ngModelChange)="resizeCropzone()" min="1">
            </div>

            <div class="crop-item">
            <label for="height" trans>Height</label>
            <input type="number" id="height" [(ngModel)]="cropzoneHeight" (ngModelChange)="resizeCropzone()" min="1">
            </div>
        </div>
        <div class="aspect-ratio" *ngFor="let aspectRatio of aspectRatios" [class.selected]="cropZone.aspectRatio === aspectRatio" (click)="applyAspectRatio(aspectRatio)">
            <div class="preview" #ratioPreview [attr.data-ratio]="aspectRatio"></div>
            <div class="name">{{aspectRatio}}</div>
        </div>
</div>